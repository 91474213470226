import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineCancelPresentation } from "react-icons/md";
import { CartState } from "../../context/Context";
import usePageDurationTracker from "../../components/Hooks/usePageDurationTracker";
import Cookies from "universal-cookie";
import axios from "axios";
import { getCookie, setCookie } from "../../SessionManager";
import { toast, ToastContainer } from "react-toastify";
import { LoadingDialog } from "../Cart/Cart";
// SVG Components remain the same as previous version

export const CloseIcon = () => (
  <svg
    className="w-5 h-5"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

export const RemoveIcon = () => (
  <svg
    className="w-5 h-5 text-red-600 animate-pulse"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

const HeartIcon = () => (
  <svg
    className="w-5 h-5"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
    />
  </svg>
);

export const SearchIcon = () => (
  <svg
    className="w-4 h-4"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
    />
  </svg>
);

export const ChevronDownIcon = () => (
  <svg
    className="w-4 h-4"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 9l-7 7-7-7"
    />
  </svg>
);

const FilterIcon = () => (
  <svg
    className="w-5 h-5"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
    />
  </svg>
);

const Skeleton = () => (
  <div className="w-64 p-4 border border-gray-200 animate-pulse">
    <div className="w-full h-48 bg-gray-200 mb-4" />
    <div className="h-6 bg-gray-200 w-3/4 mb-2" />
    <div className="h-4 bg-gray-200 w-1/4 mb-4" />
    <div className="flex gap-2 mb-4">
      <div className="h-10 bg-gray-200 w-3/4" />
      <div className="h-10 bg-gray-200 w-1/4" />
    </div>
    <div className="h-px bg-gray-200 mb-4" />
    <div className="space-y-2">
      <div className="h-4 bg-gray-200 w-full" />
      <div className="h-4 bg-gray-200 w-5/6" />
      <div className="h-4 bg-gray-200 w-4/6" />
    </div>
  </div>
);

export const FilterChip = ({ label, onRemove }) => (
  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800">
    {label}
    <button
      onClick={onRemove}
      className="ml-2 text-blue-600 hover:text-blue-800"
    >
      <CloseIcon />
    </button>
  </span>
);

export const FilterDropdown = ({ label, options, selectedValue, onChange }) => (
  <div className="relative">
    <select
      className="appearance-none bg-white border border-gray-300 rounded px-4 py-2 pr-8 focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer"
      value={selectedValue}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="">{label}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label} {option.count ? `(${option.count})` : ""}
        </option>
      ))}
    </select>
    <div className="absolute right-2 top-1/2 -translate-y-1/2 pointer-events-none text-gray-500">
      <ChevronDownIcon />
    </div>
  </div>
);

const ProductCard = ({ product, updateProducts, token }) => {

  const navigate = useNavigate()
  const [prodWishlisted, setProdWishlisted] = useState(false)
  const [removingItem, setRemovingItem] = useState(false)
  const userToken = getCookie("auth_token")
  const cart_id = JSON.parse(localStorage?.getItem("cart_id") || "");
  const [addingToCart, setAddingToCart] = useState(false);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };
  const attributes = Object.entries(product).reduce((acc, [key, value]) => {
    if (
      value &&
      ![
        "product_id",
        "sku",
        "name",
        "product_image_url",
        "description",
        "price",
      ].includes(key)
    ) {
      acc[key] = value;
    }
    return acc;
  }, {});

  const removeCompareItem = () => {
    setRemovingItem(true)
    axios.delete(`${process.env.REACT_APP_API_URL}/rest/V1/user/comparelist/remove/${token}/${product?.product_id}`)
    .then(res => {
      console.log("succ response from delete comp item: ", res?.data)
      updateProducts(res?.data?.products)
      setRemovingItem(false);
    })
    .catch(err => {
      console.log("Error while removing item from comp list", err)
      setRemovingItem(false);
    })
  }

  const handleAddtoCart = () => {
    setAddingToCart(true);
    if (userToken) {
      const payload = {
        cartItem: {
          sku: product?.sku,
          qty: 1,
          quote_id: cart_id,
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/rest/V1/carts/mine/items`,
          payload,
          { headers: headers }
        )
        .then((response) => {
          console.log("response from add to cart: ", response?.data);
          toast.dark(`✅ ${product?.name} is now added to your cart`, {
            closeOnClick: true,
          });
          setAddingToCart(false);
          setCookie("items_in_cart", response?.data?.item_qty);
        })
        .catch((error) => {
          console.log("error from add to cart: ", error);
          setAddingToCart(false);
        });
    } else {
      alert("You're not logged in. Please login to continue.");
      navigate("/login");
    }
  };

  return (
    <div className="flex flex-col gap-3 justify-start min-w-[210px] max-w-[210px] p-4 border border-gray-200 relative">
      <button
        className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
        onClick={removeCompareItem}
      >
        {removingItem ? <RemoveIcon /> : <CloseIcon />}
      </button>

      <div className="">
        <img
          src={product.product_image_url}
          alt={product.name}
          className="w-full h-48 object-cover"
        />
      </div>

      <p className="font-medium text-base h-[75px] line-clamp-3">
        {product.name}
      </p>

      <div className="text-sm">
        <span>Price: </span>
        <span className="font-medium">
          ${parseFloat(product.price).toFixed(2)}
        </span>
      </div>

      {/* Display additional attributes */}
      {/* {Object.entries(attributes).map(([key, value]) => (
        <div key={key} className="text-sm mb-1">
          <span className="font-medium">
            {key.replace("_", " ").toUpperCase()}:{" "}
          </span>
          <span>{value}</span>
        </div>
      ))} */}

      <div className="flex justify-between">
        {
          <button
            disabled={addingToCart}
            className="text-xs md:flex hover:bg-blue-600 bg-blue-500 justify-center items-center px-4 py-2 text-white cursor-pointer rounded-md"
            onClick={() => {
              handleAddtoCart();
            }}
          >
            <p>
              {addingToCart ? (
                <div className="loading-container flex justify-center items-center w-[70px] h-[20px]">
                  <svg
                    aria-hidden="true"
                    className={`w-3 h-3 mr-2 text-gray-200 animate-spin  fill-blue-600`}
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              ) : (
                <span>ADD TO CART</span>
              )}
            </p>
          </button>
        }
        {/* <button className="bg-blue-500 text-white  rounded hover:bg-blue-600">
          Add to Cart
        </button> */}
        {/* <button className="border border-gray-300 p-2 rounded hover:bg-gray-50">
          <HeartIcon />
        </button> */}
      </div>

      <div className="mt-4 pt-4 border-t">
        <div className="text-sm mb-2">
          <span className="font-medium">SKU: </span>
          {product.sku}
        </div>
        <div className="text-sm">
          <span className="font-medium">Description: </span>
          <div
            className="compare-product-description mt-2"
            dangerouslySetInnerHTML={{ __html: product?.description }}
          />

          {/* {description} */}
        </div>
      </div>
    </div>
  );
};

const ProductComparison = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [token, setToken] = useState(null);
  const cookies = new Cookies()
  const [sortBy, setSortBy] = useState("");
  const [filters, setFilters] = useState({});
  const [activeFilters, setActiveFilters] = useState([]);
  const [loading, setLoading] = useState(true);
  const userData = JSON.parse(localStorage?.getItem("userData") || "{}");
  const [compareToken, setCompareToken] = useState((localStorage?.getItem("compare_token") || ""))
  const [filteredProducts, setFilteredProducts] = useState([])
  const [filterOptions, setFilterOptions] = useState({})
  const [clearingComparisonList, setClearingComparisonList] = useState(false)

  usePageDurationTracker(`Product Comparision`);
  
  const fetchData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/rest/V1/user/comparelist/${compareToken}`)
    .then((response) => {
      console.log("response from compare: ", response?.data)  
      setProducts(response?.data.products);
      setFilteredProducts(filterProducts(response?.data?.products));
      setFilterOptions(deriveFilterOptions(response?.data?.products));
      setLoading(false);
    })
    .catch((err) => {
      console.log("error while fetching compare prods: ", err)
      if (err?.response?.data?.message === "No products found in the compare list for the given token.") {
        setFilteredProducts([])
      } else {
        alert("Error while fetching compare products");
      }
      setLoading(false);
      
    })
  }

  // Simulating API call with the provided data
  // useEffect(() => {
  //   const fetchedToken = cookies?.get("auth_token");
  //   if (fetchedToken) {
  //     setToken(fetchedToken);
  //     setCompareToken(fetchedToken.substring(0, 32));
  //   } else {
  //     const storedCompareToken = localStorage.getItem("compare_token");
  //     setCompareToken(storedCompareToken);
  //   }
  // }, []);

  // Fetch data once compareToken is available
  useEffect(() => {
    if (compareToken?.length > 0) {
      fetchData();
    } 
  }, [compareToken]);

  useEffect(() => {
    if (products?.length > 0) {
      setFilteredProducts(filterProducts(products));
      setFilterOptions(deriveFilterOptions(products));
    }
  }, [products])
  
  useEffect(() => {
    const result = filterProducts(products);
    setFilteredProducts(result);
  }, [products, searchQuery, filters, sortBy]);

  // Derive filter options from products
  const deriveFilterOptions = (products) => {
    const options = {};

    products.forEach((product) => {
      Object.entries(product).forEach(([key, value]) => {
        if (
          value &&
          ![
            "product_id",
            "sku",
            "name",
            "product_image_url",
            "description",
            "price",
            "short_description"
          ].includes(key)
        ) {
          if (!options[key]) {
            options[key] = new Map();
          }
          const count = options[key].get(value) || 0;
          options[key].set(value, count + 1);
        }
      });
    });

    return Object.fromEntries(
      Object.entries(options).map(([key, valueMap]) => [
        key,
        Array.from(valueMap.entries()).map(([value, count]) => ({
          value,
          label: value,
          count,
        })),
      ])
    );
  };

  const clearComparisonList = () => {
    setClearingComparisonList(true)
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/rest/V1/user/comparelist/removeall/${compareToken}`
      )
      .then((response) => {
        console.log("response from clear comp list: ", response?.data);
        setFilteredProducts([])
        setClearingComparisonList(false);
      })
      .catch((err) => {
        console.log("error while clearing comp list: ", err);
        setClearingComparisonList(false);
      });
  }

  // Handle filter changes
  const handleFilterChange = (filterType, value) => {
    if (value) {
      setFilters((prev) => ({ ...prev, [filterType]: value }));
      setActiveFilters((prev) => [...prev, { type: filterType, value }]);
    }
  };

  // Remove a filter
  const removeFilter = (filterType) => {
    const newFilters = { ...filters };
    delete newFilters[filterType];
    setFilters(newFilters);
    setActiveFilters((prev) => prev.filter((f) => f.type !== filterType));
  };

  const filterProducts = (products) => {
    let filtered = [...products];

    // Search filter
    if (searchQuery) {
      filtered = filtered.filter(
        (product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (product.description?.toLowerCase() || "").includes(
            searchQuery.toLowerCase()
          )
      );
    }

    // Apply dynamic filters
    Object.entries(filters).forEach(([key, value]) => {
      filtered = filtered.filter((product) => product[key] === value);
    });

    // Sorting
    if (sortBy) {
      filtered.sort((a, b) => {
        switch (sortBy) {
          case "price-asc":
            return parseFloat(a.price) - parseFloat(b.price);
          case "price-desc":
            return parseFloat(b.price) - parseFloat(a.price);
          case "name-asc":
            return a.name.localeCompare(b.name);
          case "name-desc":
            return b.name.localeCompare(a.name);
          default:
            return 0;
        }
      });
    }

    return filtered;
  };

  // const filteredProducts = filterProducts(products);
  // const filterOptions = deriveFilterOptions(products);
  const sortOptions = [
    { value: "price-asc", label: "Price: Low to High" },
    { value: "price-desc", label: "Price: High to Low" },
    { value: "name-asc", label: "Name: A to Z" },
    { value: "name-desc", label: "Name: Z to A" },
  ];

  return (
    <div className="w-full flex flex-col justify-start items-start p-6 font-man">
      <div className="flex w-full justify-between items-center mb-6">
        <h1 className="text-2xl font-medium">Compare Products</h1>
        {filteredProducts?.length > 0 && (
          <button
            className="text-blue-500 hover:text-blue-600"
            onClick={clearComparisonList}
          >
            Clear Comparison List
          </button>
        )}
      </div>

      {/* Filter and Sort Controls */}
      <div className="mb-6 space-y-4">
        <div className="flex items-center gap-4">
          <div className="relative flex-1 max-w-xs">
            <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
              <SearchIcon />
            </div>
            <input
              type="text"
              placeholder="Search products..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="flex gap-4 flex-wrap">
            <FilterDropdown
              label="Sort By"
              options={sortOptions}
              selectedValue={sortBy}
              onChange={setSortBy}
            />
            {Object.entries(filterOptions).map(([filterType, options]) => (
              <FilterDropdown
                key={filterType}
                label={filterType.replace("_", " ").toUpperCase()}
                options={options}
                selectedValue={filters[filterType] || ""}
                onChange={(value) => handleFilterChange(filterType, value)}
              />
            ))}
          </div>
        </div>

        {/* Active Filters */}
        {activeFilters.length > 0 && (
          <div className="flex gap-2 flex-wrap">
            {activeFilters.map((filter) => (
              <FilterChip
                key={filter.type}
                label={`${filter.type.replace("_", " ").toUpperCase()}: ${
                  filter.value
                }`}
                onRemove={() => removeFilter(filter.type)}
              />
            ))}
          </div>
        )}
      </div>

      {/* Products Grid with Loading State */}
      <div className="flex max-w-full w-full gap-4 overflow-x-auto pb-4">
        {loading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          filteredProducts.map((product) => (
            <ProductCard
              key={product.product_id}
              product={product}
              token={compareToken}
              updateProducts={setProducts}
            />
          ))
        )}
      </div>

      {/* No Results Message */}
      {!loading && filteredProducts.length === 0 && (
        <div className="text-center py-8 text-gray-500 min-h-[350px] flex items-center w-full justify-center">
          {products?.length > 0 ? <p>No products match your search criteria</p> : <p>No products in your comparison list</p>}
        </div>
      )}
      <div className="font-outfit text-sm ">
        <ToastContainer position="bottom-left" autoClose={4000} type="dark" />
      </div>
      <LoadingDialog
        isOpen={clearingComparisonList}
        message={"Clearing your comparison list..."}
      />
    </div>
  );
};

export default ProductComparison;
