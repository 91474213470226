import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, unstable_useBlocker } from "react-router-dom";
import Address from "../components/Address/Address";
import Payment from "../components/Payment/Payment";
import OrderReview from "../components/OrderReview/OrderReview";
import { BsShieldLockFill, BsBagFill, BsWindowDesktop } from "react-icons/bs";
import axios from "axios";
import { CartState } from "../context/Context";
import { getCart } from "./../ReactFunctions";
import Cookies from "universal-cookie";
import usePageDurationTracker from "../components/Hooks/usePageDurationTracker";

const CartSummaryCard = ({ data }) => {
  const {
    state: { cart },
  } = CartState();
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  return (
    <div
      className="flex h-[140px] items-start p-2 gap-3 font-man"
      key={data?.id}
    >
      <div className="w-fit max-h-[125px] md:w-[140px] md:min-w-[140px] md:h-[120px] md:min-h-[120px] hidden md:flex border border-gray-300 p-1 md:justify-center md:items-center rounded-md bg-gray-300">
        <img
          className="rounded-md object-contain md:w-[140px] md:min-w-[140px] md:h-[120px] md:min-h-[120px] w-[50px] h-[50px] mix-blend-multiply"
          src={data?.extension_attributes?.product_image_url}
          alt={data?.name + "img"}
        />
      </div>
      <div className="text-left flex flex-col gap-1">
        <p className="font-semibold text-base">{data?.name} </p>
        <div className="flex gap-2 justify-start items-center">
          <p className="text-base font-semibold">₹{Number(data?.price).toFixed(2)}</p>
          <p className="text-xs">X</p>
          <p className="text-base font-medium text-gray-600">{data?.qty}</p>
        </div>
      </div>
    </div>
  );
};

const Test = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const token = cookies?.get("auth_token");
  const location = useLocation();
  const [cartData, setCartData] = useState(
    location?.state ? location?.state : []
  );
  const [currentScreen, setCurrentScreen] = useState("address");
  const [selectedAddress, setSelectedAddress] = useState({});
  const [shippingCost, setShippingCost] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [taxes, setTaxes] = useState(null);
  const subtotal = cartData?.reduce(
    (total, item) => total + item?.price * item?.qty,
    0
  );
  const [orderTotal, setOrderTotal] = useState(null);
  const [fetchingTotals, setFetchingTotals] = useState(true);
  const cartHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (cartData?.length === 0) {
      getCart(cartHeaders, setCartData);
    }
  }, []);

  usePageDurationTracker("Checkout");

  return (
    <>
      <div className="main-container w-full flex flex-col items-center justify-start p-6 gap-8 font-man">
        <div className="border-b-[1px] border-[#C7C7C7] w-full py-3">
          <div className="flex items-center gap-2 justify-start">
            <BsShieldLockFill className="w-5 h-5" />
            <span className="text-xl px-1 text-[#2D2D2D] font-medium uppercase">
              Secure Checkout
            </span>
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row gap-4 justify-start items-start ">
          <div className="lg:w-3/5 md:w-1/2">
            {currentScreen === "address" && (
              <Address
                currentScreen={setCurrentScreen}
                updateShippingCost={setShippingCost}
                updateTaxes={setTaxes}
                updateDiscount={setDiscount}
                updateTotal={setOrderTotal}
                fetchingTotals={setFetchingTotals}
              />
            )}
            {currentScreen === "payment" && (
              <Payment currentScreen={setCurrentScreen} total={orderTotal} />
            )}
            {currentScreen === "order" && (
              <OrderReview currentScreen={setCurrentScreen} />
            )}
          </div>

          <div className="lg:w-2/5 md:w-1/2 w-full p-2 border-2 border-gray-300 rounded-md flex flex-col gap-4">
            <p className="flex gap-3 items-center p-3 px-2">
              <span>
                <BsBagFill size={26} />
              </span>
              <span className="font-bold text-lg">Your Order</span>
            </p>
            <div className="flex flex-col gap-3 max-h-[420px] scrollbar-hide py-2 overflow-hidden overflow-y-scroll border-t-2 divide-y-2">
              {cartData?.map((item, index) => {
                return <CartSummaryCard data={item} key={index} />;
              })}
            </div>
            {currentScreen === "payment" &&
              (fetchingTotals ? (
                <div className="h-[300px] w-full flex justify-center items-center text-center font-man">
                  Fetching details...
                </div>
              ) : (
                <div className="amount-container w-full flex flex-col py-4 gap-3">
                  <div className="p-2 flex justify-between items-center">
                    <p className="font-medium">Subtotal</p>
                    <p className="font-semibold">
                      ₹{parseFloat(subtotal).toFixed(2)}
                    </p>
                  </div>
                  {shippingCost !== null && (
                    <div className="p-2 flex justify-between items-center">
                      <p className="font-medium">Delivery</p>
                      <p className="font-semibold">
                        ₹{parseFloat(shippingCost).toFixed(2)}
                      </p>
                    </div>
                  )}
                  {taxes !== null && (
                    <div className="p-2 flex justify-between items-center">
                      <p className="font-medium">Taxes & Duties</p>
                      <p className="font-semibold">
                        ₹{parseFloat(taxes).toFixed(2)}
                      </p>
                    </div>
                  )}
                  {discount !== null && (
                    <div className="p-2 flex justify-between items-center">
                      <p className="font-medium">Discount</p>
                      <p className="font-semibold text-green-500">
                        - ₹{parseFloat(Math.abs(discount)).toFixed(2)}
                      </p>
                    </div>
                  )}
                  {orderTotal && (
                    <div className="px-2 py-5 rounded-md flex justify-between items-center border-t-2 border-gray-300">
                      <p className="font-bold text-xl">Total</p>
                      <p className="font-bold text-xl">
                        ₹{parseFloat(orderTotal).toFixed(2)}
                      </p>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Test;
