// import React, { useState } from 'react'
// import {AiOutlineDown} from 'react-icons/ai'

// const Accordion = ({ data }) => {
//     const [show, setShow] = useState(false)
//   return (
//     <div
//       id="accordion-flush"
//       data-accordion="open"
//       data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
//       data-inactive-classes="text-gray-500 dark:text-gray-400"
//     >
//       <h2 id="accordion-flush-heading-1" onClick={() => setShow(!show)}>
//         <button
//           type="button"
//           className="flex items-center justify-between w-full py-5 font-medium text-left  border-b border-gray-200 dark:border-gray-700 "
//           data-accordion-target="#accordion-flush-body-1"
//           aria-expanded="true"
//           aria-controls="accordion-flush-body-1"
//         >
//           <span>{data?.topic}</span>
//           <p className={`${show && "rotate-180"} duration-500 ease-in-out`}>
//             <AiOutlineDown />
//           </p>
//         </button>
//       </h2>
//       <div
//         id="accordion-flush-body-1"
//         className={`${
//           show ? "opacity-100 h-auto py-1" : "opacity-0 h-0"
//         } overflow-hidden transition-all duration-500 ease-in-out`}
//         aria-labelledby="accordion-flush-heading-1"
//       >
//         <div className="py-5">
//           <p className="mb-2 text-gray-500">{data?.info}</p>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Accordion

import { useState } from "react";

const AccordionItem = ({ title, content, isOpen, onClick }) => {
  return (
    <div className="border-b border-gray-200">
      <button
        onClick={onClick}
        className="w-full flex justify-between items-center p-4 hover:bg-gray-50 transition-colors"
      >
        <span className="font-medium text-gray-900">{title}</span>
        <svg
          className={`w-5 h-5 transform transition-transform duration-200 ${
            isOpen ? "rotate-180" : ""
          }`}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </button>
      <div
        className={`overflow-hidden transition-all duration-200 ease-out ${
          isOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <div className="p-4 bg-white text-gray-600">{content}</div>
      </div>
    </div>
  );
};

const Accordion = ({items}) => {
  const [openIndex, setOpenIndex] = useState(null);

  // const items = [
  //   {
  //     title: "What is an accordion?",
  //     content:
  //       "An accordion is a vertically stacked set of interactive headings that each contain a title, content snippet, or thumbnail representing a section of content.",
  //   },
  //   {
  //     title: "How does it work?",
  //     content:
  //       "The accordion component delivers large amounts of content in a small space through progressive disclosure. The header title gives the user a high-level overview of the content allowing them to decide which sections to read.",
  //   },
  //   {
  //     title: "Is it accessible?",
  //     content:
  //       "Yes, this accordion is built with accessibility in mind. It uses appropriate ARIA attributes and can be navigated using a keyboard.",
  //   },
  // ];

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="w-full max-w-full rounded-lg border border-gray-200 divide-y divide-gray-200">
      {items?.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.question}
          content={item.answer}
          isOpen={openIndex === index}
          onClick={() => handleClick(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;