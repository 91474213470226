import './App.css';
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Outlet, useLocation } from 'react-router-dom';
import Login from './screens/Login/Login';
import Register from './screens/Register/Register'
import Homepage from './screens/HomePage/Homepage'
import ProductPage from './screens/ProductPage/ProductPage';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Cart from './screens/Cart/Cart';
import Payment from './screens/Payment/Payment';
import Test from './screens/Test';
import ProductComparision from './screens/ProductComparision/ProductComparision';
import Success from './screens/Success/Success';
import Failure from './screens/Failure/Failure';
import PageNotFound from './screens/PageNotFound/PageNotFound';
// import Profile from './screens/Profile/Profile';
import Orders from './screens/Orders/Orders';
import OrderDetails from './screens/OrderDetails/OrderDetails';
import NewArrivals from './screens/NewArrivals/NewArrivals';
import Wishlist from './screens/Wishlist/Wishlist';
// import AWS from "aws-sdk";
import { CartState } from './context/Context';
import axios from 'axios'
import SearchResults from './screens/SearchResults/SearchResults';
import Newsletter from './screens/Newsletter/Newsletter';
import { getCart, usePageTracking } from './ReactFunctions';
import Cookies from 'universal-cookie'
import Account from './screens/Account/Account';
import Categories from './screens/Categories/Categories';
import rudderAnalytics from './rudderstack';
import PaymentTest from './components/PaymentTest/PaymentTest';
import {
  AuthWrapper,
  ProtectedRoute,
  PublicRoute,
  FlowProtectedRoute,
  getCookie,
} from "./SessionManager";
import ForgotPassword from './screens/ForgotPassword/ForgotPassword';
import TermsAndConditions from './screens/TermsAndConditions/TermsAndConditions';
import Coupons from './screens/Coupons/Coupons';



const Layout = () => {
  
  return (
    <div className="w-full flex flex-col">
      <div>
        <div>
          <Navbar />
        </div>
        {/* <div className="w-full relative z-[25]">
          <CategoriesMenu />
        </div> */}
      </div>

      <Outlet />
      <div className="w-full mt-24">
        <Footer />
      </div>
    </div>
  );
};

const rs = rudderAnalytics;

function App() {  
  const cookies = new Cookies()
  const { dispatch, userData } = CartState();
  const auth_token = getCookie("auth_token");
  const [cartData, setCartData] = useState([])
  const req_headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${auth_token}`,
  };


  const TrackPageView = () => {
    const location = useLocation();

    useEffect(() => {
      rs.page();
    }, [location]);

    return null;
  }
  

  // const fetchSecret = () => {
  //   const secretName = "Magento_Admin_Oauth";
  //   const awsConfig = {
  //     region: "ap-south-1", // Replace with your AWS region (e.g., 'us-east-1')
  //     accessKeyId: "AKIAU7OHNFO5I3II3FEN", // Replace with your access key ID
  //     secretAccessKey: "OGdK11ZBvriwKWW4VMxRmheJzcpuSmtdmnfap76D", // Replace with your secret access key
  //   };
  //   AWS.config.update(awsConfig);
  //   const secretsManager = new AWS.SecretsManager();
  //   secretsManager.getSecretValue({ SecretId: secretName }, (err, data) => {
  //     console.log("Service is running");
  //     if (err) {
  //       console.error("Error retrieving the secret:", err);
  //     } else {
  //       const secret = JSON.parse(data?.SecretString);
  //       console.log("secret key: ", secret);
  //       dispatch({
  //         type: "UPDATE_SECRET",
  //         payload: secret,
  //       });
  //     }
  //   });
  // };

  
  // const fetchUserData = () => {
  //   const url = `${process.env.REACT_APP_API_URL}/rest/V1/customers/me`;
  //   axios.get(url, { headers: req_headers })
  //   .then((response) => {
  //     dispatch({
  //       type: "UPDATE_USER_DATA",
  //       payload: {
  //         data: response?.data,
  //       },
  //     });
  //     localStorage?.setItem("userData", response?.data);
  //     rudderAnalytics.identify(response?.data?.id, {
  //       email: response?.data?.email,
  //       name: response?.data?.firstname + response?.data?.lastname,
  //     });
  //     console.log("Fetched user data: ", response?.data);
  //   })
  //   .catch((err) => {
  //     console.log(
  //       "error from fetching user Data: ",
  //       err?.response?.data?.message
  //     );
  //   });
  // }

  useEffect(() => {
       
      rs.ready(() => {
        console.log("Rudderstack: We are all set!!!");
      });
         
      
      // fetchSecret();
      if (auth_token) {
        // if (!userData?.id) { fetchUserData() }
        if (!cookies?.get("items_in_cart") !== undefined) {
          getCart(req_headers, setCartData);
        }
          
      };
  }, [auth_token]);

  useEffect(() => {
    const startTime = Date.now();
    sessionStorage.setItem("sessionStartTime", startTime);

    const handleBeforeUnload = () => {
      const sessionStartTime = sessionStorage.getItem("sessionStartTime");
      if (sessionStartTime) {
        const endTime = Date.now();
        const sessionDuration = endTime - sessionStartTime;
        const durationInSeconds = Math.floor(sessionDuration / 1000);
        rudderAnalytics.track("Session Duration", {
          sessionDuration: durationInSeconds,
        });
        sessionStorage.removeItem("sessionStartTime");
        alert("time spent on the site: " + durationInSeconds + " seconds");
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

   useEffect(() => {
    //  const handleBeforeUnload = (event) => {
    //    // Prevent refresh/closing on a specific route
    //    if (window.location.pathname === "/checkout") {
    //      event.preventDefault();
    //      event.returnValue = "";
    //      alert("Are you sure you want to close this tab?")
    //    }
    //  };

    //  // Add event listener for page refresh or tab close
    //  window.addEventListener("beforeunload", handleBeforeUnload);

    //  return () => {
    //    // Clean up the event listener when the component is unmounted
    //    window.removeEventListener("beforeunload", handleBeforeUnload);
    //  };
   }, [window.location]);

  useEffect(() => {
    console.log("updated on 12/11/24 updated")
    const abandonedCart = () => {
      const items_in_cart = Number(cookies?.get("items_in_cart"));
      if (items_in_cart > 0) {
        rudderAnalytics.track("Abandonded Cart", {
          numberOfItemsInCart: items_in_cart,
        });
      }
    }
    window.addEventListener("beforeunload", abandonedCart);
    return () => {
      window.removeEventListener("beforeunload", abandonedCart);
    }
  },[])


  //  useEffect(() => {
  //   const startTime = Date.now();
  //   sessionStorage.setItem("sessionStartTime", startTime);

  //   return () => {
  //   const sessionStartTime = sessionStorage.getItem("sessionStartTime");
  //   if (sessionStartTime) {
  //     const endTime = Date.now();
  //     const sessionDuration = endTime - sessionStartTime;
  //     const durationInSeconds = Math.floor(sessionDuration / 1000);
  //     rudderAnalytics.track("Session Duration", {
  //       sessionDuration: durationInSeconds,
  //     });
      
  //     sessionStorage.removeItem("sessionStartTime");
  //   }
  //   };
  //  }, []);
  
  

  return (
    <>
      <Router>
        <AuthWrapper>
          <TrackPageView />
          <Routes>
            <Route exact path="/" element={<Layout />}>
              <Route
                path="/"
                element={
                  <PublicRoute>
                    <Homepage />
                  </PublicRoute>
                }
              />
              <Route
                path="/product/:sku/:prodname"
                element={
                  <PublicRoute>
                    <ProductPage />
                  </PublicRoute>
                }
              />

              <Route
                exact
                path="/cart"
                element={
                  <ProtectedRoute>
                    <FlowProtectedRoute>
                      <Cart />
                    </FlowProtectedRoute>
                  </ProtectedRoute>
                }
              />
              {/* <Route exact path="/checkout" element={<Checkout />} /> */}
              <Route
                path="/payment"
                element={
                  <ProtectedRoute>
                    <FlowProtectedRoute>
                      <Payment />
                    </FlowProtectedRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/checkout"
                element={
                  <ProtectedRoute>
                    <FlowProtectedRoute>
                      <Test />
                    </FlowProtectedRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/success"
                element={
                  <ProtectedRoute>
                    <FlowProtectedRoute>
                      <Success />
                    </FlowProtectedRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/failure"
                element={
                  <ProtectedRoute>
                    <FlowProtectedRoute>
                      <Failure />
                    </FlowProtectedRoute>
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/account"
                element={
                  <ProtectedRoute>
                    <Account />
                  </ProtectedRoute>
                }
              />

              <Route
                exact
                path="/orders"
                element={
                  <ProtectedRoute>
                    <Orders />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/order/:orderId"
                element={
                  <ProtectedRoute>
                    <OrderDetails />
                  </ProtectedRoute>
                }
              />
              <Route exact path="/new_arrivals" element={<NewArrivals />} />
              <Route
                exact
                path="/wishlist"
                element={
                  <ProtectedRoute>
                    <Wishlist />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/coupons"
                element={
                  <ProtectedRoute>
                    <Coupons />
                  </ProtectedRoute>
                }
              />
              <Route exact path="/payt" element={<PaymentTest />} />
              <Route
                exact
                path="/search-result/:searchWord"
                element={<SearchResults />}
              />
              <Route path="/categories/:id/:name" element={<Categories />} />
              <Route
                exact
                path="/compare-products"
                element={<ProductComparision />}
              />
              <Route exact path="/newsletter" element={<Newsletter />} />
              {/* <Route exact path="/success" element={<Success />} /> */}
            </Route>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/forgot_password" element={<ForgotPassword />} />
            <Route
              exact
              path="/terms_and_conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/*" element={<PageNotFound />} />
          </Routes>
        </AuthWrapper>
      </Router>
    </>
  );
}

export default App;
