import axios from "axios";
import React, { useEffect, useState } from "react";
import ProductCard from "../ProductCard/ProductCard";
// import { useNavigate } from "react-router-dom";
import { CartState } from "../../context/Context";
// import Loader from "../Loader/Loader";
import ProductCardSkeleton from "../ProductCardSkeleton/ProductCardSkeleton";

const Product = () => {

    // const navigate = useNavigate();
    const { state: { compare }, dispatch } = CartState();
    const userData = JSON.parse(localStorage?.getItem("userData") || "{}");
    const [productList, setProductList] = useState([]);
    const [cartId, setCartId] = useState(null)
    const header_token = { "username": "admin", "password": "Admin@12345" }
    const [loading, setLoading] = useState(true)
    
    // const token = window?.sessionStorage.getItem("auth_token")
    

    // const guestCartId = () => {
    //     const getCart_headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${header_token}`
    //     }
    //     axios.post(`${process.env.REACT_APP_API_URL}/rest/V1/guest-carts`, { headers: getCart_headers })
    //     .then((response) => {
    //         setCartId(response?.data)
    //     })
    //     .catch((error) => {
    //         console.log("error from getCartID: ", error)
    //     })
    // }

    const fetchProducts = (updateProducts) => {
        axios
          .get(
            `${
              process.env.REACT_APP_API_URL
            }/rest/V1/products?searchCriteria[pageSize]=10&customerId=${
              userData?.id ?? ""
            }`
          )
          .then((res) => {
              setProductList(res?.data?.items);
              setLoading(false)
          })
            .catch((err) => {
              setLoading(false);
            console.log("Error while fetching products: ", err);
          });
    }

  

    useEffect(() => {
      fetchProducts();
    }, [])
  

    return (
      <div className="w-full flex flex-col items-start justify-center md:gap-5 font-man min-h-[450px] relative">
        <div className="flex flex-col md:flex-row justify-start w-full gap-4">
          <p className="md:text-2xl text-lg md:p-5 px-3">
            PRODUCT RECOMMENDATIONS
          </p>
        </div>
        {loading ? (
          <div className="w-full flex md:flex-wrap md:gap-5 gap-3 p-3 md:p-5 md:justify-center justify-start items-center overflow-x-scroll scrollbar-hide mt-6">
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
          </div>
        ) : (
          <div className="w-full flex md:flex-wrap md:gap-5 gap-3 p-3 md:p-5 md:justify-center justify-start items-center overflow-x-scroll scrollbar-hide mt-6">
            {productList?.length !== 0 &&
              productList.map((data, index) => {
                return <ProductCard data={data} key={index} />;
              })}
          </div>
        )}
      </div>
    );
}
export default Product;