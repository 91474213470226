import React, { useEffect, useState } from 'react'
import axios from 'axios'
import HeroSection from './../../components/HeroSection/HeroSection'
import CustomUserSection from '../../components/CustomUserSection/CustomUserSection'
import PromotionSection from '../../components/PromotionSection/PromotionSection'
import ShopCategory from '../../components/ShopCategory/ShopCategory'
import Product from '../../components/Product/Product'
import FeaturedBrands from '../../components/FeaturedBrands/FeaturedBrands'
import { CartState } from '../../context/Context';
import Cookies from 'universal-cookie'
import usePageDurationTracker from '../../components/Hooks/usePageDurationTracker'
import { createUserCart } from '../../ReactFunctions'




const Homepage = () => {
  const cookies = new Cookies()
  const auth_token = cookies?.get("auth_token")
  const { state: { userData }, dispatch } = CartState();
  const address_headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${auth_token}`
  }



  usePageDurationTracker("Homepage");

  // const fetchUserData = () => {
  //   axios.get("http://65.0.150.104/rest/V1/customers/me", null, {headers: headers})
  //   .then((response) => {
  //     dispatch({
  //       type: "UPDATE_USER_DATA",
  //       payload: response?.data
  //     })  
  //     console.log("Fetched user data: ", response?.data)
  //   })
  //   .catch((err) => {
  //     console.log('error from fetching user Data: ', err?.response?.data?.message)  
  //   })
  // }

  useEffect(() => {
    window.scroll(0,0)
    if (!localStorage?.getItem("cart_id")) createUserCart(address_headers);
  },[])

  return (
    <div className="flex flex-col gap-4 w-full min-h-max !scroll-smooth">
      <section>
        <HeroSection />
      </section>
      <section>
        <Product />
      </section>
      <section>
        <PromotionSection />
      </section>

      {/* <CustomUserSection /> */}

      {/* <ShopCategory /> */}

      {/* <FeaturedBrands /> */}
    </div>
  );
}

export default Homepage