import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import a from "./../../assets/images/a.png";
import debitcard from "./../../assets/images/cards.png"
import PayonDelivery from "./../../assets/images/cod.png";
import { FaCheck } from 'react-icons/fa'
import crypto from "crypto-js";
import { CartState } from "../../context/Context";
import { updateNavigationFlow } from "../../SessionManager";


const Payment = ({ currentScreen, total }) => {
    const { dispatch } = CartState();
    const userData = JSON.parse(localStorage?.getItem("userData") || "{}");
    const navigate = useNavigate()
    const [showCardForm, setShowCardForm] = useState(false)
    const [showCod, setShowCod] = useState(false)
    const [selectedMethod, setSelectedMethod] = useState('')
    const [shippingAddress, setShippingAddress] = useState(null)
    const [txnid, setTxnid] = useState('')
    const [hash, setHash] = useState("")


     const key = "fy7PkC";
     // const txnid = 't8svtqtjbdlXub'
     const salt = "8X5uJsfLLWfbCTTuiVOmCHqiTJk1Muek";

     //for user information start
     const firstName = userData?.firstname;
     const lastName = userData?.lastname;
     const amount = total;
     const productInfo = "clothing";
     const email = userData?.email;
     const successURL = process.env.REACT_APP_SUCCESS_URL;
     const failureURL = process.env.REACT_APP_FAILURE_URL;
     let upiHash =
       key +
       `|` +
       txnid +
       `|` +
       amount +
       `|` +
       productInfo +
       `|` +
       firstName +
       `|` +
       email +
       `|||||||||||` +
       salt;
     const upiHashValue = crypto.SHA512(upiHash).toString();


    // const generateTransactionID = () => {
    //   const characters =
    //     "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    //   const idLength = 14;
    //   let id = "";

    //   for (let i = 0; i < idLength; i++) {
    //     const randomIndex = Math.floor(Math.random() * characters.length);
    //     id += characters[randomIndex];
    //   }

    //   const date = new Date().getTime().toString();
    //   const txnid = id + date;
    //   console.log("Generated txnid: ", txnid);
    //   setTxnid(txnid);
    //   const key = "fy7PkC";
    //   const amount = total;
    //   const productInfo = "clothing";
    //   const firstName = userData?.firstName;
    //   const email = userData?.email;
    //   const salt = "8X5uJsfLLWfbCTTuiVOmCHqiTJk1Muek";
    //  const hashString = `${key}|${txnid}|${amount}|${productInfo}|${firstName}|${email}|udf1|udf2|udf3||||||||${salt}`;
    //   const upiHash = crypto.SHA512(hashString).toString();

    //   console.log("Generated Hash: ", upiHash);
    //   setHash(upiHash);
    // };

    const generateTransactionID = () => {
      const characters =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      const idLength = 14;
      let id = "";
      for (let i = 0; i < idLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        id += characters[randomIndex];
      }
      const date = new Date().getTime().toString();
      console.log("generated id: ", id + date);
      setTxnid(id + date);
    };

    const handleProceedToPayment = () => {
        sessionStorage.setItem("payuReturnAccess", "true");
        sessionStorage.setItem("lastPath", "/checkout");
        console.log("Session flag set for PayU return.");
    };

    const handleFormSubmit = (e) => {
      e.preventDefault(); // Prevent default form submission
      handleProceedToPayment();
      e.target.submit(); // Submit the form programmatically after setting flag
    };




    useEffect(() => {
        window.scrollTo(0, 0)
        // setShippingAddress(JSON.parse(sessionStorage?.getItem("sa")))
        // console.log("Picking up Shipping address from session storage: ", JSON.parse(sessionStorage?.getItem("sa")))
        generateTransactionID()
    }, [])

    return (
      <div className="flex flex-col w-full  font-outfit gap-4">
        <div className="w-full  gap-3 items-center justify-center font-medium hidden sm:flex">
          <div className="flex gap-3 items-center">
            <p className="rounded-full bg-green-500 text-white p-2 w-7 h-7 flex items-center justify-center">
              <FaCheck size={22} color="white" />
            </p>
            <p>Information</p>
          </div>
          <hr className="h-0.5 w-[60px] border-t-0 bg-black opacity-100 dark:opacity-50" />
          <div className="flex gap-3 items-center">
            <p className="rounded-full bg-black text-white p-2 w-7 h-7 flex items-center justify-center">
              2
            </p>
            <p>Payment</p>
          </div>
          <hr className="h-0.5 w-[60px] border-t-0 bg-black opacity-100 dark:opacity-50" />
          <div className="flex gap-3 items-center">
            <p className="rounded-full bg-black text-white p-2 w-7 h-7 flex items-center justify-center">
              3
            </p>
            <p>Order Placed</p>
          </div>
        </div>
        <div className="w-full flex flex-col items-start justify-start">
          {/*Payment method starts*/}
          <div className="w-full md:p-3 flex-col flex gap-2">
            <div className="flex items-end pt-3 pb-1">
              <img
                className="self-center px-1 w-7 h-auto max-w-full"
                src={a}
                alt="icon"
              />
              <span className="text-xl px-2 text-[#4D4C4C]  font-normal">
                Payment Method
              </span>
            </div>
            <span className="text-base font-normal">
              Select a payment method and provide your payment details to
              complete the Order.
            </span>
            {/* <form> */}
            <div
              className="border-[#606060] p-3 rounded-lg flex justify-between bg-[#F0F2F6] cursor-pointer"
              onClick={() => {
                setShowCardForm(!showCardForm);
                setShowCod(false);
                window.localStorage.setItem("payment", "payu");
              }}
            >
              <div className="flex rounded-md w-full p-4">
                <img
                  src={debitcard}
                  alt="logo"
                  className="px-1 h-auto max-w-full"
                />
                <div className="px-2">
                  <h4 className="text-base font-medium uppercase">
                    Online Payment
                  </h4>
                  <p className="text-sm text-[#606060]  font-normal">
                    All type of Cards, UPI, Wallets, Netbanking accepted
                  </p>
                </div>
              </div>
            </div>
            {
              showCardForm && (
                <div className="">
                  <form
                    action="https://test.payu.in/_payment"
                    method="post"
                    onSubmit={handleFormSubmit}
                  >
                    <input type="hidden" name="key" value={key} />
                    <input type="hidden" name="txnid" value={txnid} />
                    <input
                      type="hidden"
                      name="productinfo"
                      value={productInfo}
                    />
                    <input type="hidden" name="amount" value={amount} />
                    <input type="hidden" name="email" value={email} />
                    <input type="hidden" name="firstname" value={firstName} />
                    <input type="hidden" name="lastname" value={lastName} />
                    {/* <input type="hidden" name="pg" value="UPI" />
                                <input type="hidden" name="bankcode" value="UPI" />
                                <input type="hidden" name="vpa" value=" " /> */}
                    <input type="hidden" name="surl" value={successURL} />
                    <input type="hidden" name="furl" value={failureURL} />
                    <input type="hidden" name="phone" value="8686712087" />
                    <input type="hidden" name="hash" value={upiHashValue} />
                    <button className="w-full px-3 py-2 flex justify-center rounded-md items-center bg-black text-white focus:outline-none ">
                      PAY ₹<span className="font-medium">{amount}</span>
                    </button>
                  </form>
                </div>
              )
              // <form action="">
              //     <div className="grid lg:grid-cols-6 md:grid-cols-4 gap-3 my-3 p-3 ">
              //         <div className="col-span-3">
              //             <label  className="text-sm font-medium">ENTER CARD NUMBER</label>
              //             <input className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none" type="text" placeholder="XXXX-XXXX-XXXX-XXXX" required/>
              //         </div>

              //         <div className="col-span-2">
              //             <label className="text-sm font-medium">EXPIRY DATE</label>
              //             <input className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none" type="text" placeholder="MM/YY" required/>
              //         </div>

              //         <div className="col-span-1">
              //             <label className="text-sm font-medium">CVV</label>
              //             <input className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none" type="text" placeholder="CVV" required/>
              //         </div>
              //     </div>
              //     <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-3 p-1 px-3 ">
              //         <div>
              //             <label className="text-sm font-medium">CARD HOLDER NAME</label>
              //             <input className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none" type="text" placeholder="Full Name" required/>
              //         </div>
              //         <div>
              //             <button className="w-full px-3 py-2 flex justify-center items-center gap-2 bg-black text-white focus:outline-none lg:mt-[25px] md:mt-[25px] sm:mt-0">PAY <span className="font-medium">₹9,135</span></button>
              //         </div>
              //     </div>
              // </form>
            }

            {/* <div className="flex justify-center">
                  
                    <input className="self-center checked:text-black-500 h-4 w-4 accent-black" type="radio"/>
                </div> */}

            {/* <span className="text-[#5B5B5B]  font-normal text-xs px-3">Your card details will be securely saved for faster payments, your CVV will not be stored</span> */}

            <div
              className="p-3 rounded-lg flex justify-between bg-[#F0F2F6] cursor-pointer"
              onClick={() => {
                setShowCod(!showCod);
                setShowCardForm(false);
                window?.localStorage?.setItem("payment", "cashondelivery");
              }}
            >
              <div className="flex rounded-md w-full p-4">
                <img
                  src={PayonDelivery}
                  alt="logo"
                  className="px-1 h-auto max-w-full"
                />
                <div className="px-2">
                  <h4 className="text-base font-medium uppercase">
                    Pay on delivery
                  </h4>
                  <p className="text-sm text-gray-500">
                    Pay with any mode of payment at the time of delivery
                  </p>
                </div>
              </div>
            </div>
            {showCod && (
              <div className="px-3 w-full">
                <button
                  className="w-full px-3 py-2 flex justify-center rounded-md items-center gap-2 bg-black text-white focus:outline-none"
                  onClick={() => {
                    const currentPath = window.location.pathname;

                    // Update the navigation flow history before leaving the /checkout page
                    updateNavigationFlow(currentPath);
                    navigate("/success");
                  }}
                >
                  PLACE ORDER <span className="font-medium">₹{amount}</span>
                </button>
              </div>
            )}

            {/* <div className="p-3 mt-5 rounded-lg flex justify-between bg-[#F0F2F6] cursor-pointer">
                <div className="flex">
                    <img src={NetBanking} alt="logo" className="px-1 h-auto max-w-full" />
                    <div className="px-2">
                    <h4 className="text-base font-medium uppercase">Net Banking</h4>
                    <p className="text-sm text-gray-500">Use internet banking from preferred bank to Pay</p>
                    </div>
                </div>
                <div className="flex justify-center">
                    
                    <input className="self-center checked:text-black-500 h-4 w-4 accent-black" type="radio"/>
                </div>
            </div> */}

            {/* <div class="flex items-center my-5">
                <hr class="w-full border-t-2 border-[#F0F2F6]"/>
                <span class="text-base font-normal  px-2 text-black whitespace-nowrap">Express Checkout</span>
                <hr class="w-full border-t-2 border-[#F0F2F6]"/>
            </div>

            <div className="grid grid-cols-3 gap-3 mt-5">
                <div className="p-1 bg-[#F0F2F6] flex justify-center cursor-pointer">
                    <img src={ApplePay} alt="logo" className="px-2 h-auto max-w-full" />
                    <h4 className="text-xl font-normal  text-black self-center">Apple Pay</h4>
                </div>
                <div className="p-1 bg-[#F0F2F6] flex justify-center cursor-pointer">
                    <img src={PayPal} alt="logo" className="px-2 h-auto max-w-full" />
                    <h4 className="text-xl font-normal  text-black self-center">PayPal</h4>
                </div>
                <div className="p-1 bg-[#F0F2F6] flex justify-center cursor-pointer">
                    <img src={GPay} alt="logo" className="px-2 h-auto max-w-full" />
                    <h4 className="text-xl font-normal  text-black self-center">G-Pay</h4>
                </div>
            </div> */}
            {/* </form> */}
          </div>
          {/*Payment method ends*/}

          {/*order summary starts*/}
        </div>
        <div className="md:p-3 w-full flex justify-between items-center">
          <button
            className="font-sm text-black border-black border rounded-md cursor-pointer text-sm px-4 py-2 flex justify-center items-center"
            onClick={() => {
              currentScreen("address");
            }}
          >
            BACK
          </button>
          {/* <button className='font-sm text-white rounded-md bg-black cursor-pointer text-sm px-4 py-2 flex justify-center items-center'
                    // onClick={() => {
                    //     currentScreen('order')
                    // }}
                    onClick={() => navigate("/success")}
                >CONTINUE</button> */}
        </div>
      </div>
    );
}

export default Payment;