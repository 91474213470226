import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import usePageDurationTracker from "../../components/Hooks/usePageDurationTracker";

const Register = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useState();
  const [countryData, setCountryData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  // const log = { username: "admin", password: "Admin@12345" };
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [phone, setPhone] = useState("9999999999");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);
  const [registering, setRegistering] = useState(false);
  // const [address, setAddress] = useState("")
  // const [street, setStreet] = useState("")
  // const [zip, setZip] = useState("")
  const [emailAvailable, setEmailAvailable] = useState();
  // const [selectedRegion, setSelectedRegion] = useState()

  const [executeDebouncer, setExecuteDebouncer] = useState(false);
  const debounceCheckEmailAvailibity = useCallback(
    debounce(() => setExecuteDebouncer(true), 750),
    []
  );

   usePageDurationTracker("Register");

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsValidPassword(validatePassword(newPassword));
    setPasswordsMatch(newPassword === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setPasswordsMatch(password === newConfirmPassword);
  };

  const validatePassword = (password) => {
    console.log("password validation: ", password);
    // Password validation criteria
    const lengthRegex = /.{8,}/;
    const numberRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

    return (
      lengthRegex.test(password) &&
      numberRegex.test(password) &&
      uppercaseRegex.test(password) &&
      specialCharRegex.test(password)
    );
  };

  const registerData = {
    email: email,
    firstname: firstName,
    lastname: lastName,
    password: password,
    phone_number: phone,
  };

  // const registerData = {
  //   customer: {
  //     email: email,
  //     firstname: firstName,
  //     lastname: lastName,
  //     addresses: [],
  //     extension_attributes: { is_subscribed: true },
  //     custom_attributes: [
  //       {
  //         attribute_code: "phone_number",
  //         value: phone,
  //       },
  //     ],
  //   },
  //   password: password,
  // };

  const sendSignupEmail = (email) => {
    axios.post(`${process.env.REACT_APP_API_URL}/rest/V1/send-welcome-email`, {email:email})
    .then(res => {
      console.log("email sent from registration: ", res)
    })
    .catch(err => {
      console.log("error while sending welcome em to user: ", err)  
    })
  }

  // const checkEmailAvailibity = () => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL}/index.php/rest/V1/customers/isEmailAvailable`,
  //       emData,
  //       { Authorization: `Bearer ${log}` }
  //     )
  //     .then((response) => {
  //       console.log("Response from em avail: ", response?.data);
  //       setEmailAvailable(response?.data);
  //     })
  //     .catch((err) => {
  //       console.log("Error from em av: ", err);
  //     });
  // };

  const handleRegister = () => {
    
    let isError = true; // Variable to track if any error occurs

    // Check if first name is empty
    if (!firstName.trim()) {
      isError = false;
    }

    // Check if last name is empty
    if (!lastName.trim()) {
      isError = false;
    }

    // Check if phone number is empty or invalid
    if (!phone.trim() || !phone.match(/[0-9]{10}/)) {
      isError = false;
    }

    // Check password validation
    if (isValidPassword && passwordsMatch && isError) {
      setRegistering(true);
      axios
        .post(
          // `${process.env.REACT_APP_API_URL}/index.php/rest/V1/customers`,
          `${process.env.REACT_APP_API_URL}/rest/V1/fastcustomer/create`,
          registerData,
          // { Authorization: `Bearer ${log}` }
        )
        .then((response) => {
          console.log("response from register: ", response?.data);
          if (response?.status === 200) {
            setRegistering(false);
            alert("Signed Up successfully. Please login to continue shopping");
            navigate("/login");
            sendSignupEmail(email);
          }
        })
        .catch((error) => {
          setRegistering(false);
          if (error?.response) {
            
            setShowError(true);
            setErrorMessage(error?.response?.data?.message);
            console.log(
              "error from login post if: ",
              error?.response?.data?.message
            );
          }
        });
    } else {
      // Show error for invalid password or mismatched passwords

      if (!isValidPassword) {
        setShowError(true);
        setErrorMessage(
          "Password should contain 8 or more characters, 1 uppercase letter, 1 special character and 1 number"
        );
      } else if (!passwordsMatch) {
        setShowError(true);
        setErrorMessage("Password and Confirm Password does not match");
      }
    }

    // Show error if any field is empty or invalid
    if (!isError) {
      setShowError(true);
      setErrorMessage("Please fill in all required fields correctly.");
    }
  };

  // const handleRegister = () => {
  //   if (isValidPassword && passwordsMatch) {
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_API_URL}/index.php/rest/V1/customers`,
  //         registerData,
  //         { Authorization: `Bearer ${log}` }
  //       )
  //       .then((response) => {
  //         console.log("response from register: ", response?.data);
  //         if (response?.status === 200) {
  //           alert("Signed Up successfully. Please login to continue shopping");
  //           navigate("/login");
  //         }
  //       })
  //       .catch((error) => {
  //         if (error?.response) {
  //           setShowError(true);
  //           setErrorMessage(error?.response?.data?.message);
  //           console.log(
  //             "error from login post if: ",
  //             error?.response?.data?.message
  //           );
  //         }
  //       });
  //   } else if (!isValidPassword) {
  //     setShowError(true);
  //     setErrorMessage(
  //       "Password should contain 8 or more characters, 1 uppercase letter, 1 special character and 1 number"
  //     );
  //   } else if (!passwordsMatch) {
  //     setShowError(true);
  //     setErrorMessage("Password and Confirm Password does not match");
  //   }
  // };

  // useEffect(() => {
  //   if (executeDebouncer && email?.length > 5) {
  //     setExecuteDebouncer(false);
  //     checkEmailAvailibity();
  //   }
  // }, [executeDebouncer]);

  // useEffect(() => {
  //   fetchCountryData();
  // }, [])

  useEffect(() => {
    if (email?.length > 7) setEmailAvailable("");
  }, [email]);

  useEffect(() => {
    setRegionData(
      countryData?.filter((cty) => cty?.full_name_english === country)?.[0]
        ?.available_regions
    );
  }, [country]);

  return (
    <section className="bg-gray-50 dark:bg-gray-900 font-outfit py-8 min-h-screen flex justify-center items-center">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto gap-4 lg:py-0">
        <p className="flex items-center text-2xl font-semibold text-gray-900 dark:text-white">
          <img
            className="w-8 h-8 mr-2"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg"
            alt="logo"
          />
          Magento eCommerce
        </p>
        {showError && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 relative w-full rounded-lg shadow dark:border md:mt-0 sm:max-w-md flex"
            role="alert"
          >
            <div className="gap-3 flex">
              <p className="block sm:inline md:text-base sm:text-sm text-xs">
                <span className="font-bold">Oops! </span>
                <span>{errorMessage}</span>
              </p>
            </div>

            <span className="flex px-1 py-3 items-center">
              <svg
                onClick={() => setShowError(false)}
                className="fill-current h-6 w-6 text-red-500"
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </div>
        )}
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Create an account
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={(e) => {
                e.preventDefault();
                handleRegister();
              }}
            >
              <div className="w-full flex gap-3">
                {/* <div className='w-1/2'>
                  <label htmlFor="fName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                  
                      <input value={firstName} autoComplete='off' onChange={(e)=>setFirstName(e?.target?.value)} type="text" name="first_name" id="fName" className="bg-gray-50 border text-xs md:text-base sm:text-sm border-gray-300 text-gray-900  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required />
                </div> */}
                {/* <div className='w-1/2'>
                      <label htmlFor="lName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                      <input value={lastName} autoComplete='off' onChange={(e)=>setLastName(e?.target?.value)} type="text" name="last_name" id="lName" className="bg-gray-50 border text-xs md:text-base sm:text-sm border-gray-300 text-gray-900  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Doe" required />
                </div> */}
                <div>
                  <label
                    htmlFor="fName"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    First Name
                  </label>
                  <input
                    value={firstName}
                    autoComplete="off"
                    onChange={(e) => setFirstName(e?.target?.value)}
                    type="text"
                    name="first_name"
                    id="fName"
                    className="bg-gray-50 border text-xs md:text-base sm:text-sm border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="John"
                    required // Make it required
                    minLength={2} // Minimum length
                    pattern="[A-Za-z]+" // Only allow alphabets
                  />
                  {!firstName && showError && (
                    <span className="text-red-500">First Name is required</span>
                  )}
                </div>

                {/* // Last Name Input */}
                <div>
                  <label
                    htmlFor="lName"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Last Name
                  </label>
                  <input
                    value={lastName}
                    autoComplete="off"
                    onChange={(e) => setLastName(e?.target?.value)}
                    type="text"
                    name="last_name"
                    id="lName"
                    className="bg-gray-50 border text-xs md:text-base sm:text-sm border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Doe"
                    required // Make it required
                    minLength={2} // Minimum length
                    pattern="[A-Za-z]+" // Only allow alphabets
                  />
                  {!lastName && showError && (
                    <span className="text-red-500">Last Name is required</span>
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  value={email}
                  autoComplete="off"
                  onChange={(e) => {
                    setEmail(e?.target?.value);
                    // debounceCheckEmailAvailibity();
                  }}
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 text-xs md:text-base border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="john@example.com"
                  required
                />
                {/* {(emailAvailable === false) && <span>Email is not available. Try a different email address</span>} */}
              </div>
              {/* <div>
                    <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone</label>
                    <input value={phone} autoComplete='off' onChange={(e)=>setPhone(e?.target?.value)} type="tel" name="phone" id="phone" className="bg-gray-50 border text-xs md:text-base sm:text-sm border-gray-300 text-gray-900  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="00000000000" required />
                  </div> */}
              {/* <div>
                <label
                  htmlFor="phone"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Phone
                </label>
                <input
                  value={phone}
                  autoComplete="off"
                  onChange={(e) => setPhone(e?.target?.value)}
                  type="tel"
                  name="phone"
                  id="phone"
                  className="bg-gray-50 border text-xs md:text-base sm:text-sm border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="00000000000"
                  required // Make it required
                  pattern="[0-9]{10}" // Only allow 10 digits
                />
                {!phone && showError && (
                  <span className="text-red-500">Phone Number is required</span>
                )}
                {phone && !phone.match(/[0-9]{10}/) && showError && (
                  <span className="text-red-500">
                    Phone Number must be 10 digits
                  </span>
                )}
              </div> */}
              <div className="relative">
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  value={password}
                  autoComplete="off"
                  onChange={handlePasswordChange}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border text-xs md:text-base  border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 top-6 right-3 flex items-center cursor-pointer text-gray-600 dark:text-gray-400"
                >
                  {showPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-6 w-6 text-gray-400"
                    >
                      <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-6 w-6 text-gray-400"
                    >
                      <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                      <path
                        fillRule="evenodd"
                        d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </span>
              </div>
              <div className="relative">
                <label
                  htmlFor="confirm-password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Confirm password
                </label>
                <input
                  value={confirmPassword}
                  autoComplete="off"
                  onChange={handleConfirmPasswordChange}
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirm-password"
                  id="confirm-password"
                  placeholder="••••••••"
                  className="bg-gray-50 border text-xs md:text-base  border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
                <span
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute inset-y-0 top-6 right-3 flex items-center cursor-pointer text-gray-600 dark:text-gray-400"
                >
                  {showConfirmPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-6 w-6 text-gray-400"
                    >
                      <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-6 w-6 text-gray-400"
                    >
                      <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                      <path
                        fillRule="evenodd"
                        d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </span>
              </div>
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="terms"
                    aria-describedby="terms"
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                    required
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="terms"
                    className="font-light text-gray-500 dark:text-gray-300"
                  >
                    I accept the{" "}
                    <span
                      className="font-medium text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
                      onClick={() => navigate("/terms_and_conditions")}
                    >
                      Terms and Conditions
                    </span>
                  </label>
                </div>
              </div>
              <button
                type="submit"
                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Create an account
              </button>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Already have an account?{" "}
                <span
                  onClick={() => navigate("/login")}
                  className="font-medium text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
                >
                  Login here
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
      <dialog
        open={registering}
        className="p-3 inset-0 fixed z-10 bg-black bg-opacity-50 w-full min-h-screen font-pop"
      >
        <div className="w-full min-h-screen loading-container p-4 flex justify-center items-center flex-col gap-6">
          <svg
            aria-hidden="true"
            className="w-8 h-8 mr-2 text-gray-200 animate-spin  fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <p className="text-white">Registering your details...</p>
        </div>
      </dialog>
    </section>
    // <div className="login-container">
    //   <div className="image-container">
    //     <img src="https://images.unsplash.com/photo-1482003297000-b7663a1673f1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80" alt="login-img" height="100%" width="100%" />
    //   </div>
    //   <form className="form" onSubmit={(e) => {
    //           e.preventDefault()
    //           handleRegister()
    //   }}>
    //     <p className='intro-text'>Register</p>

    //     <div className="input-box">
    //           <FaUser name="email" size={22} color="black" />
    //           <input
    //             type='text'
    //             value={firstName}
    //             placeholder='First Name'
    //             numberOfLines={1}
    //             autoComplete='off'
    //             onChange={(e)=>setFirstName(e.target.value)}
    //             className='inputField'

    //           />
    //     </div>
    //     <div className="input-box">
    //           <FaUser name="email" size={22} color="black" />
    //           <input
    //             type='text'
    //             value={lastName}
    //             placeholder='Last Name'
    //             numberOfLines={1}
    //             autoComplete='off'
    //             onChange={(e)=>setLastName(e.target.value)}
    //             className='inputField'

    //           />
    //     </div>
    //     <div className="input-box">
    //           <HiMail name="email" size={22} color="black" />
    //           <input
    //             type='email'
    //             value={email}
    //             placeholder='Email'
    //             numberOfLines={1}
    //             autoComplete='off'
    //             onChange={(e) => {
    //             setEmail(e?.target?.value)
    //             debounceCheckEmailAvailibity();
    //             }}
    //             className='inputField'

    //           />
    //       {emailAvailable && <span>✅</span>}
    //     </div>
    //     <div className="input-box">
    //           <BsTelephoneFill size={22} color="black" />
    //           <input
    //             type='text'
    //             value={phone}
    //             placeholder='Phone'
    //             numberOfLines={1}
    //             autoComplete='off'
    //             onChange={(e)=>setPhone(e?.target?.value)}
    //             className='inputField'
    //           />
    //     </div>
    //      <div className="input-box">
    //           <BsFillLockFill size={22} color="black" />
    //           <input
    //             type='password'
    //             value={password}
    //             placeholder='Password'
    //             numberOfLines={1}
    //             autoComplete='off'
    //             onChange={(e)=>setPassword(e?.target?.value)}
    //             className='inputField'
    //           />
    //     </div>
    //     <input type="submit" className='login-submit-btn' value="Register" />
    //   </form>
    // </div>
  );
};

export default Register;

// import React, { useState, useEffect, useCallback } from 'react'
// import axios from 'axios'
// import { debounce } from "lodash";
// import { useNavigate } from 'react-router-dom';

// const Register = () => {
//   const navigate = useNavigate()
//   const [country, setCountry] = useState()
//   const [countryData, setCountryData] = useState([])
//   const [regionData, setRegionData] = useState([])
//   const log = { "username": "admin", "password": "Admin@12345" }
//   const [email, setEmail] = useState("")
//   const [firstName, setFirstName] = useState("")
//   const [lastName, setLastName] = useState("")
//   const [password, setPassword] = useState("")
//   const [confirmPassword, setConfirmPassword] = useState("")
//   const [phone, setPhone] = useState("")
//   const [showError, setShowError] = useState(false)
//   const [errorMessage, setErrorMessage] = useState("")
//   const [isValidPassword, setIsValidPassword] = useState(true);
//   const [passwordsMatch, setPasswordsMatch] = useState(true);
//   const [termsChecked, setTermsChecked] = useState(false);
//   // const [address, setAddress] = useState("")
//   // const [street, setStreet] = useState("")
//   // const [zip, setZip] = useState("")
//   const [emailAvailable, setEmailAvailable] = useState()
//   // const [selectedRegion, setSelectedRegion] = useState()

//   const [executeDebouncer, setExecuteDebouncer] = useState(false);
//   const debounceCheckEmailAvailibity = useCallback(
//     debounce(() => setExecuteDebouncer(true), 750),
//     []
//   );

//   const handlePasswordChange = (e) => {
//     const newPassword = e.target.value;
//     setPassword(newPassword);
//     setIsValidPassword(validatePassword(newPassword));
//     setPasswordsMatch(newPassword === confirmPassword);
//   };

//   const handleConfirmPasswordChange = (e) => {
//     const newConfirmPassword = e.target.value;
//     setConfirmPassword(newConfirmPassword);
//     setPasswordsMatch(password === newConfirmPassword);
//   };

//   const validatePassword = (password) => {
//     console.log("password validation: ", password)
//     // Password validation criteria
//     const lengthRegex = /.{8,}/;
//     const numberRegex = /\d/;
//     const uppercaseRegex = /[A-Z]/;
//     const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

//     return (
//       lengthRegex.test(password) &&
//       numberRegex.test(password) &&
//       uppercaseRegex.test(password) &&
//       specialCharRegex.test(password)
//     );
//   };

//   const registerData = {
//     "customer": {
//       "email": email,
//       "firstname": firstName,
//       "lastname": lastName,
//       "addresses": [],
//       "extension_attributes": { "is_subscribed": true },
//       "custom_attributes": [{
//         "attribute_code": "phone_number",
//         "value": phone
//       }]
//     },
//     "password": password
//   }

//   const emData = {
//     "customerEmail": email,
//     "websiteId": 1
//   }

//   const checkEmailAvailibity = () => {
//     axios.post("http://65.0.150.104/index.php/rest/V1/customers/isEmailAvailable", emData, { Authorization: `Bearer ${log}` })
//     .then((response) => {
//         console.log("Response from em avail: ", response?.data)
//         setEmailAvailable(response?.data)
//     })
//     .catch((err) => {
//       console.log("Error from em av: ", err)
//     })
//   }

//   const handleRegister = () => {
//     if (isValidPassword && passwordsMatch) {
//       axios.post("http://65.0.150.104/index.php/rest/V1/customers", registerData, { Authorization: `Bearer ${log}` })
//       .then((response) => {
//         console.log("response from register: ", response?.data)
//         if (response?.status === 200) {
//           console.log("status worked")
//           alert("Signed Up & Logged in successfully")
//           navigate("/")
//         } else if (response?.data?.id) {
//           alert("Signed Up successfully. Please login to continue shopping")
//           navigate("/login")
//         }
//       })
//       .catch((error) => {
//         if (error?.response) {
//           setShowError(true)
//           setErrorMessage(error?.response?.data?.message)
//           console.log("error from login post if: ", error?.response?.data?.message)
//         }
//       })
//     } else if (!isValidPassword) {
//       setShowError(true)
//       setErrorMessage("Password should contain 8 or more characters, 1 uppercase letter, 1 special character and 1 number")
//     } else if (!passwordsMatch) {
//       setShowError(true)
//       setErrorMessage("Password and Confirm Password does not match")
//     }

//   }

//   useEffect(() => {
//     if (executeDebouncer && email?.length > 5 ) {
//       setExecuteDebouncer(false);
//       checkEmailAvailibity();
//     }
//   }, [executeDebouncer]);

//   const fetchCountryData = () => {
//     axios.get("http://65.0.150.104/index.php/rest/all/V1/directory/countries", { Authorization: `Bearer ${log}` })
//     .then((response) => {
//       setCountryData(response?.data)
//     })
//     .catch((error) => {
//     console.log("Error from register country data: ", error)
//     })
//   }

//   // useEffect(() => {
//   //   fetchCountryData();
//   // }, [])

//   useEffect(() => {
//     if(email?.length > 7) setEmailAvailable('')
//   }, [email])

//   useEffect(() => {
//     setRegionData(countryData?.filter((cty) => cty?.full_name_english === country)?.[0]?.available_regions)
//   }, [country])

//   return (
//     <section className="bg-gray-50 dark:bg-gray-900 font-outfit py-8 min-h-screen flex justify-center items-center">
//   <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto gap-4 lg:py-0">
//       <p className="flex items-center text-2xl font-semibold text-gray-900 dark:text-white">
//           <img className="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo" />
//           Magento eCommerce
//       </p>
//         {showError && (
//           <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 relative w-full rounded-lg shadow dark:border md:mt-0 sm:max-w-md flex" role="alert">
//             <div className='gap-3 flex'>
//               <p className="block sm:inline md:text-base sm:text-sm text-xs">
//                 <span className='font-bold'>Oops!{" "}</span>
//                 <span>{errorMessage}</span></p>
//             </div>

//             <span className="flex px-1 py-3 items-center">
//               <svg onClick={()=>setShowError(false)}className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
//             </span>
//           </div>
//         )}
//       <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
//           <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
//               <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
//                   Create an account
//               </h1>
//             <form className="space-y-4 md:space-y-6" onSubmit={(e) => {
//               e.preventDefault()
//               handleRegister()
//             }}>
//                 <div className='w-full flex gap-3'>
//                 <div className='w-1/2'>
//                   <label htmlFor="fName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>

//                       <input value={firstName} autoComplete='off' onChange={(e)=>setFirstName(e?.target?.value)} type="text" name="first_name" id="fName" className="bg-gray-50 border text-xs md:text-base sm:text-sm border-gray-300 text-gray-900  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required />
//                 </div>
//                 <div className='w-1/2'>
//                       <label htmlFor="lName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
//                       <input value={lastName} autoComplete='off' onChange={(e)=>setLastName(e?.target?.value)} type="text" name="last_name" id="lName" className="bg-gray-50 border text-xs md:text-base sm:text-sm border-gray-300 text-gray-900  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Doe" required />
//                 </div>
//                 </div>
//                   <div>
//                 <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
//                   <input value={email} autoComplete='off' onChange={(e) => {
//                   setEmail(e?.target?.value)
//                   // debounceCheckEmailAvailibity();
//                 }} type="email" name="email" id="email" className="bg-gray-50 text-xs md:text-base border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="john@example.com" required />
//                 {/* {(emailAvailable === false) && <span>Email is not available. Try a different email address</span>} */}

//               </div>
//                   <div>
//                     <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone</label>
//                     <input value={phone} autoComplete='off' onChange={(e)=>setPhone(e?.target?.value)} type="tel" name="phone" id="phone" className="bg-gray-50 border text-xs md:text-base sm:text-sm border-gray-300 text-gray-900  rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="00000000000" required />
//                   </div>
//                   <div>
//                       <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
//                       <input value={password} autoComplete='off' onChange={handlePasswordChange} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border text-xs md:text-base  border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
//                   </div>
//                   <div>
//                       <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
//                       <input value={confirmPassword} autoComplete='off'  onChange={handleConfirmPasswordChange} type="confirm-password" name="confirm-password" id="confirm-password" placeholder="••••••••" className="bg-gray-50 border text-xs md:text-base  border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
//                   </div>
//                   <div className="flex items-start">
//                       <div className="flex items-center h-5">
//                         <input id="terms" aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required />
//                       </div>
//                       <div className="ml-3 text-sm">
//                         <label htmlFor="terms" className="font-light text-gray-500 dark:text-gray-300">I accept the <a className="font-medium text-blue-600 hover:underline dark:text-blue-500" href="#">Terms and Conditions</a></label>
//                       </div>
//                   </div>
//                   <button type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Create an account</button>
//                   <p className="text-sm font-light text-gray-500 dark:text-gray-400">
//                       Already have an account? <span onClick={()=>navigate("/login")} className="font-medium text-blue-600 hover:underline dark:text-blue-500 cursor-pointer">Login here</span>
//                   </p>
//               </form>
//           </div>
//       </div>
//   </div>
// </section>
//     // <div className="login-container">
//     //   <div className="image-container">
//     //     <img src="https://images.unsplash.com/photo-1482003297000-b7663a1673f1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80" alt="login-img" height="100%" width="100%" />
//     //   </div>
//     //   <form className="form" onSubmit={(e) => {
//     //           e.preventDefault()
//     //           handleRegister()
//     //   }}>
//     //     <p className='intro-text'>Register</p>

//     //     <div className="input-box">
//     //           <FaUser name="email" size={22} color="black" />
//     //           <input
//     //             type='text'
//     //             value={firstName}
//     //             placeholder='First Name'
//     //             numberOfLines={1}
//     //             autoComplete='off'
//     //             onChange={(e)=>setFirstName(e.target.value)}
//     //             className='inputField'

//     //           />
//     //     </div>
//     //     <div className="input-box">
//     //           <FaUser name="email" size={22} color="black" />
//     //           <input
//     //             type='text'
//     //             value={lastName}
//     //             placeholder='Last Name'
//     //             numberOfLines={1}
//     //             autoComplete='off'
//     //             onChange={(e)=>setLastName(e.target.value)}
//     //             className='inputField'

//     //           />
//     //     </div>
//     //     <div className="input-box">
//     //           <HiMail name="email" size={22} color="black" />
//     //           <input
//     //             type='email'
//     //             value={email}
//     //             placeholder='Email'
//     //             numberOfLines={1}
//     //             autoComplete='off'
//     //             onChange={(e) => {
//     //             setEmail(e?.target?.value)
//     //             debounceCheckEmailAvailibity();
//     //             }}
//     //             className='inputField'

//     //           />
//     //       {emailAvailable && <span>✅</span>}
//     //     </div>
//     //     <div className="input-box">
//     //           <BsTelephoneFill size={22} color="black" />
//     //           <input
//     //             type='text'
//     //             value={phone}
//     //             placeholder='Phone'
//     //             numberOfLines={1}
//     //             autoComplete='off'
//     //             onChange={(e)=>setPhone(e?.target?.value)}
//     //             className='inputField'
//     //           />
//     //     </div>
//     //      <div className="input-box">
//     //           <BsFillLockFill size={22} color="black" />
//     //           <input
//     //             type='password'
//     //             value={password}
//     //             placeholder='Password'
//     //             numberOfLines={1}
//     //             autoComplete='off'
//     //             onChange={(e)=>setPassword(e?.target?.value)}
//     //             className='inputField'
//     //           />
//     //     </div>
//     //     <input type="submit" className='login-submit-btn' value="Register" />
//     //   </form>
//     // </div>
//   )
// }

// export default Register
