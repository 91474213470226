import axios from "axios";
import AWS from "aws-sdk";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import rudderAnalytics from "./rudderstack";

export const getCart = (cartHeaders, updateCart, loader) => {
  console.log("Loader function:", loader);
  const cookie = new Cookies();
  axios
    .get(`${process.env.REACT_APP_API_URL}/rest/default/V1/carts/mine`, {
    headers: cartHeaders,
    })
    .then((response) => {
      console.log("cartData: ", response?.data?.items);
      updateCart(response?.data?.items);
      const twoHoursFromNow = new Date(Date.now() + 2 * 60 * 60 * 1000);
      const cookieOptions = {
        path: "/",
        expires: twoHoursFromNow,
        sameSite: "lax", // You can adjust this based on your needs
        // secure: true,    // Set to 'true' if using HTTPS
      };
      const count = response?.data?.items_qty;
      console.log("cookie options: ", cookieOptions);
      console.log("items_in_cart: ", response?.data?.items?.length);

      cookie.set("items_in_cart", count, cookieOptions);
      if (loader) loader(false);

      // localStorage?.setItem("items_in_cart", response?.data?.items?.length);
    })
    .catch((error) => {
      console.log("Error while fetching active cart", error);
      if (loader) loader(false);
    });
};

export const fetchSecret = (secretValue) => {
  const secretName = "Magento_Admin_Oauth";
  const awsConfig = {
    region: "ap-south-1", // Replace with your AWS region (e.g., 'us-east-1')
    accessKeyId: "AKIAU7OHNFO5PTHB47OD", // Replace with your access key ID
    secretAccessKey: "VTkSVgUDHYi2/vvZKPnB/DS0Bl0m2QrTb+0Vu7", // Replace with your secret access key
    // region: "ap-south-1", // Replace with your AWS region (e.g., 'us-east-1')
    // accessKeyId: "AKIAU7OHNFO5ETHB47OD", // Replace with your access key ID
    // secretAccessKey: "VTkSVgUDHYi2/vvZKPnB/DS0Bl0m2QrTb+0Vu7Tj", // Replace with your secret access key
  };
  AWS.config.update(awsConfig);
  const secretsManager = new AWS.SecretsManager();
  secretsManager.getSecretValue({ SecretId: secretName }, (err, data) => {
    console.log("Service is running");
    if (err) {
      console.error("Error retrieving the secret:", err);
    } else {
      const secret = JSON.parse(data?.SecretString);
      console.log("secret key: ", secret);
      secretValue(secret);
    }
  });
};

export const generateRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  localStorage.setItem("compare_token", result)
  return result;
}

export const setCookie = (name, value, expiryHours = 24) => {
  const date = new Date();
  date.setTime(date.getTime() +  9000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/;SameSite=Strict`;

  // Set up expiration listener
  setupExpirationListener(name, date);
};

// expiryHours * 60 * 60 *

export const getCookie = (name) => {
  const cookies = document.cookie.split(";");
  const cookie = cookies.find((c) => c.trim().startsWith(`${name}=`));
  return cookie ? cookie.split("=")[1] : null;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
};

export const setupExpirationListener = (cookieName, expiryDate) => {
  const timeUntilExpiry = expiryDate.getTime() - Date.now();

  if (timeUntilExpiry > 0) {
    setTimeout(() => {
      // Check if cookie still exists before triggering cleanup
      if (!getCookie(cookieName)) {
        handleTokenExpiration();
      }
    }, timeUntilExpiry);
  }
};

export const customRenderers = {
  ul: ({ children }) => (
    <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>{children}</ul>
  ),
  img: ({ src, alt }) => (
    <img
      src={src}
      alt={alt}
      className="object-contain max-w-[600px] max-h-[360px] mx-auto"
    />
  ),
};



export const handleTokenExpiration = () => {
  // Clear relevant localStorage items
  const keysToRemove = ["user_preferences", "cart_items", "recent_views"];
  keysToRemove.forEach((key) => localStorage.removeItem(key));

  // Show notification to user
  showExpirationNotification();

  // Optional: Redirect to login page
  window.location.href = "/login";
};

export const showExpirationNotification = () => {
  // If you're using a notification library like react-toastify
  if ("Notification" in window && Notification.permission === "granted") {
    new Notification("Session Expired", {
      body: "Your session has expired. Please login again.",
      icon: "/path-to-your-icon.png",
    });
  } else {
    alert("Your session has expired. Please login again.");
  }
};


export const getCompareItems = () =>
  JSON.parse(localStorage.getItem("compareItems")) || [];
export const setCompareItems = (items) =>
  localStorage.setItem("compareItems", JSON.stringify(items));

export const fetchProducts = (updateProducts) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/rest/V1/products?searchCriteria[pageSize]=10`
    )
    .then((res) => {
      updateProducts(res?.data?.items);
      if (!localStorage?.getItem("products")) {
        localStorage?.setItem("products", JSON.stringify(res?.data?.items));
      }
    })
    .catch((err) => {
      console.log("Error while fetching products: ", err);
    });
};

export const convertToUrlFormat = (str) => {
  return str
    .trim()
    .toLowerCase()
    .replace(/[\s:]+/g, '-')
    .replace(/[^a-zA-Z0-9-]/g, '')
    .toLowerCase()
};

export const createUserCart = (headers) => {
    console.log("cart creation started");
    axios
      .post(`${process.env.REACT_APP_API_URL}/rest/V1/carts/mine`, null, {
        headers: headers,
      })
      .then((response) => {
        console.log("Response from creating an active cart: ", response?.data);
        window.localStorage.setItem("cart_id", response?.data);
      })
      .catch((error) => {
        console.log(
          "Error while fetching active cart: ",
          error?.response?.data?.message
        );
      });
  };


export const usePageTracking = () => {
  useEffect(() => {
    const entryTime = Date.now();
    const sessionId =
      sessionStorage.getItem("sessionId") ||
      Math.random().toString(36).substring(2);
    sessionStorage.setItem("sessionId", sessionId);

    const pagesViewed = JSON.parse(sessionStorage.getItem("pagesViewed")) || [];
    pagesViewed.push(window.location.pathname);
    sessionStorage.setItem("pagesViewed", JSON.stringify(pagesViewed));

    rudderAnalytics.page();
    rudderAnalytics.track("Page Viewed", { path: window.location.pathname });

    const handleBeforeUnload = () => {
      const exitTime = Date.now();
      const timeSpent = (exitTime - entryTime) / 1000;

      const totalTimeSpent =
        parseFloat(sessionStorage.getItem("totalTimeSpent")) || 0;
      sessionStorage.setItem("totalTimeSpent", totalTimeSpent + timeSpent);

      if (timeSpent < 30) {
        rudderAnalytics.track("Bounce", {
          path: window.location.pathname,
          timeSpent,
        });
      } else {
        rudderAnalytics.track("Page Exited", {
          path: window.location.pathname,
          timeSpent,
        });
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [window.location.pathname]);

  // useEffect(() => {
  //   const handlePageHide = () => {
  //     const totalTimeSpent =
  //       parseFloat(sessionStorage.getItem("totalTimeSpent")) || 0;
  //     const pagesViewed =
  //       JSON.parse(sessionStorage.getItem("pagesViewed")) || [];

  //     rudderAnalytics.track("Session End", {
  //       totalTimeSpent,
  //       pagesViewed,
  //     });

  //     sessionStorage.removeItem("totalTimeSpent");
  //     sessionStorage.removeItem("pagesViewed");
  //     sessionStorage.removeItem("sessionId");
  //   };

  //   window.addEventListener("pagehide", handlePageHide);

  //   return () => {
  //     window.removeEventListener("pagehide", handlePageHide);
  //   };
  // }, []);
};





// import axios from 'axios'
// import AWS from "aws-sdk";
// import Cookies from 'universal-cookie'

// export const getCart = (cartHeaders, updateCart) => {
//   const cookie = new Cookies();
//   axios.get(`${process.env.REACT_APP_API_URL}/rest/default/V1/carts/mine`, {headers: cartHeaders})
//       .then((response) => {
//         console.log("cartData: ", response?.data?.items);
//         updateCart(response?.data?.items);
//         const oneWeekFromNow = new Date();
//         oneWeekFromNow.setTime(oneWeekFromNow.getTime() + 604800 * 1000);
//         const cookieOptions = {
//           path: "/",
//           expires: oneWeekFromNow,
//           sameSite: "lax", // You can adjust this based on your needs
//           // secure: true,    // Set to 'true' if using HTTPS
//         };
//         const count = response?.data?.items?.length;
//         console.log("cookie options: ", cookieOptions)
//         console.log("items_in_cart: ", response?.data?.items?.length);
//         cookie.set("items_in_cart", count, cookieOptions);
//         // localStorage?.setItem("items_in_cart", response?.data?.items?.length);
//     })
//     .catch((error) => {
//       console.log("Error while fetching active cart", error);
//     });
// };

// export const fetchSecret = (secretValue) => {
//   const secretName = "Magento_Admin_Oauth";
//   const awsConfig = {
//     region: "ap-south-1", // Replace with your AWS region (e.g., 'us-east-1')
//     accessKeyId: "AKIAU7OHNFO5PTHB47OD", // Replace with your access key ID
//     secretAccessKey: "VTkSVgUDHYi2/vvZKPnB/DS0Bl0m2QrTb+0Vu7", // Replace with your secret access key
//     // region: "ap-south-1", // Replace with your AWS region (e.g., 'us-east-1')
//     // accessKeyId: "AKIAU7OHNFO5ETHB47OD", // Replace with your access key ID
//     // secretAccessKey: "VTkSVgUDHYi2/vvZKPnB/DS0Bl0m2QrTb+0Vu7Tj", // Replace with your secret access key
//   };
//   AWS.config.update(awsConfig);
//   const secretsManager = new AWS.SecretsManager();
//   secretsManager.getSecretValue({ SecretId: secretName }, (err, data) => {
//     console.log("Service is running");
//     if (err) {
//       console.error("Error retrieving the secret:", err);
//     } else {
//       const secret = JSON.parse(data?.SecretString);
//       console.log("secret key: ", secret);
//       secretValue(secret)
//     }
//   });
// };

// export const fetchProducts = (updateProducts) => {
//   axios.get(`${process.env.REACT_APP_API_URL}/rest/V1/products?searchCriteria[pageSize]=10`)
//   .then((res) => {
//     updateProducts(res?.data?.items);
//     if (!localStorage?.getItem("products")) {
//       localStorage?.setItem("products", JSON.stringify(res?.data?.items));
//     }
//   })
//   .catch((err) => {
//     console.log("Error while fetching products: ", err);
//   });
// };

//old
// import axios from 'axios'
// import AWS from "aws-sdk";
// import Cookies from 'universal-cookie'

// export const getCart = (cartHeaders, updateCart) => {
//   const cookie = new Cookies();
//   axios.get(`${process.env.REACT_APP_API_URL}/rest/default/V1/carts/mine`, {headers: cartHeaders})
//       .then((response) => {
//         console.log("cartData: ", response?.data?.items);
//         updateCart(response?.data?.items);
//         const oneWeekFromNow = new Date();
//         oneWeekFromNow.setTime(oneWeekFromNow.getTime() + 604800 * 1000);
//         const cookieOptions = {
//           path: "/",
//           expires: oneWeekFromNow,
//           sameSite: "lax", // You can adjust this based on your needs
//           // secure: true,    // Set to 'true' if using HTTPS
//         };
//         const count = response?.data?.items?.length;
//         console.log("cookie options: ", cookieOptions)
//         console.log("items_in_cart: ", response?.data?.items?.length);
//         cookie.set("items_in_cart", count, cookieOptions);
//         // localStorage?.setItem("items_in_cart", response?.data?.items?.length);
//     })
//     .catch((error) => {
//       console.log("Error while fetching active cart", error);
//     });
// };

// export const fetchSecret = (secretValue) => {
//   const secretName = "Magento_Admin_Oauth";
//   const awsConfig = {
//     region: "ap-south-1", // Replace with your AWS region (e.g., 'us-east-1')
//     accessKeyId: "AKIAU7OHNFO5ETHB47OD", // Replace with your access key ID
//     secretAccessKey: "VTkSVgUDHYi2/vvZKPnB/DS0Bl0m2QrTb+0Vu7Tj", // Replace with your secret access key
//   };
//   AWS.config.update(awsConfig);
//   const secretsManager = new AWS.SecretsManager();
//   secretsManager.getSecretValue({ SecretId: secretName }, (err, data) => {
//     console.log("Service is running");
//     if (err) {
//       console.error("Error retrieving the secret:", err);
//     } else {
//       const secret = JSON.parse(data?.SecretString);
//       console.log("secret key: ", secret);
//       secretValue(secret)
//     }
//   });
// };

// export const fetchProducts = (updateProducts) => {
//   axios.get("http://65.0.150.104/rest/V1/products?searchCriteria[pageSize]=10")
//   .then((res) => {
//     updateProducts(res?.data?.items);
//     if (!localStorage?.getItem("products")) {
//       localStorage?.setItem("products", JSON.stringify(res?.data?.items));
//     }
//   })
//   .catch((err) => {
//     console.log("Error while fetching products: ", err);
//   });
// };
