import React, { useState, useRef, useEffect } from "react";
import { getCookie } from "../../ReactFunctions";

const HeaderIcons = ({
  items_count,
  navigate,
  userData,
  auth_token,
  handleSignOut,
}) => {
  const [activeTooltip, setActiveTooltip] = useState(null);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [cartItemsCount, setCartItemsCount] = useState(getCookie("items_in_cart") ?? 0)
  const tooltipTimer = useRef(null);
  const menuRef = useRef(null);

  // Handle clicks outside of user menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      if (tooltipTimer.current) {
        clearTimeout(tooltipTimer.current);
      }
    };
  }, []);

  const handleMouseEnter = (iconName) => {
    tooltipTimer.current = setTimeout(() => {
      setActiveTooltip(iconName);
    }, 2500); // 2.5 seconds delay
  };

  const handleMouseLeave = () => {
    if (tooltipTimer.current) {
      clearTimeout(tooltipTimer.current);
    }
    setActiveTooltip(null);
    };
    

  const IconWrapper = ({ children, tooltip }) => (
    <div
      className="relative group"
      onMouseEnter={() => handleMouseEnter(tooltip)}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {activeTooltip === tooltip && (
        <div className="absolute -bottom-12 left-1/2 transform -translate-x-1/2 px-3 py-2 bg-gray-800 text-white text-sm rounded-lg whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out z-50">
          {tooltip}
          <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 w-2 h-2 bg-gray-800 rotate-45" />
        </div>
      )}
    </div>
  );

    useEffect(() => {
      const checkCookie = () => {
          const newCount = getCookie("items_in_cart");
          console.log("checking new cookie and the value: ", newCount)
        setCartItemsCount(newCount);
      };

      // Check for cookie changes every second
      const intervalId = setInterval(checkCookie, 500);

      // Clean up interval on component unmount
      return () => clearInterval(intervalId);
    }, []);
    
  return (
    <div className="flex gap-4 items-center min-w-fit justify-center font-man">
      <IconWrapper tooltip="Comparison">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6 text-gray-700 cursor-pointer hover:text-gray-900 transition-colors duration-200"
          onClick={() => navigate("/compare-products")}
        >
          <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75ZM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 0 1-1.875-1.875V8.625ZM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 0 1 3 19.875v-6.75Z" />
        </svg>
      </IconWrapper>

      <IconWrapper tooltip="Wishlist">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6 text-gray-700 cursor-pointer hover:text-gray-900 transition-colors duration-200"
          onClick={() => navigate("/wishlist")}
        >
          <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
        </svg>
      </IconWrapper>

      <IconWrapper tooltip="Cart">
        <div className="relative group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6 text-gray-700 cursor-pointer hover:text-gray-900 transition-colors duration-200"
            onClick={() => navigate("/cart")}
          >
            <path
              fillRule="evenodd"
              d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 0 0 4.25 22.5h15.5a1.875 1.875 0 0 0 1.865-2.071l-1.263-12a1.875 1.875 0 0 0-1.865-1.679H16.5V6a4.5 4.5 0 1 0-9 0ZM12 3a3 3 0 0 0-3 3v.75h6V6a3 3 0 0 0-3-3Zm-3 8.25a3 3 0 1 0 6 0v-.75a.75.75 0 0 1 1.5 0v.75a4.5 4.5 0 1 1-9 0v-.75a.75.75 0 0 1 1.5 0v.75Z"
              clipRule="evenodd"
            />
          </svg>
          {cartItemsCount > 0 && (
            <span className="absolute -top-1 left-3 rounded-full w-5 h-5 flex items-center justify-center bg-red-500 p-2 text-[0.6rem] leading-none text-white group-hover:animate-pulse">
              {cartItemsCount}
            </span>
          )}
        </div>
      </IconWrapper>

      <div className="relative" ref={menuRef}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className={`w-6 h-6 cursor-pointer transition-colors duration-200 ${
            showUserMenu ? "text-gray-900" : "text-gray-700 hover:text-gray-900"
          }`}
          onClick={() => setShowUserMenu(!showUserMenu)}
        >
          <path
            fillRule="evenodd"
            d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
            clipRule="evenodd"
          />
        </svg>

        {/* User Menu Dropdown */}
        {showUserMenu && (
          <div className="absolute right-0 mt-2 w-[180px] origin-top-right transition-all duration-200 ease-out transform z-50 scale-100 opacity-100">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-gray-700">
              {auth_token ? (
                <>
                  <div className="px-4 py-3 border-b border-gray-200 dark:border-gray-600">
                    <span className="block text-sm text-gray-900 dark:text-white font-semibold">
                      {userData?.firstname + " " + userData?.lastname}
                    </span>
                    <span className="block text-xs font-medium text-gray-400 mt-2 truncate">
                      {userData?.email}
                    </span>
                  </div>
                  <div className="py-1">
                    <button
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600"
                      onClick={() => {
                        setShowUserMenu(false);
                        navigate("/account");
                      }}
                    >
                      My Account
                    </button>
                    <button
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600"
                      onClick={() => {
                        setShowUserMenu(false);
                        navigate("/orders");
                      }}
                    >
                      Orders
                    </button>
                    <button
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600"
                      onClick={() => {
                        setShowUserMenu(false);
                        handleSignOut();
                      }}
                    >
                      Sign out
                    </button>
                  </div>
                </>
              ) : (
                <div className="py-1">
                  <button
                    className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600"
                    onClick={() => {
                      setShowUserMenu(false);
                      navigate("/login");
                    }}
                  >
                    Login
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderIcons;
