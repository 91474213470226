import React from 'react'
import Hero from './../Navbar/frame1.png'
import { useNavigate } from 'react-router-dom'

const HeroSection = () => {
  const navigate = useNavigate()
  return (
    <div
      className="w-full flex items-center justify-center p-4 bg-cover bg-center filter grayscale font-man md:h-[650px] h-[300px]"
      style={{ backgroundImage: `url(${Hero})` }}
    >
      <div className="flex flex-col md:w-1/2 w-[95%] text-center gap-4 py-9 bg-[#00000080] justify-center items-center">
        <p className="md:text-5xl text-white">
          Handcrafted Designs
          <br />
          meet Fashion
        </p>
        <p className="md:text-base text-white font-medium">
          Explore a wide range of evolving fashion
        </p>
        <button
          className="text-white border w-max p-3 text-sm cursor-pointer hover:bg-white hover:text-black font-semibold"
          onClick={() => navigate("/categories/38/whats-new")}
        >
          View More
        </button>
      </div>
    </div>
  );
}

export default HeroSection