import React, { useEffect, useState } from "react";

// const Pagination = ({ totalPages, currentPage, onPageChange }) => {
//   // const [inputPage, setInputPage] = useState(currentPage);

//     const handlePageChange = (page) => {
//       window.scroll(0,0)
//     if (page >= 1 && page <= totalPages) {
//       onPageChange(page);
//     }
//   };

//     const renderPageNumbers = () => {
//       let pages = [];

//       for (let i = 1; i <= totalPages; i++) {
//         pages.push(
//           <button
//             key={i}
//             onClick={() => handlePageChange(i)}
//             className={`px-3 py-2 rounded ${
//               i === currentPage
//                 ? "bg-blue-500 text-white"
//                 : "bg-gray-200 text-gray-700"
//             }`}
//           >
//             {i}
//           </button>
//         );
//       }

//       return pages;
//     };

//   return (
//     <div className="flex items-center justify-center mt-4 gap-4 w-full max-w-full">
//       <button
//         onClick={() => handlePageChange(currentPage - 1)}
//         disabled={currentPage === 1}
//         className="px-4 py-2 mx-2 bg-gray-200 text-gray-700 rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
//       >
//         Previous
//       </button>

//       <div className="min-w-fit hidden md:flex flex-wrap gap-2 max-w-[60%]">
//         {renderPageNumbers()}
//       </div>

//       <button
//         onClick={() => handlePageChange(currentPage + 1)}
//         disabled={currentPage === totalPages}
//         className="px-4 py-2 mx-2 bg-gray-200 text-gray-700 rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
//       >
//         Next
//       </button>
//     </div>
//   );
// };

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const handlePageChange = (page) => {
    if (page !== currentPage && page >= 1 && page <= totalPages) {
      window.scroll(0, 0);
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    let pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-2 rounded ${
            i === currentPage
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          {i}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className="flex items-center justify-center mt-4 gap-4 w-full max-w-full">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-4 py-2 mx-2 bg-gray-200 text-gray-700 rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
      >
        Previous
      </button>

      <div className="min-w-fit hidden md:flex flex-wrap gap-2 max-w-[60%]">
        {renderPageNumbers()}
      </div>

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-4 py-2 mx-2 bg-gray-200 text-gray-700 rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;



  // const handleInputChange = (e) => {
  //   setInputPage(e.target.value);
  // };

  // const handleInputSubmit = (e) => {
  //   e.preventDefault();
  //   const page = parseInt(inputPage, 10);
  //   if (!isNaN(page)) {
  //     handlePageChange(page);
  //   }
  // };