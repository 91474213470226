import React, { useEffect, useRef, useState } from 'react'
import ProductCardSkeleton from '../ProductCardSkeleton/ProductCardSkeleton';
import axios from 'axios';
import ProductCard from '../ProductCard/ProductCard';

const RelatedProducts = ({ sku }) => {
    const [loading, setLoading] = useState(true)
    const [productData, setProductData] = useState([])
    const scrollContainerRef = useRef(null);
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);


    const fetchRelatedProducts = () => {
        setLoading(true)
        axios.get(`https://magento.thesketch.net/rest/V1/relatedProducts/${sku}`)
        .then((response) => {
            console.log("response from related products: ", response?.data)    
            setProductData(response?.data?.items)
            setLoading(false);
        })
        .catch((error) => {
            console.log("error while fetching products: ", error)   
            setLoading(false);
        })
    }
 const checkScrollPosition = () => {
   const container = scrollContainerRef.current;
   if (container) {
     // Check if at start
     setIsAtStart(container.scrollLeft === 0);

     // Check if at end (accounting for rounding errors)
     const isEnd =
       Math.abs(
         container.scrollLeft + container.clientWidth - container.scrollWidth
       ) < 1;
     setIsAtEnd(isEnd);
   }
 };

//  useEffect(() => {
//    const container = scrollContainerRef.current;
//    if (container) {
//      container.addEventListener("scroll", checkScrollPosition);
//      window.addEventListener("resize", checkScrollPosition);
//      // Initial check
//      checkScrollPosition();

//      return () => {
//        container.removeEventListener("scroll", checkScrollPosition);
//        window.removeEventListener("resize", checkScrollPosition);
//      };
//    }
    //  }, []);
    
    useEffect(() => {
      const container = scrollContainerRef.current;
      if (container) {
        // Initial check
        checkScrollPosition();

        // Add event listeners for scroll and resize
        container.addEventListener("scroll", checkScrollPosition);
        window.addEventListener("resize", checkScrollPosition);

        // Clean up
        return () => {
          container.removeEventListener("scroll", checkScrollPosition);
          window.removeEventListener("resize", checkScrollPosition);
        };
      }
    }, [productData]);

   const scroll = (direction) => {
     const container = scrollContainerRef.current;
     if (container) {
       const cardWidth = 280; // Width of each card
       const gap = 16; // Gap between cards
       const scrollAmount = cardWidth + gap;

       // Get the current scroll position
       const currentScroll = container.scrollLeft;

       // Calculate the next scroll position
       const targetScroll =
         direction === "left"
           ? currentScroll - scrollAmount
           : currentScroll + scrollAmount;

       // Scroll to the nearest card
       container.scrollTo({
         left: targetScroll,
         behavior: "smooth",
       });
     }
   };


    useEffect(() => {
        fetchRelatedProducts()
    }, [])
    
  return (
    <div className="w-full flex flex-col mt-6 relative">
      <div>
        <p className="font-semibold md:text-2xl text-xl">RELATED PRODUCTS</p>
      </div>
      <div>
        {loading ? (
          <div className="flex w-full overflow-x-scroll justify-start items-center gap-6 py-8 scrollbar-hide no-scrollbar">
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
          </div>
        ) : (
          <div className="w-full flex relative px-4">
            <div className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none">
              {!isAtStart && (
                <button
                  onClick={() => scroll("left")}
                  className="pointer-events-auto absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 z-10 bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-all duration-300"
                  aria-label="Scroll left"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}

              {!isAtEnd && (
                <button
                  onClick={() => scroll("right")}
                  className="pointer-events-auto absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 z-10 bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-all duration-300"
                  aria-label="Scroll right"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
            </div>

            <div
              ref={scrollContainerRef}
              className="flex overflow-x-auto scrollbar-hide snap-x snap-mandatory gap-6 mt-6"
              style={{
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                WebkitOverflowScrolling: "touch", // For smoother scrolling on iOS
              }}
            >
              {productData?.map((product) => {
                return <ProductCard key={product?.sku} data={product} />;
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RelatedProducts

//   <div className="flex w-full overflow-x-scroll justify-start items-center gap-6 py-8 no-scrollbar">
        //     {productData?.map((product) => {
        //       return <ProductCard key={product?.sku} data={product} />;
        //     })}
        //   </div>