const ProductCardSkeleton = () => {
  return (
    <div className="bg-white rounded-lg shadow-md w-64 min-w-[256px] animate-pulse">
      <div className="h-40 bg-gray-200 rounded-t-lg"></div>
      <div className="p-4">
        <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
        <div className="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
        <div className="h-8 bg-gray-200 rounded w-full mb-4"></div>
        <div className="flex justify-between items-center">
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded">
            <div className="h-4 bg-white rounded w-20"></div>
          </button>
          <div className="h-6 bg-gray-200 rounded w-6"></div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardSkeleton;
