// sessionManager.js
import { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Constants
const JWT_COOKIE_NAME = "auth_token";
const FLOW_STATE_KEY = "navigation_flow";
const SESSION_DURATION = 24 * 60 * 60 * 1000;
const CHECK_INTERVAL = 60 * 1000;

// Route configurations
const PROTECTED_ROUTES = [
  "/account",
  "/orders",
  "/cart",
  "/wishlist",
  "/profile",
];

const FLOW_ENTRY_POINTS = ["/cart", "/checkout"]; // Add any other routes that can start the flow

// const FLOW_PROTECTED_ROUTES = {
//   "/cart": {
//     requiredPrevious: [],
//     allowedNext: ["/checkout"],
//   },
//   "/checkout": {
//     requiredPrevious: ["/cart"],
//     allowedNext: ["/success", "/failure"],
//   },
//   "/success": {
//     requiredPrevious: ["/checkout", ""],
//     allowedNext: ["/orders", "/"],
//     validSessionFlag: "payuReturnAccess",
//   },
//   "/failure": {
//     requiredPrevious: ["/checkout"],
//     allowedNext: ["/checkout", "/cart"],
//     validSessionFlag: "payuReturnAccess",
//   },
// };

const FLOW_PROTECTED_ROUTES = {
  "/cart": {
    requiredPrevious: [],
    allowedNext: ["/checkout"],
  },
  "/checkout": {
    requiredPrevious: ["/cart"],
    allowedNext: ["/success", "/failure"],
  },
  "/success": {
    requiredPrevious: ["/checkout"],
    allowedNext: ["/orders", "/"],
    validSessionFlag: "payuReturnAccess",
  },
  "/failure": {
    requiredPrevious: ["/checkout"],
    allowedNext: ["/checkout", "/cart"],
    validSessionFlag: "payuReturnAccess",
  },
};

const PUBLIC_ROUTES = [
  "/",
  "/products",
  "/product",
  "/category",
  "/search",
  "/contact",
];

// Utility functions
export const setCookie = (name, value, expiryHours = 4) => {
  const date = new Date();
  date.setTime(date.getTime() + expiryHours * 60 * 60 * 1000);
  const expires = date.toUTCString();
  document.cookie = `${name}=${value};expires=${expires};path=/`;
  localStorage.setItem(`${name}_expiry`, date.getTime().toString());
};

export const getCookie = (name) => {
  const cookies = document.cookie.split(";");
  const cookie = cookies.find((c) => c.trim().startsWith(`${name}=`));
  return cookie ? cookie.split("=")[1] : null;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  localStorage.removeItem(`${name}_expiry`);
};

const isCookieExpired = (name) => {
  const expiryTime = localStorage.getItem(`${name}_expiry`);
  if (!expiryTime) return true;
  return Date.now() >= parseInt(expiryTime);
};

export const cleanupLocalStorage = () => {
  deleteCookie("items_in_cart");
  const keysToClean = [
    "userData",
    "products",
    "cart_id",
    "items_in_cart",
    "auth_token",
    FLOW_STATE_KEY
  ];
  keysToClean.forEach((key) => localStorage.removeItem(key));
  localStorage.removeItem(`${JWT_COOKIE_NAME}_expiry`);
};

// Navigation flow management
// const updateNavigationFlow = (path) => {
//   try {
//     const flowHistory = JSON.parse(
//       sessionStorage.getItem(FLOW_STATE_KEY) || "[]"
//     );

//     // If it's an entry point, clear the history and start fresh
//     if (FLOW_ENTRY_POINTS.includes(path)) {
//       const newHistory = [{ path, timestamp: Date.now() }];
//       sessionStorage.setItem(FLOW_STATE_KEY, JSON.stringify(newHistory));
//       return;
//     }

//     // Add the new path to history
//     flowHistory.push({ path, timestamp: Date.now() });

//     // Keep only the last 5 entries to prevent history from growing too large
//     const trimmedHistory = flowHistory.slice(-5);

//     sessionStorage.setItem(FLOW_STATE_KEY, JSON.stringify(trimmedHistory));
//   } catch (error) {
//     console.error("Error updating navigation flow:", error);
//     // Initialize new history if there's an error
//     sessionStorage.setItem(
//       FLOW_STATE_KEY,
//       JSON.stringify([{ path, timestamp: Date.now() }])
//     );
//   }
// };



export const updateNavigationFlow = (path) => {
  try {
    const flowHistory = JSON.parse(
      sessionStorage.getItem(FLOW_STATE_KEY) || "[]"
    );

    // If it's an entry point, clear the history and start fresh
    if (FLOW_ENTRY_POINTS.includes(path)) {
      const newHistory = [{ path, timestamp: Date.now() }];
      sessionStorage.setItem(FLOW_STATE_KEY, JSON.stringify(newHistory));
      return;
    }

    // Add the new path to history
    flowHistory.push({ path, timestamp: Date.now() });

    // Keep only the last 5 entries to prevent history from growing too large
    const trimmedHistory = flowHistory.slice(-5);

    sessionStorage.setItem(FLOW_STATE_KEY, JSON.stringify(trimmedHistory));
  } catch (error) {
    console.error("Error updating navigation flow:", error);
    // Initialize new history if there's an error
    sessionStorage.setItem(
      FLOW_STATE_KEY,
      JSON.stringify([{ path, timestamp: Date.now() }])
    );
  }
};

// const isValidNavigationFlow = (currentPath) => {
//   try {
//     // If it's not a flow-protected route, allow access
//     if (!FLOW_PROTECTED_ROUTES[currentPath]) return true;

//     // If it's an entry point, allow access
//     if (FLOW_ENTRY_POINTS.includes(currentPath)) return true;

//     const flowHistory = JSON.parse(
//       sessionStorage.getItem(FLOW_STATE_KEY) || "[]"
//     );
//     const flowConfig = FLOW_PROTECTED_ROUTES[currentPath];

//     // If no history and not an entry point, deny access
//     if (flowHistory.length === 0) return false;

//     const lastPath = flowHistory[flowHistory.length - 1]?.path;

//     // Debug logging
//     console.log("Current Path:", currentPath);
//     console.log("Last Path:", lastPath);
//     console.log("Flow History:", flowHistory);
//     console.log("Required Previous:", flowConfig.requiredPrevious);

//     return flowConfig.requiredPrevious.includes(lastPath);
//   } catch (error) {
//     console.error("Error checking navigation flow:", error);
//     return false;
//   }
// };

const isValidNavigationFlow = (currentPath) => {
  try {
    // If it's not a flow-protected route, allow access
    if (!FLOW_PROTECTED_ROUTES[currentPath]) return true;

    // If it's an entry point, allow access
    if (FLOW_ENTRY_POINTS.includes(currentPath)) return true;

    const flowHistory = JSON.parse(
      sessionStorage.getItem(FLOW_STATE_KEY) || "[]"
    );
    const flowConfig = FLOW_PROTECTED_ROUTES[currentPath];

    // If no history and not an entry point, deny access
    if (flowHistory.length === 0) return false;

    const lastPath = flowHistory[flowHistory.length - 1]?.path;

    // Special case for payment redirection paths
    if (
      (currentPath === "/success" || currentPath === "/failure") &&
      lastPath === "/checkout"
    ) {
      return true;
    }

    // Allow access to /success if validSessionFlag is set in sessionStorage
    if (currentPath === "/success") {
      const validSessionFlag = flowConfig.validSessionFlag;
      const sessionValue = sessionStorage.getItem(validSessionFlag);
      if (sessionValue) {
        return true; // Allow access if the session flag is set
      }
    }

    // Debug logging
    console.log("Current Path:", currentPath);
    console.log("Last Path:", lastPath);
    console.log("Flow History:", flowHistory);
    console.log("Required Previous:", flowConfig.requiredPrevious);

    return flowConfig.requiredPrevious.includes(lastPath);
  } catch (error) {
    console.error("Error checking navigation flow:", error);
    return false;
  }
};



// Helper functions
const isProtectedRoute = (pathname) => {
  return PROTECTED_ROUTES.some((route) => pathname.startsWith(route));
};

const isFlowProtectedRoute = (pathname) => {
  return Object.keys(FLOW_PROTECTED_ROUTES).some((route) =>
    pathname.startsWith(route)
  );
};

// Session Expired Alert Component
export const SessionExpiredAlert = () => {
  return (
    <div className="w-96 p-4 bg-red-100 border border-red-300 rounded-md shadow-lg animate-slide-in">
      <div className="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-5 w-5 text-red-600"
        >
          <path
            fillRule="evenodd"
            d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
            clipRule="evenodd"
          />
        </svg>
        <div className="ml-3">
          <h3 className="text-lg font-semibold text-red-700">
            Session Expired
          </h3>
          <p className="mt-1 text-sm text-red-600">
            Your session has expired. Please login to continue.
          </p>
        </div>
      </div>
    </div>
  );
};

// Invalid Navigation Alert Component
const InvalidNavigationAlert = () => (
  <div className="w-96 p-4 bg-red-100 border border-red-300 rounded-md shadow-lg animate-slide-in">
    <div className="flex items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="h-5 w-5 text-red-600"
      >
        <path
          fillRule="evenodd"
          d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
          clipRule="evenodd"
        />
      </svg>
      <div className="ml-3">
        <h3 className="text-lg font-semibold text-red-700">
          Invalid Navigation
        </h3>
        <p className="mt-1 text-sm text-red-600">
          This page cannot be accessed directly.
        </p>
      </div>
    </div>
  </div>
);

const debugFlowState = () => {
  const flowHistory = JSON.parse(
    sessionStorage.getItem(FLOW_STATE_KEY) || "[]"
  );
  console.log("Current Flow History:", flowHistory);
  return flowHistory;
};



// Custom Hook for Session Management
export const useSessionManager = (initialToken) => {
  const [token, setToken] = useState(initialToken);
  const [showExpiredAlert, setShowExpiredAlert] = useState(false);
  const [showInvalidNavigationAlert, setShowInvalidNavigationAlert] =
    useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Updated token setter that manages cookies
  const updateToken = useCallback((newToken) => {
    if (newToken) {
      // Set the token in cookie with 24 hours expiry
      setCookie(JWT_COOKIE_NAME, newToken, 24);
      setToken(newToken);
    } else {
      // Clear the token and remove cookie
      deleteCookie(JWT_COOKIE_NAME);
      setToken(null);
    }
  }, []);

  const handleSessionExpiry = useCallback(() => {
    const currentPath = location.pathname;

    if (isProtectedRoute(currentPath)) {
      setShowExpiredAlert(true);
      setTimeout(() => {
        setShowExpiredAlert(false);
        sessionStorage.setItem("redirectAfterLogin", currentPath);
        navigate("/login");
      }, 3000);
    }

    cleanupLocalStorage();
    updateToken(null); // Use updateToken instead of direct cookie deletion
  }, [navigate, location, updateToken]);

    const handleInvalidNavigation = useCallback(() => {
      console.log("Invalid Navigation Detected");
      console.log("Current Flow State:", debugFlowState());

      setShowInvalidNavigationAlert(true);
      setTimeout(() => {
        setShowInvalidNavigationAlert(false);
        // Instead of always navigating to home, navigate back if possible
        const flowHistory = debugFlowState();
        if (flowHistory.length > 0) {
          const previousPath = flowHistory[flowHistory.length - 1].path;
          navigate(previousPath);
        } else {
          navigate("/");
        }
      }, 3000);
    }, [navigate]);

  // const navigateTo = useCallback(
  //   (path, state = null) => {
  //     const currentPath = location.pathname;

  //     // Always update navigation flow before checking validity
  //     updateNavigationFlow(currentPath);

  //     const flowConfig = FLOW_PROTECTED_ROUTES[path];
  //     const currentFlowConfig = FLOW_PROTECTED_ROUTES[currentPath];

  //     // If it's an entry point, allow navigation
  //     if (FLOW_ENTRY_POINTS.includes(path)) {
  //       navigate(path, state ? { state } : undefined);
  //       return;
  //     }

  //     // Check if current path has allowed next routes
  //     if (currentFlowConfig && !currentFlowConfig.allowedNext.includes(path)) {
  //       handleInvalidNavigation();
  //       return;
  //     }

  //     // Navigate to the new path
  //     navigate(path, state ? { state } : undefined);
  //   },
  //   [location, navigate, handleInvalidNavigation]
  // );

  const navigateTo = useCallback(
    (path, state = null) => {
      const currentPath = location.pathname;
      const flowConfig = FLOW_PROTECTED_ROUTES[path];
      const currentFlowConfig = FLOW_PROTECTED_ROUTES[currentPath];

      // Check if current path has allowed next routes
      if (currentFlowConfig && !currentFlowConfig.allowedNext.includes(path)) {
        handleInvalidNavigation();
        return;
      }

      // Always update navigation flow after checking validity
      updateNavigationFlow(path);

      // Navigate to the new path
      navigate(path, state ? { state } : undefined);
    },
    [location, navigate, handleInvalidNavigation, updateNavigationFlow]
  );



  // Check session and navigation validity
  useEffect(() => {
    const checkSessionAndNavigation = () => {
      const currentPath = location.pathname;
      const currentToken = getCookie(JWT_COOKIE_NAME);

      // Check for protected route session
      if (isProtectedRoute(currentPath)) {
        if (!currentToken || isCookieExpired(JWT_COOKIE_NAME)) {
          handleSessionExpiry();
          return;
        }
      }

      // Check for flow-protected route navigation
      if (isFlowProtectedRoute(currentPath)) {
        if (!isValidNavigationFlow(currentPath)) {
          handleInvalidNavigation();
          return;
        }
      }
    };

    checkSessionAndNavigation();

    // Set up interval for session checks on protected routes
    if (isProtectedRoute(location.pathname)) {
      const intervalId = setInterval(checkSessionAndNavigation, CHECK_INTERVAL);
      return () => clearInterval(intervalId);
    }
  }, [location, handleSessionExpiry, handleInvalidNavigation]);

  // Initialize token from cookie on mount
  useEffect(() => {
    const cookieToken = getCookie(JWT_COOKIE_NAME);
    if (cookieToken && !isCookieExpired(JWT_COOKIE_NAME)) {
      setToken(cookieToken);
    }
  }, []);

  return {
    token,
    setToken: updateToken, // Return the cookie-aware setter
    showExpiredAlert,
    showInvalidNavigationAlert,
    navigateTo,
    isAuthenticated: !!token && !isCookieExpired(JWT_COOKIE_NAME),
  };
};

// Auth Wrapper Component
export const AuthWrapper = ({ children }) => {
  const { showExpiredAlert, showInvalidNavigationAlert } =
    useSessionManager(null);

  return (
    <>
      {showExpiredAlert && (
        <div className="fixed w-screen h-screen bg-black/50 backdrop-blur-sm flex justify-center items-center z-50">
          <SessionExpiredAlert />
        </div>
      )}
      {showInvalidNavigationAlert && (
        <div className="fixed w-screen h-screen bg-black/50 backdrop-blur-sm flex justify-center items-center z-50">
          <InvalidNavigationAlert />
        </div>
      )}
      {children}
    </>
  );
};

// Protected Route Component
export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = getCookie(JWT_COOKIE_NAME);

  useEffect(() => {
    if (!token || isCookieExpired(JWT_COOKIE_NAME)) {
      sessionStorage.setItem("redirectAfterLogin", location.pathname);
      navigate("/login");
    }
  }, [token, navigate, location]);

  return token && !isCookieExpired(JWT_COOKIE_NAME) ? children : null;
};

// Public Route Component
export const PublicRoute = ({ children }) => {
  return children;
};

const useInvalidNavigation = () => {
  const [showInvalidNavigationAlert, setShowInvalidNavigationAlert] =
    useState(false);
  const navigate = useNavigate();

  const handleInvalidNavigation = useCallback(() => {
    setShowInvalidNavigationAlert(true);
    setTimeout(() => {
      setShowInvalidNavigationAlert(false);
      const flowHistory = JSON.parse(
        sessionStorage.getItem(FLOW_STATE_KEY) || "[]"
      );
      if (flowHistory.length > 0) {
        const previousPath = flowHistory[flowHistory.length - 1].path;
        navigate(previousPath);
      } else {
        navigate("/");
      }
    }, 3000);
  }, [navigate]);

  return {
    showInvalidNavigationAlert,
    handleInvalidNavigation,
  };
};

export const FlowProtectedRoute = ({ children }) => {
  const location = useLocation();
  const { handleInvalidNavigation } = useInvalidNavigation();
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const checkValidity = () => {
      setTimeout(() => {
        const currentPath = location.pathname;
        const valid = isValidNavigationFlow(currentPath);

        // Check if session flag is set for PayU return routes
        const routeConfig = FLOW_PROTECTED_ROUTES[currentPath];
        const sessionFlag = routeConfig?.validSessionFlag
          ? sessionStorage.getItem(routeConfig.validSessionFlag)
          : null;

        console.log("Route Validity Check:", currentPath, valid, sessionFlag);

        // If route is invalid and not an entry point, handle invalid navigation
        if (!valid && !FLOW_ENTRY_POINTS.includes(currentPath)) {
          // Allow if session flag exists for routes like /success or /failure
          if (sessionFlag) {
            sessionStorage.removeItem(routeConfig.validSessionFlag); // Clear flag for one-time access
            setIsValid(true);
          } else {
            handleInvalidNavigation();
          }
          return;
        }

        setIsValid(true);
      }, 100);
    };

    checkValidity();
  }, [location, handleInvalidNavigation]);

  return isValid ? children : null;
};
