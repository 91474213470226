import React, { useState } from 'react'
import { CartState } from '../../context/Context'
import axios from 'axios'
import { HmacSHA256, enc } from 'crypto-js'; 
// import { SecretsManagerClient } from "@aws-sdk/client-secrets-manager";
import OAuth from 'oauth-1.0a';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie'
import { regions } from '../Address/Address';
import { LoadingDialog } from '../../screens/Cart/Cart';




const AddressCard = ({ address, allAddresses, updateAddresses }) => {
    const { dispatch } = CartState()
    const [userData, setUserData] = useState(JSON.parse(localStorage?.getItem("userData") || "{}"))
    const cookies = new Cookies();
    const token = cookies?.get("auth_token")
    const [addressRecvd, setAddressRecvd] = useState(address)
    const [showMenu, setShowMenu] = useState(false)
    const [updatedAddress, setUpdatedAddress] = useState({})
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [firstname, setFirstname] = useState(address?.firstname)
    const [lastname, setLastname] = useState(address?.lastname)
    const [street, setStreet] = useState(address?.street[0])
    const [city, setCity] = useState(address?.city)
    const [state, setState] = useState(address?.region?.region)
    const [country, setCountry] = useState(address?.country)
    const [phone, setPhone] = useState(address?.telephone)
    const [postcode, setPostcode] = useState(address?.postcode)
    const [updatingAddress, setUpdatingAddress] = useState(false)
    const [deletingAddress, setDeletingAddress] = useState(false)
    const req_headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
    

    const url = `${process.env.REACT_APP_API_URL}/rest/V1/addresses`;

    // const oauth = OAuth({
    //     consumer: {
    //         key: secret?.consumerKey,
    //         secret: secret?.consumerSecret,
    //     },
    //     signature_method: 'HMAC-SHA256',
    //     hash_function(base_string, key) {
    //         return HmacSHA256(base_string, key).toString(enc.Base64); // Use HmacSHA256 from crypto-js
    //     },
    // });

    // const requestData = {
    //     method: 'POST', // Or any other HTTP method your API requires
    //     url: url,
    // };

    // const requestHeaders = oauth.toHeader(oauth.authorize(requestData, { key: secret?.accessToken, secret: secret?.accessTokenSecret }));

    const handleAddressUpdate = () => {
        setUpdatingAddress(true)
        const updatedAddress = {
          address: {
            id: address?.id,
            customer_id: userData?.id,
            firstname: firstname,
            lastname: lastname,
            region: {
              region_code: state?.code,
              region: state?.name,
              region_id: Number(state?.id),
            },
            street: [street],
            telephone: phone,
            postcode: postcode,
            city: city,
            default_billing:
              userData?.default_billing == address?.id ? true : false,
            default_shipping:
              userData?.default_shipping == address?.id ? true : false,
            country_id: address?.country_id,
          },
        };
        console.log("Address updated: ", updatedAddress)
        axios
          .post(`${process.env.REACT_APP_API_URL}/rest/V1/save/addresses`, updatedAddress, { headers: req_headers })
          .then((response) => {
            console.log("request sent: ", response?.data);
            setAddressRecvd(response?.data);
              setShowEditDialog(false);
              setUpdatingAddress(false);
              toast.dark(`✅ Updated Successfully!`);
              
          })
          .catch((error) => {
              console.log("error received while upadting address: ", error);
              setUpdatingAddress(false);
            toast.dark(
              `❌ Oops! Error while updating address. Please try again.`
            );
          });
    }

    const handleAddressDelete = () => {
        const confirmDelete = window.confirm('Are you sure you want to delete this address?');
        if (confirmDelete) {
            setDeletingAddress(true)
            const updatedArray = allAddresses?.filter(addy => addy?.id !== address?.id)
            axios.put(`${process.env.REACT_APP_API_URL}/rest/V1/customers/me`, { customer: { firstname: userData?.firstname, lastname: userData?.lastname, email: userData?.email, addresses: updatedArray } }, { headers: req_headers })
            .then((response) => {
                dispatch({
                    type: "UPDATE_USER_DATA",
                    payload: {
                        data: response?.data,
                    },
                })    
                updateAddresses(response?.data?.addresses)
                localStorage.setItem(
                  "userData",
                  JSON.stringify(response?.data)
                );
                setDeletingAddress(false);
                toast.dark(`✅ Address deleted successfully!`)
            })
            .catch((error) => {
                console.log("Error received: ", error?.response?.data?.message) 
                setDeletingAddress(false);
            })
        } else {
            console.log("Delete request cancelled")
        }

    }
        

    return (
      <>
        <div className="w-full p-5 border-b-2 flex gap-2 justify-between">
          {showEditDialog ? (
            <div>
              <form
                onSubmit={(e) => {
                  e?.preventDefault();
                  handleAddressUpdate();
                }}
              >
                <div className="flex items-center py-4 ">
                  <span className="text-base text-[#2D2D2D] font-medium">
                    EDIT ADDRESS
                  </span>
                </div>
                <div className="flex justify-between gap-4">
                  <div className="w-1/2">
                    <label className="text-[#767676] text-base">
                      First Name
                    </label>
                    <input
                      className="w-full px-3 py-2 border-[1px] border-[#C7C7C7]
                                     focus:outline-none"
                      type="text"
                      placeholder="John"
                      name="firstName"
                      value={firstname}
                      onChange={(e) => {
                        setFirstname(e?.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="text-[#767676] text-base">
                      Last Name
                    </label>
                    <input
                      className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] 
                                     focus:outline-none"
                      type="text"
                      placeholder="Doe"
                      name="lastName"
                      value={lastname}
                      onChange={(e) => {
                        setLastname(e?.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label className="text-[#767676] text-base">Address</label>
                  <input
                    className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none"
                    type="text"
                    placeholder="60 Sunrise Dr, Lebanon, KY, 40033"
                    required
                    name="address"
                    value={street}
                    onChange={(e) => {
                      setStreet(e?.target.value);
                    }}
                  />
                </div>
                <div className="flex justify-start items-center gap-4 mt-4">
                  <div className="col-span-2 w-1/3">
                    <label className="text-[#767676] text-base">City</label>
                    <input
                      className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none"
                      type="text"
                      placeholder="Your City"
                      required
                      name="city"
                      value={city}
                      onChange={(e) => {
                        setCity(e?.target.value);
                      }}
                    />
                  </div>
                  <div className="flex gap-4 w-1/3">
                    <div className="w-full flex flex-col">
                      <label className="text-[#767676] text-base">State</label>
                      <select
                        name="region_dd"
                        id="region_dd"
                        className="w-full px-1 py-2 border-[1px] border-[#C7C7C7] focus:outline-none"
                        value={state?.name || ""}
                        onChange={(e) => {
                          const selectedName = e.target.value;
                          const selectedRegion = regions.find(
                            (region) => region.name === selectedName
                          );
                          console.log("selected Region: ", selectedRegion);
                          setState(selectedRegion);
                        }}
                        required
                      >
                        <option value="">Select a State</option>
                        {regions?.map((region) => (
                          <option key={region.id} value={region.name}>
                            {" "}
                            {/* Using name as value */}
                            {region.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="w-1/3">
                    <label className="text-[#767676] text-base">Country</label>
                    <input
                      disabled
                      className="w-full px-1 py-2 border-[1px]
                                 border-[#C7C7C7] focus:outline-none"
                      name="country"
                      value={"India"}
                      required
                    />
                  </div>
                </div>
                <div className="flex justify-between items-center gap-4 mt-4">
                  <div className="w-1/2">
                    <label className="text-[#767676] text-base">Phone</label>
                    <input
                      className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none"
                      type="text"
                      placeholder="+910000000000"
                      required
                      name="zipcode"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e?.target.value);
                      }}
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="text-[#767676] text-base">
                      Postal/Zipcode
                    </label>
                    <input
                      className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none"
                      type="text"
                      placeholder="10001"
                      required
                      name="zipcode"
                      value={postcode}
                      onChange={(e) => {
                        setPostcode(e?.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-start gap-4 mt-7">
                  <button
                    disabled={updatingAddress}
                    className="w-[90px]  py-3 border rounded-md bg-white text-black  text-sm font-semibold border-gray-400"
                    onClick={(e) => {
                      setShowEditDialog(false);
                      e?.preventDefault();
                    }}
                  >
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    disabled={updatingAddress}
                    className=" w-[90px]  py-3 border rounded-md bg-black text-white  text-sm font-semibold border-gray-400 flex items-center justify-center"
                  >
                    {updatingAddress ? (
                      <svg
                        aria-hidden="true"
                        className={`w-5 h-5 text-gray-200 animate-spin  fill-blue-600`}
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : (
                      <span>SUBMIT</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <>
              <div className="flex gap-3 flex-col">
                <div className="flex gap-3">
                  <p className="font-medium">
                    {addressRecvd?.firstname + " " + addressRecvd?.lastname}
                  </p>
                  <p>{addressRecvd?.telephone}</p>
                </div>
                <div>
                  <p>
                    {addressRecvd?.street?.[0]}, {addressRecvd?.city},{" "}
                    {addressRecvd?.region?.region}, {addressRecvd?.country} -{" "}
                    {addressRecvd?.postcode}
                  </p>
                </div>
              </div>
              <div
                className="cta-container flex relative"
                onMouseLeave={() => setShowMenu(false)}
              >
                <button onMouseOver={() => setShowMenu(true)}>
                  <svg
                    className="w-6 h-6 text-gray-800 cursor-pointer"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 4 15"
                  >
                    <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                  </svg>
                </button>
                <ul
                  className={`absolute right-0 w-20 bg-white py-2 mt-2 rounded-lg shadow-xl ${
                    showMenu ? "block" : "hidden"
                  }`}
                >
                  <li
                    className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      setShowEditDialog(!showEditDialog);
                      setShowMenu(false);
                    }}
                  >
                    Edit
                  </li>
                  <li
                    className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      handleAddressDelete();
                      setShowMenu(false);
                    }}
                  >
                    Delete
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
        <div className="font-outfit text-sm ">
          <ToastContainer position="bottom-left" autoClose={4000} type="dark" />
        </div>
        <LoadingDialog
          isOpen={deletingAddress}
          message={"Deleting Address..."}
        />
      </>
    );
}

const ManageAddress = () => {
    const [userData, setUserData] = useState(JSON.parse(localStorage?.getItem("userData") || "{}"))
    const [addresses, setAddresses] = useState(JSON.parse(localStorage?.getItem("userData")) ? JSON.parse(localStorage?.getItem("userData"))?.addresses : [])
  return (
      <div className='prf-addy-container w-full p-4 pb-8 gap-6 flex flex-col'>
          <p className='font-semibold'>Manage Addresses</p>
          <div className='addresses-container'>
              <div className="add-address"></div>
              <div className="address-map-container border rounded-sm">
                  { addresses?.length > 0 ? 
                      addresses?.map((address, index) => (
                          <div key={address?.id} className='w-full'>
                              <AddressCard address={address} updateAddresses={setAddresses} allAddresses={addresses} />
                          </div>
                          
                      )) : <div className='w-full flex mt-10 justify-center items-center'><p>No addresses added to the account yet.</p></div>
                  }
              </div>
          </div>
    </div>
  )
}

export default ManageAddress