import React, {useEffect, useState} from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
// import { BsArrowLeft } from 'react-icons/bs'
// import { IoShareSocial } from 'react-icons/io5'
import Accordion from '../../components/Accordion/Accordion'
import {BiStar, BiSolidShareAlt} from 'react-icons/bi';
// import {ImFacebook, ImTwitter} from 'react-icons/im'
// import { FaPinterestP } from 'react-icons/fa';
import axios from 'axios'
import { CartState } from '../../context/Context'
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie'
import StarRating from '../../components/StarRating/StarRating';
import OAuth from 'oauth-1.0a';
import { HmacSHA256, enc } from 'crypto-js';
import Loader from '../../components/Loader/Loader';
import ReviewCard from '../../components/ReviewCard/ReviewCard';
import rudderAnalytics from '../../rudderstack';
import usePageDurationTracker from '../../components/Hooks/usePageDurationTracker';
import AverageRating from '../../components/AverageRating/AverageRating';
import { setCookie } from '../../SessionManager';
import { generateRandomString } from '../../ReactFunctions';
import RelatedProducts from '../../components/RelatedProducts/RelatedProducts';


const ProductPage = () => {
  const cookies = new Cookies()
  const navigate = useNavigate()
  const location = useLocation()
  const {sku} = useParams()
  const { state: { cart, secret }, dispatch } = CartState()
  
  const token = cookies?.get("auth_token")
  const cart_id = window?.localStorage?.getItem("cart_id")
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(location?.state)
  const size_array = [8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 13]
  const colors = [1, 2, 3, 4, 5]
  const [count, setCount] = useState(1)
  const product_url = window.location.href
  const [showReviewInput, setShowReviewInput] = useState(false)
  const [summary, setSummary] = useState("")
  const [reviewContent, setReviewContent] = useState("")
  const [rating, setRating] = useState(0)
  const [reviewData, setReviewData] = useState([])
  const [reviewLoading, setReviewLoading] = useState(false)
  const [userOrdered, setUserOrdered] = useState(false);
  const [productAddedToCart, setProductAddedToCart] = useState(false)
  const userData = JSON.parse(localStorage?.getItem("userData") || "{}");
  const [addingToCart, setAddingToCart] = useState(false);
  const [addingToCompare, setAddingToCompare] = useState(false)
  const [isWishListed, setIsWishListed] = useState(false);
  const [addingToWishlist, setAddingToWishlist] = useState(false);
  
  const oneWeekFromNow = new Date();
  oneWeekFromNow.setTime(oneWeekFromNow.getTime() + 604800 * 1000);

   const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
  }
  

  //check if the user has ordered the item before to provide a review
  const hasOrdered = () => {
    const payload = {
      "customerId": userData?.id,
      "productId": data?.id,
    };
    axios.post(`${process.env.REACT_APP_API_URL}/rest/V1/ordercheck/hasordered`, payload)
    .then((res) => {
      console.log("response from has ordered: ", res?.data)  
      setUserOrdered(res?.data)
    })
    .catch((err) => {
      console.log("err while fetching has ordered: ", err)  
    })
  }


  const list = [
    {
      question: "Materials",
      answer:
        "Hand-crafted from Italian cow leather. Ultrasuede interior. Comes with a detachable and adjustable shoulder strap.",
    },
    {
      question: "Dimensions",
      answer: "39cm x 99cm",
    },
    {
      question: "Care Instructions",
      answer:
        "Wash it with warm water. Dry in the sun and use oil to condition the leather.",
    },
  ];

  const images = [
    {
      url: `${process.env.REACT_APP_API_URL}/media/catalog/product` + data?.media_gallery_entries?.[0]?.file
    },
    {
      url: "https://images.unsplash.com/photo-1525547719571-a2d4ac8945e2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=764&q=80"
    },
    {
      url: "https://images.unsplash.com/photo-1549298916-b41d501d3772?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1112&q=80"
    },
    {
      url: "https://images.unsplash.com/photo-1524805444758-089113d48a6d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=688&q=80"
    }    
  ]

  const [mainImg, setMainImg] = useState(images?.[0]?.url)

   const handleAddtoCart = () => {
     setAddingToCart(true);
     if (token) {
       const payload = {
         cartItem: {
           sku: data?.sku,
           qty: 1,
           quote_id: cart_id,
         },
       };
       axios
         .post(
           `${process.env.REACT_APP_API_URL}/rest/V1/carts/mine/items`,
           payload,
           { headers: headers }
         )
         .then((response) => {
           console.log("response from add to cart: ", response?.data);
           toast.dark(`✅ ${data?.name} is now added to your cart`, {
             closeOnClick: true,
           });
           setAddingToCart(false);
           setCookie("items_in_cart", response?.data?.item_qty);
         })
         .catch((error) => {
           console.log("error from add to cart: ", error);
           setAddingToCart(false);
         });
     } else {
       alert("You're not logged in. Please login to continue.");
       navigate("/login");
     }
   };

  //  const handleAddtoCart = () => {
  //       if (token) {
  //           const payload = {
  //               "cartItem": {
  //                   "sku": data?.sku,
  //                   "qty": 1,
  //                   "quote_id": cart_id
  //               }
  //           }

  //           axios.post(`${process.env.REACT_APP_API_URL}/rest/V1/carts/mine/items`, payload, { headers: headers })
  //               .then((response) => {
  //                   console.log("response from add to cart: ", response?.data)
  //                 toast.dark(`✅ ${data?.name} is now added to your cart`, { closeOnClick: true })
  //                 setProductAddedToCart(true)
  //                   if (cart?.some((c) => c?.sku === data?.sku)) {
  //                       dispatch({
  //                           type: "INCREMENT",
  //                           payload: {
  //                               sku: data?.sku
  //                           }
  //                       })
  //                   } else {
  //                       dispatch({
  //                           type: "ADD_TO_CART",
  //                           payload: data
  //                       })
  //                       updateItemsInCart(getItemsInCart()+1)
  //                   }
  //                   console.log("Context: ", cart)
  //               })
  //               .catch((error) => {
  //                   console.log("error from add to cart: ", error)
  //               })
  //       } else {
  //           alert("You're not logged in. Redirecting you to the login page...")
  //           navigate("/login")
  //       }

  // }

      const addToWishlist = () => {
        if (userData?.id) {
          if (data) setAddingToWishlist(true);
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/rest/V1/wishlist/customer/product/${userData?.id}/${data?.id}`,
              null,
              { headers }
            )
            .then((response) => {
              console.log("Response : ", response?.data);
              if (response?.data === true) {
                toast.dark(`✅ ${data?.name} is added to WishList`, {
                  closeOnClick: true,
                });
                setIsWishListed(true);
                setAddingToWishlist(false);
              } else {
                toast.dark(
                  `❌ ${data?.name} is not added to Wishlist. Please try again`,
                  { closeOnClick: true }
                );
                setAddingToWishlist(false);
              }
            })
            .catch((err) => {
              console.log("Error while adding item to wishlist: ", err);
            });
        } else {
          const routeLogin = window.confirm(
            "Please login to add the product to your wishlist. Do you want to login?"
          );
          console.log("routeLogin value:", routeLogin);
          if (routeLogin) {
            navigate("/login"); // Navigate to the login page
          }
        }
      };

      const removeFromWishlist = () => {
        axios
          .delete(
            `${process.env.REACT_APP_API_URL}/rest/V1/wishlist/customer/item/${userData?.id}/${data?.id}`,
            null,
            { headers: headers }
          )
          .then((response) => {
            console.log("Response : ", response?.data);
            toast.dark(`🗑️ ${data?.name} is removed from your Wishlist.`, {
              closeOnClick: true,
            });
            if (data?.extension_attributes?.wishlist) {
              data.extension_attributes.wishlist = false;
            }
            setIsWishListed(false);
          })
          .catch((err) => {
            console.log("Error while adding item to wishlist: ", err);
            toast.dark(
              `❌ Error while removing ${data?.name} from your Wishlist. Please try again.`,
              {
                closeOnClick: true,
              }
            );
          });
      };
  
  const fetchProductInfo = () => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API_URL}/rest/default/V1/products/${sku}?customer_id=${userData?.id ?? ""}`)
    .then((response) => {
        console.log('response from fetch img: ', response?.data)
      setData(response?.data)
      if (userData?.id) hasOrdered();
      setMainImg(`${process.env.REACT_APP_API_URL}/media/catalog/product${response?.data?.media_gallery_entries?.[0]?.file}`)
      setIsWishListed(response?.data?.extension_attributes?.wishlist);
      setLoading(false)
    })
    .catch((err) => {
      console.log("err from fetchImages: ", err);
      setLoading(false)
    });
  }

  const handleReviewSubmit = (e) => {
    e?.preventDefault()
    console.log("Summary: ", summary)
    console.log("Review: ", reviewContent)
    console.log("secret from context: ", secret)
    if (rating !== 0) {
      const review_payload = {
        review: {
          title: summary,
          detail: reviewContent,
          nickname: userData?.firstname + " " + userData?.lastname,
          ratings: [
            {
              rating_name: "Rating",
              value: rating,
            },
            {
              rating_name: "Quality",
              value: rating,
            },
          ],
          review_entity: "product",
          review_status: 1,
          entity_pk_value: data?.id,
          customer_id: userData?.id,
        },
      };

      axios.post(`${process.env.REACT_APP_API_URL}/rest/V1/reviews`, review_payload)
        .then((response) => {
          toast.dark(`✅ Review submitted successfully`, {
            closeOnClick: true,
          });
          console.log("Response:", response?.data);
          setReviewData([...reviewData, response?.data?.review])
          setShowReviewInput(false)
        })
        .catch((error) => {
          toast.dark(`❌ Error while submitting review. Please try again.`, {
            closeOnClick: true,
          });
          console.error("Error:", error);
        });
    } else {
     alert("Please select the rating before submitting the review.")
    }
    
  }

  const productShare = (text,title,url) => {
    if (navigator?.share) {
      navigator.share({
        text: text,
        title: title,
        url: url        
      });
    } else {
      navigator?.clipboard?.writeText(url);
    }
  };

  const addToCompare = () => {
    const payload = {
      productId: data?.id,
      compareToken: "",
    };
    if (userData?.id) {
      payload.compareToken = token?.substring(0, 32);
    } else {
      payload.compareToken = generateRandomString(32);
    }
    setAddingToCompare(true)
    axios
      .post(`${process.env.REACT_APP_API_URL}/rest/V1/compare/add`, payload)
      .then((res) => {
        console.log("response from add to compare: ", res?.data);
        setAddingToCompare(false);
        toast.dark(`✅ ${data?.name} is now added to your comparison list`, {
          closeOnClick: true,
        });
      })
      .catch((err) => {
        console.log("error while adding item to comparison list: ", err);
        if (
          err?.response.data.message ===
          "Product is already in the compare list."
        ) {
          toast.dark(`❌ ${data?.name} is already in your compare list`, {
            closeOnClick: true,
          });
        } else {
          toast.dark(
            `❌ Error while adding ${data?.name} to your comparison list`,
            { closeOnClick: true }
          );
        }
        setAddingToCompare(false);
        
      });
  };

  const getProductReviews = () => {
    setReviewLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/rest/V1/products/reviews/${data?.sku}`)
      .then((response) => {
        setReviewData(response?.data?.reviews?.reverse());
        console.log("review data received: ", response?.data);
        // if (response?.data?.reviews?.length > 0) getAvgRating(response?.data);
        setReviewLoading(false);
      })
      .catch((err) => {
        console.log("error while fetching prod reviews: ", err);
        setReviewLoading(false);
      });

  }


  useEffect(() => {
    window.scrollTo(0, 0)
    // if (data?.name) {
    //   setMainImg(images?.[0]?.url)
    // } else {
    fetchProductInfo()
    getProductReviews()

    return () => {
      if (!productAddedToCart) {
        rudderAnalytics.track("product_bounce_rate", {
          ProductName: data?.name,
          sku: data?.sku,
        });
      }
    }

    // }
  }, [])

  usePageDurationTracker("Product Page");

  useEffect(() => {
    if (data?.name) {
      rudderAnalytics.track("Product Viewed", {
        ProductName: data?.name,
        sku: data?.sku,
      });
    }
  },[])
  
  return (
    <div className="flex flex-col font-man select-none items-center justify-start p-2">
      <div className="w-full p-2 md:px-4">
        <p
          className="hover:underline cursor-pointer md:px-4 rounded-md md:text-sm text-xs font-medium"
          onClick={() => navigate(-1)}
        >
          BACK
        </p>
      </div>
      <div className="w-[80%] flex flex-col md:flex-row justify-center items-start md:p-4 gap-2 m-0 ">
        <div className="md:w-1/2 w-full max-w-max p-2 flex flex-col gap-2">
          {loading ? (
            <div className="rounded-md bg-[#ECF2F4] w-[100px] h-[100px] loading-container p-4 flex justify-center items-center lg:h-[550px] lg:w-[400px]">
              <svg
                aria-hidden="true"
                className="w-5 h-5 mr-2 text-gray-200 animate-spin  fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          ) : (
            <img
              alt="ecommerce"
              className="block sm:rounded-xl border border-gray-300 lg:h-[550px] lg:max-h-[550px] lg:w-[450px] object-fill lg:pointer-events-auto cursor-pointer"
              src={mainImg}
              width="100%"
              height="100%"
            />
          )}
        </div>

        <div className="md:w-1/2 w-full h-full flex flex-col items-start justify-start gap-3 p-1">
          <p className="text-base text-gray-500">Vendor Name</p>
          <p className="md:text-3xl text-xl font-bold">{data?.name}</p>
          <p className="font-semibold flex gap-4 items-center">
            <span className="md:text-2xl text-lg">
              ₹{parseFloat(data?.price).toFixed(2)}
            </span>
          </p>
          <div className="w-full flex justify-between items-center">
            <p className="text-sm text-gray-400">Tax Included</p>
            <div className="flex">
              <button
                className="flex gap-1 hover:underline"
                onClick={() => {
                  productShare(
                    `Hey! Look I found this amazing product ${data?.name}`,
                    `Check out this amazing product shared by ${userData?.name}`,
                    product_url
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M15.75 4.5a3 3 0 1 1 .825 2.066l-8.421 4.679a3.002 3.002 0 0 1 0 1.51l8.421 4.679a3 3 0 1 1-.729 1.31l-8.421-4.678a3 3 0 1 1 0-4.132l8.421-4.679a3 3 0 0 1-.096-.755Z"
                    clipRule="evenodd"
                  />
                </svg>
                Share
              </button>
            </div>
          </div>
          <div className="flex w-full sm:w-full gap-4 justify-start items-center">
            <button
              disabled={addingToCart}
              className="px-4 py-3 md:h-[50px] h-[40px] border w-[180px] rounded-md  text-sm font-semibold  text-white bg-black cursor-pointer"
              onClick={() => {
                handleAddtoCart();
              }}
            >
              <p className="text-center flex justify-center items-center">
                {addingToCart ? (
                  <div className="loading-container flex justify-center items-center w-[70px] h-[20px]">
                    <svg
                      aria-hidden="true"
                      className={`w-5 h-5 text-gray-200 animate-spin  fill-blue-600`}
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : (
                  <span>ADD TO CART</span>
                )}
              </p>
            </button>
            {isWishListed || addingToWishlist ? (
              <button
                className="px-4 py-3 rounded-md border-black border-2 md:h-[50px] h-[40px]"
                onClick={removeFromWishlist}
                disabled={addingToWishlist}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className={`w-6 h-6 text-red-600 ${
                    addingToWishlist && "animate-pulse"
                  }`}
                >
                  <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                </svg>
              </button>
            ) : (
              <button
                className="px-4 py-3 rounded-md border-black border-2 md:h-[50px] h-[40px]"
                onClick={() => {
                  addToWishlist();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                </svg>
              </button>
            )}
            <button
              className="px-4 py-3 rounded-md border-black border-2 md:h-[50px] h-[40px]"
              disabled={addingToCompare}
              onClick={addToCompare}
            >
              {addingToCompare ? (
                <svg
                  aria-hidden="true"
                  className={`w-5 h-5 text-gray-200 animate-spin  fill-blue-600`}
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 text-gray-700 cursor-pointer hover:text-gray-900 transition-colors duration-200 "
                >
                  <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75ZM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 0 1-1.875-1.875V8.625ZM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 0 1 3 19.875v-6.75Z" />
                </svg>
              )}
            </button>
          </div>
          <div className="w-full flex flex-col items-start justify-center gap-3">
            <p className="text-lg font-semibold">Product Details</p>
            <p className="text-gray-500 font-medium">
              {data?.name} from Nike's largest Air Max unit puts a distinctive
              footprint under the heel of a cloud-comfort sneaker sporting a
              sock-knit upper branded with welded Swooshes.
            </p>
          </div>

          <div className="w-full">
            <Accordion items={list} />
          </div>
        </div>
      </div>
      {/*Product Review Section*/}
      <div className="w-[80%] rounded-md p-2 flex flex-col justify-start items-center gap-2">
        <div className="w-full p-2 flex justify-between items-center">
          <p className="font-semibold md:text-2xl text-xl">REVIEWS</p>
          <p className="font-bold text-2xl flex items-center gap-1">
            <AverageRating reviews={reviewData} />
          </p>
        </div>
        <hr className="w-full p-2" />
        <div className="w-full p-2 flex justify-between items-center py-4">
          <div className={`${userOrdered ? "visible" : "invisible"}`}>
            <button
              className="p-2 rounded-md w-[150px] h-[40px] text-white bg-[#EA8B24] flex items-center justify-center hover:bg-[#D77716]"
              onClick={() => setShowReviewInput(!showReviewInput)}
            >
              <p>Add a Review</p>
            </button>
          </div>
          <div>
            <select
              name="review_sort"
              id="review_sort"
              className="border border-gray-200 p-2 rounded-sm"
            >
              <option value="" defaultValue className="p-1">
                Sort By
              </option>
              <option value={1} className="p-1">
                Top Rated Reviews
              </option>
              <option value={2} className="p-1">
                Recent Reviews
              </option>
            </select>
          </div>
        </div>
        {showReviewInput && (
          <form
            className={`flex w-full justify-between items-start flex-col gap-2 ${
              showReviewInput ? "opacity-100 h-auto py-1" : "opacity-0 h-0"
            } overflow-hidden transition-all duration-500 ease-in-out`}
            onSubmit={(e) => handleReviewSubmit(e)}
          >
            <div
              className={`w-full p-2 flex flex-col gap-1 ${
                showReviewInput ? "opacity-100 h-auto py-1" : "opacity-0 h-0"
              } overflow-hidden transition-all duration-500 ease-in-out`}
            >
              <p>Summary</p>
              <input
                type="text"
                name="review-summary"
                value={summary}
                onChange={(e) => setSummary(e?.target?.value)}
                id=""
                className="w-full border border-gray-300 p-2 outline-none rounded-md"
                placeholder="Title/Summary of the review"
                required
              />
            </div>
            <div
              className={`w-full p-2 flex flex-col gap-1 ${
                showReviewInput ? "opacity-100 h-auto py-1" : "opacity-0 h-0"
              } overflow-hidden transition-all duration-500 ease-in-out`}
            >
              <p>Rating</p>
              <StarRating rating={rating} setRating={setRating} />
            </div>
            <div
              className={`w-full p-2 flex flex-col gap-1 ${
                showReviewInput ? "opacity-100 h-auto py-1" : "opacity-0 h-0"
              } overflow-hidden transition-all duration-500 ease-in-out`}
            >
              <p>Review</p>
              <textarea
                name="review-content"
                id=""
                value={reviewContent}
                className="w-full border border-gray-300 p-2 outline-none rounded-md"
                rows={3}
                placeholder="Write your review in detail here"
                onChange={(e) => setReviewContent(e?.target?.value)}
                required
              />
            </div>
            <div
              className={`w-full p-2 flex justify-between items-center gap-1 ${
                showReviewInput ? "opacity-100 h-auto py-1" : "opacity-0 h-0"
              } overflow-hidden transition-all duration-500 ease-in-out`}
            >
              <button className="p-2 rounded-md w-[150px] h-[40px] text-white bg-[#EA8B24] flex items-center justify-center hover:bg-[#D77716]">
                Submit Review
              </button>
              <button
                className="p-2 rounded-md w-[150px] h-[40px] text-white bg-black flex items-center justify-center hover:bg-slate-900"
                onClick={() => setShowReviewInput(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
        <div className="min-h-[100px] w-full p-2">
          {loading ? (
            <Loader size={7} />
          ) : reviewData?.length > 0 ? (
            <div className="w-full flex flex-col divide-y-2 divide-gray-300 justify-start items-center">
              {reviewData?.map((review, index) => {
                return (
                  <ReviewCard
                    reviewData={review}
                    key={review?.id + index + review?.title}
                  />
                );
              })}
            </div>
          ) : (
            <div>No reviews found</div>
          )}
        </div>
        <div className="w-full">
          <RelatedProducts sku={data?.sku} />
        </div>
      </div>

      <div className="font-outfit text-sm ">
        <ToastContainer position="bottom-left" autoClose={4000} type="dark" />
      </div>
    </div>
  );
}

export default ProductPage