import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertToUrlFormat } from "../../ReactFunctions";

const MobileMenu = ({ categories, showMobileMenu, setShowMobileMenu }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [subMenu, setSubMenu] = useState(null);
    const navigate = useNavigate();
    
      const handleCategoryClick = (id, name) => {
        navigate(`/categories/${id}/${convertToUrlFormat(name)}`);
      };



  useEffect(() => {
    setActiveMenu(null);
    setSubMenu(null);
  }, [showMobileMenu]);
    
    

  return (
    <div
      className={`${
        showMobileMenu
          ? "left-0 w-full sm:w-[40%] md:w-[50%] px-10 py-5 z-[2]"
          : "-left-full w-0"
      } overflow-y-scroll scrollbar-hide shadow-md font-medium text-[15px] duration-500 absolute top-full font-man bg-gray-800 h-screen text-white`}
    >
      {/* {!activeMenu &&   */}
      <div>
        <ul className="space-y-5">
          {categories?.map((category) => (
            <li key={category.id} className="flex items-center justify-between">
              <p
                className="p-1"
                onClick={() => {
                  setShowMobileMenu(false);
                  handleCategoryClick(category.id, category.name);
                }}
              >
                {" "}
                {category.name}{" "}
              </p>
              {category?.children_data?.length > 0 && (
                <button
                  className="p-1 flex justify-between items-center"
                  onClick={() => setActiveMenu(category)}
                >
                  <svg
                    className="w-6 h-6 ml-auto"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 12H5m14 0-4 4m4-4-4-4"
                    />
                  </svg>
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>
      {/* }   */}
      {/* Clicked Menu Item */}
      {/* {activeMenu && */}
      <div
        className={` ${
          activeMenu ? " left-0" : "-left-full"
        } w-[99%] font-medium overflow-y-auto text-[15px] px-10 py-5 duration-500 absolute top-0 font-montserrat bg-gray-800 h-screen`}
      >
        <div className="flex items-center gap-5">
          <button
            onClick={() => {
              setActiveMenu(null);
              setSubMenu(null);
            }}
            className="flex items-center text-primary font-semibold"
          >
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 12h14M5 12l4-4m-4 4 4 4"
              />
            </svg>
          </button>
          <p
            className="font-semibold text-secondary"
            onClick={() => handleCategoryClick(activeMenu.id, activeMenu.name)}
          >
            {activeMenu?.name}
          </p>
        </div>
        <ul className="py-5 space-y-5 h-full overflow-y-auto">
          {activeMenu?.children_data?.map((child) => (
            <li key={child.innerMenu}>
              <div className="flex justify-between items-center gap-1">
                <p
                  onClick={() => {
                    setShowMobileMenu(false);
                    handleCategoryClick(child.id, child.name);
                  }}
                  className={`${
                    subMenu?.name === child.name
                      ? "text-blue-400 font-semibold"
                      : "rotate-0 text-white font-medium"
                  } py-1`}
                >
                  {child?.name}
                </p>
                {child?.children_data?.length > 0 && (
                  <button
                    onClick={() =>
                        setSubMenu((prevMenu) =>
                            prevMenu?.name === child?.name ? null : child
                        )}
                    className="flex items-center p-1"
                  >
                    <svg
                      className={`${
                        subMenu?.name === child.name
                          ? "rotate-180 text-primary"
                          : "rotate-270 text-black"
                      } duration-300 w-5 h-5 dark:text-white`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 9-7 7-7-7"
                      />
                    </svg>
                  </button>
                )}
              </div>
              {subMenu?.name === child.name && (
                <ul className={`ml-5`}>
                  {subMenu?.children_data?.map((lastChild) => (
                    <li
                      key={lastChild.name}
                      className="py-2.5 hover:text-secondary "
                    >
                      <p
                        onClick={() => {
                          setShowMobileMenu(false);
                          handleCategoryClick(
                            lastChild.id,
                            lastChild.name
                          );
                        }}
                      >
                        {lastChild.name}
                      </p>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      {/* }  */}
    </div>
  );
};

export default MobileMenu;
