import React, { useEffect } from 'react'

const AverageRating = ({ reviews }) => {


  console.log("Review data in Avg Rating: ", reviews)

  const calculateAverageRating = (reviews) => {
    // Filter reviews that have ratings with stars
    const reviewsWithStars = reviews?.filter(
      (review) =>
        review?.ratings && review?.ratings?.length > 0 && review?.ratings[0]?.value
    );

    if (reviewsWithStars?.length === 0) return 0;

    // Sum up all star ratings
    const totalStars = reviewsWithStars?.reduce(
      (sum, review) => sum + review.ratings[0].value,
      0
    );

    console.log("TotalStars: ", totalStars)

    // Calculate average and round to 1 decimal place
    return Number((totalStars / reviewsWithStars?.length).toFixed(1));
  };

  const averageRating = calculateAverageRating(reviews);
  const totalReviews = reviews?.filter(
    (review) =>
      review?.ratings && review?.ratings?.length > 0 && review?.ratings[0]?.value
  )?.length;

  // Render stars based on rating
  const renderStars = () => {
    return (
      <div className="flex items-center gap-1">
        {[1, 2, 3, 4, 5].map((star) => (
          <svg
            key={star}
            className={`w-5 h-5 ${
              star <= averageRating ? "text-yellow-400" : "text-gray-300"
            }`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        ))}
        <span className="ml-2 text-gray-600">
          {averageRating} out of 5 ({totalReviews} reviews)
        </span>
      </div>
    );
  };

  return <div className="p-4">{renderStars()}</div>;
};

export default AverageRating;
