import React from 'react'
import men from './../Navbar/men.png'
import collection from './../Navbar/collection.png'

const PromotionSection = () => {
  return (
      <div className='flex flex-col w-full justify-center gap-7 font-man'>
          <div className="w-full flex md:flex-row flex-col-reverse">
                <div className="flex text-center min-h-max flex-col md:w-1/2 justify-center items-center gap-5 px-5 bg-[#ebebeb] py-6">
                    <p className='md:text-4xl font-man font-medium sm:text-3xl text-2xl'>Gift for Dads!</p>
                    <p className='md:text-2xl text-lg'>There's still time to find the perfect present. Order by June 15 to get it by June 18 with expedited shipping.</p>
                    <button className="my-6 bg-black px-4 py-2 text-white md:w-1/4 w-1/2 rounded-md hover:bg-white  hover:text-black">SHOP NOW</button>
                </div>
                <div className="md:w-1/2">
                    <img src={men} height="100%" width="100%" alt="men-promo" className='h-[250px] md:h-full object-fit'/>
                </div>
            </div>
            <div className="w-full sm:flex-row flex-col flex md:justify-start justify-center">
                <div className="md:w-1/2 md:flex-1">
                    <img className="object-fill min-h-full" src={collection} height="100%" width="100%" alt="signup-promo"/>
                </div>
                <div className="flex text-left min-h-max flex-col md:w-1/2 md:items-start items-center justify-center gap-4 bg-[#ebebeb]">
                        <p className='md:text-3xl text-xl font-medium mt-7 md:ml-20'>Signup for Mail &</p>
                        <p className='md:text-3xl text-xl font-bold md:ml-20'><b>Get Extra 25% off</b></p>
                        <p className='md:ml-20 w-2/3'>{`Don’t miss out and stay in the know with Early bird
                            deals and latest offers`}.
                        </p>
                        <button className="my-6 bg-black px-4 py-2 md:w-1/4 w-1/2 rounded-md md:ml-20 text-white hover:bg-white hover:text-black">SHOP NOW</button>
                </div>

            </div> 
    </div>
  )
}

export default PromotionSection