import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ProductCard from "../../components/ProductCard/ProductCard";
import usePageDurationTracker from "../../components/Hooks/usePageDurationTracker";
import Pagination from "../../components/Pagination/Pagination";
import { CartState } from "../../context/Context";
import Loader from "../../components/Loader/Loader";
import ProductCardSkeleton from "../../components/ProductCardSkeleton/ProductCardSkeleton";

const Categories = () => {
  const { id, name } = useParams();
  const userData = JSON.parse(localStorage?.getItem("userData") || "{}");
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const productsPerPage = 20;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

 const fetchProducts = useCallback(
   (page) => {
     setLoading(true);
     axios
       .get(
         `${
           process.env.REACT_APP_API_URL
         }/rest/V1/products?searchCriteria[filter_groups][0][filters][0][field]=category_id&searchCriteria[filter_groups][0][filters][0][value]=${id}&searchCriteria[currentPage]=${page}&searchCriteria[pageSize]=${productsPerPage}&customerId=${
           userData?.id ?? ""
         }`
       )
       .then((res) => {
         setProductList(res.data.items);
         const totalPages = Math.ceil(
           Number(res?.data?.total_count) / productsPerPage
         );
         setTotalPages(totalPages);
         setLoading(false);
       })
       .catch((err) => {
         console.log("Error while fetching products: ", err);
         setLoading(false);
       });
   },
   [id, productsPerPage, userData?.id]
 );

 usePageDurationTracker(`Category_${id}`);

 // Single useEffect to handle both category changes and page changes
 useEffect(() => {
   if (id) {
     // When category changes, reset to page 1 and fetch
     if (currentPage !== 1) {
       setCurrentPage(1);
     } else {
       fetchProducts(currentPage);
     }
   }
 }, [id, currentPage, fetchProducts]);

  return (
    <div className="w-full flex flex-col items-start justify-center gap-4 font-man py-10">
      <div className="flex justify-start w-full gap-4">
        <p className="text-2xl ml-10">
          {name.toUpperCase()?.replace(/-/g, " ")}
        </p>
      </div>
      {loading ? (
        <div className="w-full flex md:flex-wrap md:gap-5 gap-3 p-3 md:p-5 md:justify-center justify-start items-center overflow-x-scroll scrollbar-hide mt-6">
          {[...Array(10)].map((_, index) => (
            <ProductCardSkeleton key={index} />
          ))}
        </div>
      ) : productList.length !== 0 ? (
        <div className="w-full flex flex-col justify-center items-center gap-8">
          <div className="flex mx-auto justify-center flex-wrap gap-8 p-4">
            {productList.map((data, index) => (
              <ProductCard data={data} key={index} />
            ))}
          </div>
          <div className="w-full flex justify-center items-center">
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center items-center min-h-[350px]">
          <p>Oops! No items found.</p>
        </div>
      )}
    </div>
  );
};

export default Categories;
