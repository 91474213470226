import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { CartState } from '../../context/Context';
import Cookies from 'universal-cookie';
import { setCookie } from '../../SessionManager';


const WishlistProdCard = ({ data, updateList }) => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const token = cookies?.get("auth_token");
  const cart_id = JSON.parse(localStorage?.getItem("cart_id") || "");
  // const {state: { userData }} = CartState();
  const userData = JSON.parse(localStorage?.getItem("userData") || "{}");
  const [removingfromWishlist, setRemovingfromWishlist] = useState(false)
  const [addingToCart, setAddingToCart] = useState(false);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };


  const handleAddtoCart = () => {
    setAddingToCart(true);
    if (token) {
      const payload = {
        cartItem: {
          sku: data?.sku,
          qty: 1,
          quote_id: cart_id,
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/rest/V1/carts/mine/items`,
          payload,
          { headers: headers }
        )
        .then((response) => {
          console.log("response from add to cart: ", response?.data);
          toast.dark(`✅ ${data?.name} is now added to your cart`, {
            closeOnClick: true,
          });
          setAddingToCart(false);
          setCookie("items_in_cart", response?.data?.item_qty);
        })
        .catch((error) => {
          console.log("error from add to cart: ", error);
          setAddingToCart(false);
        });
    } else {
      alert("You're not logged in. Please login to continue.");
      navigate("/login");
    }
  };
  
  const removeFromWishlist = () => {
    setRemovingfromWishlist(true)
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/rest/V1/wishlist/customer/item/${userData?.id}/${data?.product_id}`,
        null,
        { headers }
      )
      .then((response) => {
        console.log("Response : ", response?.data);
        updateList(response?.data)        
        setRemovingfromWishlist(false);
      })
      .catch((err) => {
        console.log("Error while adding item to wishlist: ", err);
        setRemovingfromWishlist(false);
      });
  };

  
  return (
    <div
      key={data?.item_id}
      className="flex flex-col justify-start items-start gap-2 border-2 rounded-md hover:shadow-xl md:w-[250px] w-[180px]"
    >
      <div className="w-full flex flex-col-reverse justify-center items-center relative">
        <img
          src={data?.image}
          alt="prod-img"
          width="100%"
          height="100%"
          className="object-contain md:max-h-[150px] h-[150px] w-full"
        />
        {/* sm:w-[100px] md:w-40 w-[90px] */}
        <div className="w-full flex justify-end items-center px-2 p-1">
          <button className="top-1 right-1" onClick={removeFromWishlist}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className={`size-6  hover:text-red-600 ${
                removingfromWishlist
                  ? "text-red-600 animate-pulse"
                  : "text-gray-700"
              }`}
              width="24"
              height="24"
            >
              <path
                fillRule="evenodd"
                d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="flex flex-col items-start justify-center md:px-3 px-1 py-1 gap-3 w-full">
        <p
          className="md:text-base text-sm font-medium w-full h-[75px] cursor-pointer font-man line-clamp-3"
          onClick={() =>
            navigate(
              `/product/${data?.sku}/${data?.name?.replace(/ /g, "-")}`,
              { state: data }
            )
          }
        >
          {data?.name}
        </p>
        {/* <div className="w-full flex justify-between">
          {data?.price && (
            <p className="font-medium md:text-base text-sm">${data?.price}</p>
          )}
          <p className="flex flex-row gap-1 md:text-base text-sm">
                        ⭐4.3
                    </p>
        </div> */}

        <div className="w-full flex justify-between">
          {
            <button
              disabled={addingToCart}
              className="text-xs md:flex hover:bg-black bg-slate-800 justify-center items-center p-2 text-white cursor-pointer rounded-md"
              onClick={() => {
                handleAddtoCart();
              }}
            >
              <p>
                {addingToCart ? (
                  <div className="loading-container flex justify-center items-center w-[70px] h-[20px]">
                    <svg
                      aria-hidden="true"
                      className={`w-3 h-3 mr-2 text-gray-200 animate-spin  fill-blue-600`}
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : (
                  <span>ADD TO CART</span>
                )}
              </p>
            </button>
          }
        </div>
      </div>
      <div className="font-outfit text-sm ">
        <ToastContainer position="bottom-left" autoClose={4000} type="dark" />
      </div>
    </div>
  );
};

export default WishlistProdCard