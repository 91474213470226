import React, {useState, useEffect} from 'react'
import { createUserCart, getCart } from '../../ReactFunctions'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsFillPatchCheckFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';
import { CartState } from '../../context/Context';
import OAuth from 'oauth-1.0a';
import { HmacSHA256, enc } from 'crypto-js';
import { OrderItemCard } from '../Orders/Orders';
import Cookies from 'universal-cookie'
import {MdOutlineError} from 'react-icons/md'
import usePageDurationTracker from '../../components/Hooks/usePageDurationTracker';

const Success = () => {
    const cookies = new Cookies()
    const navigate = useNavigate()
    const token = cookies?.get("auth_token")
    const { state: { secret, userData }, dispatch } = CartState()
    const [cartData, setCartData] = useState([])
    const [loading, setLoading] = useState(false)
    const [orderId, setOrderId] = useState(null)
    const [data, setData] = useState({})
    const [shippingAddress, setShippingAddress] = useState({})
    const [items, setItems] = useState([])
    const [orderPlaced, setOrderPlaced] = useState(null)
    const [orderFetched, setOrderFetched] = useState(false)
    const shippingInfoSubmitted = localStorage?.getItem("shippingInfoSubmitted")

    const auth_token = cookies?.get("auth_token")

    const order_headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth_token}`
    }

    const resetValues = () => {
        localStorage?.removeItem("cart_id");
        localStorage?.removeItem("payment");
        localStorage.setItem("items_in_cart", 0);
        cookies.set("items_in_cart", 0);
    }
        
    
    
    
    const placeOrder = () => {
        const req_url = `${process.env.REACT_APP_API_URL}/rest/V1/carts/mine/order`;
        const payment_payload = {
            paymentMethod: {
                method: localStorage?.getItem("payment"),
            },
        };
        setLoading(true);
        axios
            .put(req_url, payment_payload, { headers: order_headers })
            .then((response) => {
                console.log("order placed: ", response?.data);
                setOrderId(response?.data);
                setOrderPlaced(true);
                resetValues()
                fetchOrderDetails(response?.data);
                createUserCart(order_headers);
                // console.log("log before fetch Orders");
                
            })
            .catch((err) => {
                console.log("Error while placing the order: ", err?.response?.data);
                setLoading(false);
                setOrderPlaced(false);
                toast.dark(`❗Error while placing order`, { closeOnClick: true });
            });
    };

    const fetchOrderDetails = (orderId) => {
        // setLoading(true)
        const orderUrl = `${process.env.REACT_APP_API_URL}/rest/V1/customer/order/${orderId}`;
        axios({ method: "get", url: orderUrl, headers: order_headers })
            .then((response) => {
                console.log(
                    "response from fetching single order: ",
                    response?.data
                );
                setData(response?.data);
                toast.dark(`✅ Order placed successfully`, {
                  closeOnClick: true,
                });
                setShippingAddress(
                    response?.data?.extension_attributes?.shipping_assignments?.[0]
                        ?.shipping?.address
                );
                const orderedItems = response?.data?.items.filter((item) => {
                    return item.product_type !== "configurable";
                });
                console.log("Ordered items: ", orderedItems);
                setItems(orderedItems);
                setLoading(false);
                // setLoading(false)
            })
            .catch((error) => {
                console.log("error while fetching order: ", error?.response?.data);
                setLoading(false);
                // setLoading(false)
            });
    }

    // useEffect(() => {
    //     if (orderPlaced && secret) {
    //        if(!orderFetched) fetchOrderDetails(orderId)
    //    } 
    // },[secret, orderId])

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!localStorage?.getItem("cart_id")) {
            alert("No active cart found. Please add items to the cart and then continue")
            navigate("/")
        } else {
            if (!orderPlaced && shippingInfoSubmitted) {
                placeOrder();
            }
        }
    }, [])

    usePageDurationTracker("Order Success");

    useEffect(() => {
        console.log(`type of payment:${localStorage?.getItem('payment')} and it's a ${typeof (localStorage?.getItem('payment'))}`)
    }, [])

    // useEffect(() => {
    //     if (secret?.accessToken !== undefined) {
    //         if (orderPlaced) fetchOrderDetails(orderId)
    //     }
    // },[secret, orderPlaced])
   

    return (
      <div className="success-container w-full min-h-[450px] flex flex-col items-center justify-start">
        {loading ? (
          <div className="loading-container p-4 flex justify-center items-center w-full gap-3 min-h-[300px]">
            <svg
              aria-hidden="true"
              className="w-5 h-5 mr-2 text-gray-200 animate-spin  fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <p>Processing your payment...</p>
          </div>
        ) : orderPlaced === true ? (
          <div className="w-full flex font-outfit justify-center items-start my-5">
            <div className="w-3/4 rounded-md flex flex-col items-center justify-startp-4 border gap-5">
              <div className="w-full flex flex-col gap-5 p-4">
                <div className="w-full flex justify-end">
                  {/* <button className="p-2 px-4 rounded-md bg-gray-100 hover:bg-gray-200 delay-400 text-sm font-medium">View Invoice</button> */}
                </div>
                <div className="w-full justify-start items-center flex flex-col gap-2">
                  <p className="">
                    <BsFillPatchCheckFill color="#10B981" size={32} />
                  </p>
                  <p className="text-2xl font-medium">
                    We received your order!
                  </p>
                  <p className="text-base text-gray-600">
                    Your order #{data?.increment_id} will be shipped soon.
                  </p>
                </div>
              </div>
              <div className=" border-t-2 border-b-2 w-11/12 flex justify-between items-start p-3 py-6">
                <div className="gap-5 flex flex-col">
                  <div>
                    <p className="text-gray-400 text-sm font-medium">
                      SHIPPING ADDRESS
                    </p>
                  </div>
                  <div className="leading-relaxed">
                    <p>
                      {data?.customer_firstname + " " + data?.customer_lastname}
                    </p>
                    <p>
                      {data?.shipping_address?.street?.[0]},{" "}
                      {data?.shipping_address?.city},
                      <br /> {data?.shipping_address?.region}{" "}
                      {data?.shipping_address?.postcode},{" "}
                      {data?.shipping_address?.country_id}
                    </p>
                  </div>
                </div>
                <div className="gap-5 flex flex-col">
                  <div>
                    <p className="text-gray-400 text-sm font-medium">
                      PAYMENT INFO
                    </p>
                  </div>
                  <div className="leading-relaxed">
                    {data?.payment?.additional_information?.method_title}
                  </div>
                </div>
              </div>
              <div className="w-11/12 flex flex-col gap-5">
                <div>
                  <p className="text-gray-400 text-sm font-medium">
                    ORDER ITEMS
                  </p>
                </div>
                <div className="flex flex-col gap-4">
                  {items?.map((product, index) => {
                    return <OrderItemCard product={product} key={index} />;
                  })}
                </div>
                <div className="py-5 w-full border-t-2">
                  <div className="flex w-full justify-between items-center font-medium md:text-lg">
                    <p>TOTAL</p>
                    <p>₹{parseFloat(data?.grand_total).toFixed(2)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : orderPlaced === false ? (
          <div className="bg-red-100 border border-red-400 mt-12 text-red-700 p-5 relative w-[300px] md:w-[450px] text-center font-outfit rounded-lg gap-4 justify-center items-center shadow dark:border flex flex-col">
            <p>
              <MdOutlineError size={44} color="red" />
            </p>
            <p>
              <span className="font-semibold">Oops!</span> Error while placing
              order. Please try again.
            </p>
            <button
              className="py-2 px-3 rounded-md text-white bg-gray-800"
              onClick={() => navigate("/cart")}
            >
              Try Again
            </button>
          </div>
        ) : (
          <div className="border border-black mt-12 text-gray-700 p-5 relative w-[300px] md:w-[450px] text-center font-outfit rounded-lg gap-4 justify-center items-center shadow flex flex-col">
            <p>Please follow the checkout steps to place an order</p>
            <button className="bg-black text-white py-2 rounded-md text-sm cursor-pointer px-4 border-[1px] border-black">
              Go Home
            </button>
          </div>
        )}

        {!loading && orderPlaced && (
          <div className="w-3/4 flex justify-between">
            <button
              className="bg-black text-white py-2 rounded-md text-xs cursor-pointer px-3"
              onClick={() => navigate(`/order/${orderId}`)}
            >
              TRACK ORDER
            </button>
            <button
              className="bg-white text-black py-2 rounded-md text-xs cursor-pointer px-3 border-[1px] border-black"
              onClick={() => navigate("/")}
            >
              CONTINUE SHOPPING
            </button>
          </div>
        )}

        <div className="font-outfit text-sm ">
          <ToastContainer position="bottom-left" autoClose={4000} type="dark" />
        </div>
      </div>
    );
}


export default Success