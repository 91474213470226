import React, {useEffect, useState} from 'react'
import { CartState } from '../../context/Context'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom';
import Accordion from '../Accordion/Accordion';


const ProfileInfo = ({userData, updateUserData}) => {
    const cookies = new Cookies();
    const token = cookies?.get("auth_token")
    const navigate = useNavigate();
    // const { dispatch } = CartState();
    // const [userData, setUserData ]= useState(JSON.parse(localStorage?.getItem("userData") || "{}" ))
    const [editPersonal, setEditPersonal] = useState(false)
    const [editEmail, setEditEmail] = useState(false)
    const [formData, setFormData] = useState(userData)
    const [firstname, setFirstname] = useState(userData?.firstname)
    const [lastname, setLastname] = useState(userData?.lastname)
    const [email, setEmail] = useState(userData?.email)
    const [showOtpField, setShowOtpField] = useState(false)
    const [showEmailOtpField, setShowEmailOtpField] = useState(false);
    const [otpReceived, setOtpReceived] = useState("");
    const [userOtpEntered, setUserOtpEntered] = useState('')
    const [emailOtp, setEmailOtp] = useState('')
    const [sendingOtp, setSendingOtp] = useState(false)
    const [updatingInfo, setUpdatingInfo] = useState(false)

    const req_headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }


    const userInfoUpdate = () => {
        console.log('User Info Update ran')
        if (userData?.firstname === firstname && userData?.lastname === lastname) {
            toast.dark(`🚫No action taken. No change in values noted.`)
            console.log('User Info Update ran, if condition')
        } else {
            console.log('User Info Update ran, else condition')
            sendOtp("user");
        }
    }

    const userEmailUpdate = () => {
        if (userData?.email === email) {
            toast.dark(`🚫No action taken. No change in value noted.`)
        } else {
            sendOtp("email");
        }
    }

    const sendOtp = (type) => {
        setSendingOtp(true)
        axios
          .post(`${process.env.REACT_APP_API_URL}/rest/V1/otp/generate`, {
            customerId: userData?.id,
          })
          .then((response) => {
              console.log("response from otp verification: ", response?.data);
              setOtpReceived(response?.data?.otp);
              if (type === "user") {
                  setShowOtpField(true)
              } else if (type === "email") {
                  setShowEmailOtpField(true)
              }
              setSendingOtp(false);
              alert("Email containing an OTP is sent to your registered email address.")
          })
          .catch((err) => {
              console.log("error while sending OTP: ", err);
              setSendingOtp(false);
          });
    }

    const validateUserOtp = () => {
        if (otpReceived === userOtpEntered) {
          handleUpdate();
        } else {
          alert("Invalid OTP entered. Please try again.");
        }
    }

    const handleUpdate = () => {
        setUpdatingInfo(true)
        axios.put(`${process.env.REACT_APP_API_URL}/rest/V1/customers/me`, { customer: { firstname: firstname, lastname: lastname, email: email } }, { headers: req_headers })
        .then((response) => {  
            setEditPersonal(false)
            localStorage.setItem("userData", JSON.stringify(response?.data));
            updateUserData(response?.data);
            toast.dark(`✅ Updated Successfully!`)
            setShowEmailOtpField(false)
            setShowOtpField(false)
            setEditPersonal(false)
            setEditEmail(false);
            setUpdatingInfo(false);
        })
        .catch((error) => {
            console.log("Error received: ", error?.response?.data?.message)    
            setUpdatingInfo(false);
        })
    }


    const faq_list = [
        {
            question: "What happens when I update my email address (or mobile number)?",
            answer: "Your login email id (or mobile number) changes, likewise. You'll receive all your account related communication on your updated email address (or mobile number)."
        },
        {
            question: "When will my account be updated with the new email address (or mobile number)?",
            answer: "It happens as soon as you confirm the verification code sent to your email (or mobile) and save the changes."
        },
        {
            question: "What happens to my existing account when I update my email address (or mobile number)?",
            answer: "Updating your email address (or mobile number) doesn't invalidate your account. Your account remains fully functional. You'll continue seeing your Order history, saved information and personal details."
        }
    ]

    useEffect(() => {
        if (cookies?.get("auth_token") === undefined) {
            alert("Please login to view this page")
            navigate("/login")
        }
    },[])

  return (
    <div className="prf-info-container w-full p-4 gap-6 flex flex-col pb-8">
      <div className="pi-top-container w-full flex flex-col gap-6">
        {/* <!--Personal Info Update--> */}
        <div className="flex gap-8 justify-start items-center">
          <p className="font-semibold">Personal Information</p>
          <button
            className="text-blue-700"
            onClick={() => {
              setEditPersonal(!editPersonal);
              setShowOtpField(false);
              setEditEmail(false);
            }}
          >
            {editPersonal ? "Cancel" : "Edit"}
          </button>
        </div>
        {showOtpField ? (
          <form
            className="flex gap-4 items-center justify-start"
            onSubmit={(e) => {
              e?.preventDefault();
              validateUserOtp();
            }}
          >
            <input
              type="text"
              name="otp_field"
              id="otp_field"
              placeholder="XXXX"
              required
              onChange={(e) => setUserOtpEntered(e?.target.value)}
              className={`${
                editPersonal ? "bg-white" : "bg-gray-100"
              } outline-none`}
            />
            <button
              type="submit"
              disabled={updatingInfo}
              className="h-[50px] bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-sm w-[100px] flex items-center justify-center"
            >
              {updatingInfo ? (
                <span>
                  <svg
                    aria-hidden="true"
                    className={`w-5 h-5 text-gray-200 animate-spin  fill-blue-600`}
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </span>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </form>
        ) : (
          <form
            className="flex gap-4 items-center justify-start"
            onSubmit={(e) => {
              e?.preventDefault();
              userInfoUpdate();
            }}
          >
            <div
              className={`border border-gray-400 p-1 px-2 w-[200px] h-[50px] rounded-sm flex flex-col ${
                editPersonal ? "justify-start" : "justify-center bg-gray-100"
              }`}
            >
              {editPersonal && (
                <label htmlFor="firstname" className="text-xs text-gray-400">
                  First Name
                </label>
              )}
              <input
                type="text"
                name="firstname"
                id="firstname"
                onChange={(e) => setFirstname(e?.target?.value)}
                value={firstname}
                className={`${
                  editPersonal ? "bg-white" : "bg-gray-100"
                } outline-none`}
                disabled={!editPersonal}
              />
            </div>
            <div
              className={`border border-gray-400 p-1 px-2 w-[200px] h-[50px] rounded-sm flex flex-col ${
                editPersonal ? "justify-start" : "justify-center bg-gray-100"
              }`}
            >
              {editPersonal && (
                <label htmlFor="lastname" className="text-xs text-gray-400">
                  Last Name
                </label>
              )}
              <input
                type="text"
                name="lastname"
                id="lastname"
                onChange={(e) => setLastname(e?.target?.value)}
                value={lastname}
                className={`${
                  editPersonal ? "bg-white" : "bg-gray-100"
                } outline-none`}
                disabled={!editPersonal}
              />
            </div>
            {editPersonal && (
              <div className="h-[50px] flex items-end">
                <label htmlFor="submit" className="hidden">
                  Submit
                </label>
                <button
                  id="submit"
                  disabled={sendingOtp}
                  className="h-[50px] bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-sm w-[100px] flex items-center justify-center"
                >
                  {sendingOtp ? (
                    <span>
                      <svg
                        aria-hidden="true"
                        className={`w-5 h-5 text-gray-200 animate-spin  fill-blue-600`}
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </span>
                  ) : (
                    <span>Save</span>
                  )}
                </button>
              </div>
            )}
          </form>
        )}
        {/* <!--Email Address Update--> */}
        <div className="flex gap-8 justify-start items-center">
          <p className="font-semibold">Email Address</p>
          <button
            className="text-blue-700"
            onClick={() => {
              setEditEmail(!editEmail);
              setShowEmailOtpField(false);
              setEditPersonal(false);
            }}
          >
            {editEmail ? "Cancel" : "Edit"}
          </button>
        </div>
        {showEmailOtpField ? (
          <form
            className="flex gap-4 items-center justify-start"
            onSubmit={(e) => {
              e?.preventDefault();
              validateUserOtp();
            }}
          >
            <input
              type="text"
              name="otp_field"
              id="otp_field"
              placeholder="XXXX"
              required
              className={`${
                editEmail ? "bg-white" : "bg-gray-100"
              } outline-none`}
              onChange={(e) => setUserOtpEntered(e?.target.value)}
            />
            <button
              type="submit"
              disabled={updatingInfo}
              className="h-[50px] bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-sm w-[100px] flex items-center justify-center"
            >
              {updatingInfo ? (
                <span>
                  <svg
                    aria-hidden="true"
                    className={`w-5 h-5 text-gray-200 animate-spin  fill-blue-600`}
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </span>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </form>
        ) : (
          <form
            className="flex gap-4 items-center justify-start"
            onSubmit={(e) => {
              e.preventDefault();
              // handleLogin()
            }}
          >
            <div
              className={`border border-gray-400 p-1 px-2 w-[280px] h-[50px] rounded-sm flex flex-col ${
                editEmail ? "justify-start" : "justify-center bg-gray-100"
              }`}
            >
              {editEmail && (
                <label htmlFor="firstname" className="text-xs text-gray-400">
                  Email Address
                </label>
              )}
              <input
                type="text"
                name="firstname"
                id="firstname"
                onChange={(e) => setEmail(e?.target?.value)}
                value={email}
                className={`${
                  editEmail ? "bg-white" : "bg-gray-100"
                } outline-none`}
                disabled={!editEmail}
              />
            </div>
            {editEmail && (
              <div className="h-[50px] flex items-end">
                <label htmlFor="submit" className="hidden">
                  Submit
                </label>
                <button
                  id="submit"
                  disabled={sendingOtp}
                  className="h-[50px] bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-sm w-[100px] flex items-center justify-center"
                  onClick={() => {
                    userEmailUpdate();
                  }}
                >
                  {sendingOtp ? (
                    <span>
                      <svg
                        aria-hidden="true"
                        className={`w-5 h-5 text-gray-200 animate-spin  fill-blue-600`}
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </span>
                  ) : (
                    <span>Save</span>
                  )}
                </button>
              </div>
            )}
          </form>
        )}
      </div>
      <div className="flex flex-col gap-3 justify-center items-start">
        <p className="font-semibold">FAQ</p>
        <div className="w-full flex flex-col justify-start items-start gap-4">
          {<Accordion items={faq_list} />}
        </div>
      </div>
      <div className="font-outfit text-sm ">
        <ToastContainer position="bottom-left" autoClose={4000} type="dark" />
      </div>
    </div>
  );
}

export default ProfileInfo