import axios from 'axios'
import React, { useState, useEffect, useMemo } from 'react'
import Accordion from './FilterAccordian'
import SearchProductCard from './SearchProductCard'
import { BsArrowRightCircle } from 'react-icons/bs'
import { useLocation, useParams } from 'react-router-dom'
import ProductCard from '../../components/ProductCard/ProductCard'
import rudderAnalytics from "../../rudderstack.js";
import Loader from '../../components/Loader/Loader.jsx'
import ProductCardSkeleton from '../../components/ProductCardSkeleton/ProductCardSkeleton.jsx'
import { FilterDropdown } from '../ProductComparision/ProductComparision.jsx'

const SearchResults = () => {
  const location = useLocation();
  const { searchWord } = useParams()
  const [products, setProducts] = useState([])
  const [priceRange, setPriceRange] = useState({ min: 0, max: Infinity });
  const [filteredProducts, setFilteredProducts] = useState([])
  const [searchList, setSearchList] = useState([])
  const [searching, setSearching] = useState(true)
  const [searchResults, setSearchResults] = useState([])
 const [sortBy, setSortBy] = useState("");
  //  const [sortOption, setSortOption] = useState("price-asc");

   const sortOptions = [
     { value: "price-asc", label: "Price: Low to High" },
     { value: "price-desc", label: "Price: High to Low" },
     { value: "name-asc", label: "Name: A to Z" },
     { value: "name-desc", label: "Name: Z to A" },
  ];
  
  

   // Filter products based on price range
  const filterProducts = (products) => {
    let filtered = [...products];

    // Search filter
    // if (searchQuery) {
    //   filtered = filtered.filter(
    //     (product) =>
    //       product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //       (product.description?.toLowerCase() || "").includes(
    //         searchQuery.toLowerCase()
    //       )
    //   );
    // }

    // Apply dynamic filters
    // Object.entries(filters).forEach(([key, value]) => {
    //   filtered = filtered.filter((product) => product[key] === value);
    // });

    // Sorting
    if (sortBy) {
      filtered.sort((a, b) => {
        switch (sortBy) {
          case "price-asc":
            return parseFloat(a.price) - parseFloat(b.price);
          case "price-desc":
            return parseFloat(b.price) - parseFloat(a.price);
          case "name-asc":
            return a.name.localeCompare(b.name);
          case "name-desc":
            return b.name.localeCompare(a.name);
          default:
            return 0;
        }
      });
    }

    return filtered;
  };


    useEffect(() => {
      const result = filterProducts(products);
      setFilteredProducts(result);
    }, [products, sortBy]);
   // Sort products based on selected criteria
  //  const sortedProducts = useMemo(() => {
  //    return filteredProducts.sort((a, b) => {
  //      const [sortBy, order] = sortOption.split("-");
  //      if (sortBy === "price") {
  //        const aPrice = parseFloat(a.price);
  //        const bPrice = parseFloat(b.price);
  //        if (order === "asc") return aPrice - bPrice;
  //        else return bPrice - aPrice;
  //      } else if (sortBy === "name") {
  //        if (order === "asc") return a.name.localeCompare(b.name);
  //        else return b.name.localeCompare(a.name);
  //      }
  //      return 0;
  //    });
  //  }, [filteredProducts, sortOption]);

   const handlePriceRangeChange = (min, max) => {
     setPriceRange({ min, max });
   };

  //  const handleSortOptionChange = (option) => {
  //    setSortOption(option);
  //  };


    useEffect(() => {
      // let listOfProduct = location?.state.filter((current) => current.type_id == 'simple')
      // setProductList(listOfProduct)
        // setSearchList(listOfProduct)
        handleSearch()
    }, [searchWord]);
    const [show, setShow] = useState(false)



    // const priceFilter = (event) => {
    //     console.log("event triggered: ",)
    //     const filteredData = searchResults.filter((curr) =>
    //         curr.price <= event.target.value
    //     )
    //     setSearchResults(filteredData);
    // };

    // const clearFilter = () => {
    //     setSearchResults(productList)
    // }

    const handleSearch = () => {
      setSearching(true);
      rudderAnalytics.track("Product Search", {
        searchKeyWord: searchWord,
      });
      if (searchWord?.length > 1) {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/rest/V1/fuzzysearch?searchQuery=${searchWord}`
          )
          .then((response) => {
              setFilteredProducts(response?.data?.items);
              setProducts(response?.data?.items);
            // console.log(response?.data?.items);

            // navigate(`/search-result/${searchWord}`);
            setSearching(false);
          })
          .catch((error) => {
            console.log("Error while searching..", error);
            setSearching(false);
          });
      }
    };

    return (
      <div className="w-full p-5 md:p-8 font-man flex flex-col gap-3 justify-start items-start">
        <div className="w-full flex flex-col md:flex-row md:justify-between justify-start md:items-center items-start gap-3">
          <div>
            <p className="font-semibold md:text-2xl text-xl mb-4">
              Search Results "{searchWord}"
            </p>
            <p className="">{products?.length} Items</p>
          </div>
          <div>
            <FilterDropdown
              label="Sort By"
              options={sortOptions}
              selectedValue={sortBy}
              onChange={setSortBy}
            />
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row items-start justify-center md:gap-5">
          <div className="w-full flex flex-wrap md:gap-4 gap-3 gap-y-3 md:justify-start justify-center mt-5">
            {searching ? (
              <div className="w-full flex flex-wrap gap-3 justify-start items-start">
                <ProductCardSkeleton />
                <ProductCardSkeleton />
                <ProductCardSkeleton />
                <ProductCardSkeleton />
              </div>
            ) : products?.length !== 0 ? (
              filteredProducts?.map((data, index) => {
                console.log("search card before data: ", data);
                return <ProductCard data={data} key={index} />;
              })
            ) : (
              <p>No Search Results</p>
            )}
          </div>
        </div>
      </div>
    );
}

export default SearchResults;