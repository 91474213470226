import React, { useState } from "react";

const Coupons = () => {

    const [copiedCoupon, setCopiedCoupon] = useState(null);

    const copyToClipboard = (couponCode) => {
      navigator.clipboard.writeText(couponCode);
      setCopiedCoupon(couponCode);
      setTimeout(() => {
        setCopiedCoupon(null);
      }, 3000);
    };

  const coupons = [
    {
      coupon_code: "H20",
      description: "Use promo code H20 at checkout",
      discount_amount: "70.0000",
      discount_type: "percent",
      from_date: null,
      to_date: null,
      uses_per_customer: "1",
      times_used: "0",
      is_active: "1",
    },
    {
      coupon_code: "10PCTOFF",
      description: "10% Off",
      discount_amount: "10.0000",
      discount_type: "percent",
      from_date: "2024-10-24",
      to_date: null,
      uses_per_customer: "0",
      times_used: "0",
      is_active: "1",
    },
  ];

  return (
    <div className="w-full py-12 font-man">
      <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-black mb-8">
          Available Coupons
        </h1>
        <div className="w-full flex flex-wrap justify-start items-start gap-6">
          {coupons.map((coupon) => (
            <div
              key={coupon.coupon_code}
                  className={`bg-gradient-to-br from-purple-600 to-indigo-600 w-[230px] h-[270px] rounded-lg shadow-md overflow-hidden relative ${coupon?.is_active === "1" ? "cursor-pointer" : "cursor-not-allowed"} hover:shadow-lg`}
              onClick={() => copyToClipboard(coupon.coupon_code)}
            >
              <div className="p-4 border-b border-gray-200 relative">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-medium text-gray-100">
                    {coupon.discount_type === "percent"
                      ? `${Number(coupon.discount_amount)}% OFF`
                      : `$${Number(coupon.discount_amount)} OFF`}
                  </h3>
                          <span className="text-sm font-medium text-gray-100">
                              <div class="relative w-9 h-9">
  <svg
    class="absolute inset-0 w-full h-full text-gray-800 dark:text-white animate-spin-slow"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <g transform="translate(12, 12)">
      <path
        fillRule="evenodd"
        d="M20.29 8.567c.133.323.334.613.59.85v.002a3.536 3.536 0 0 1 0 5.166 2.442 2.442 0 0 0-.776 1.868 3.534 3.534 0 0 1-3.651 3.653 2.483 2.483 0 0 0-1.87.776 3.537 3.537 0 0 1-5.164 0 2.44 2.44 0 0 0-1.87-.776 3.533 3.533 0 0 1-3.653-3.654 2.44 2.44 0 0 0-.775-1.868 3.537 3.537 0 0 1 0-5.166 2.44 2.44 0 0 0 .775-1.87 3.55 3.55 0 0 1 1.033-2.62 3.594 3.594 0 0 1 2.62-1.032 2.401 2.401 0 0 0 1.87-.775 3.535 3.535 0 0 1 5.165 0 2.444 2.444 0 0 0 1.869.775 3.532 3.532 0 0 1 3.652 3.652c-.012.35.051.697.184 1.02Z"
        clipRule="evenodd"
        transform="translate(-12, -12)"
      />
    </g>
    <g transform="translate(9, 9)">
      <path
        fillRule="evenodd"
        d="M9.927 7.371a1 1 0 1 0 0 2h.01a1 1 0 0 0 0-2h-.01Zm5.889 2.226a1 1 0 0 0-1.414-1.415L8.184 14.4a1 1 0 0 0 1.414 1.414l6.218-6.217Zm-2.79 5.028a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01Z"
        clipRule="evenodd"
        transform="translate(-9, -9)"
      />
    </g>
  </svg>

  <span
    class="absolute inset-0 flex items-center justify-center text-gray-800 font-bold"
  >
    %
  </span>
</div>
                    

                  </span>
                </div>
              </div>
              <div className="px-6 py-4 relative">
                <p className="text-sm text-gray-100">Coupon Code:</p>
                <p className="text-lg font-bold text-white">
                  {coupon.coupon_code}
                </p>
                {/* <p className="text-sm text-gray-500 mt-2">
                  {coupon.description}
                </p> */}
                {/* <p className="text-sm text-gray-500 mt-2">
                  Uses per customer: {coupon.uses_per_customer}
                </p> */}
                <p className="text-sm text-gray-100 mt-2">
                  Times used: {coupon.times_used}
                </p>
                <p className="text-sm text-gray-100 mt-2">
                  Status: {coupon.is_active === "1" ? "Active" : "Inactive"}
                </p>
              </div>
              {/* <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent to-gray-100 transform -rotate-3 origin-top-left"></div>
              <div className="absolute top-0 right-0 w-full h-full bg-gradient-to-l from-transparent to-gray-100 transform rotate-3 origin-top-right"></div> */}
              {copiedCoupon === coupon.coupon_code && (
                <div className="absolute bottom-0 left-0 w-full px-4 py-2 bg-green-500 text-white text-sm rounded-b-lg">
                  Coupon code is now copied to your clipboard
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Coupons;
