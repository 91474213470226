import React, {useEffect, useState} from 'react'
import { FaPowerOff, FaUserCircle } from 'react-icons/fa'
import {RiFolderUserFill} from 'react-icons/ri'
import ProfileInfo from '../../components/ProfileInfo/ProfileInfo'
import ManageAddress from '../../components/ManageAddress/ManageAddress'
import { CartState } from '../../context/Context'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import usePageDurationTracker from '../../components/Hooks/usePageDurationTracker'

const Account = () => {
  const navigate = useNavigate()
  const [component, setComponent] = useState("profile")
  const [userData, setUserData] = useState(JSON.parse(localStorage?.getItem("userData") || "{}"))
  const {dispatch } = CartState();
  const cookies = new Cookies()
  
  usePageDurationTracker("Manage Account");

  useEffect(() => {
    window.scroll(0,0)
  },[userData])

  return (
    <section className='w-full flex gap-4 p-4 font-man'>
        <div className='nav-left-div flex flex-col gap-3 justify-start min-w-[120px] lg:w-[25%] md:w-[30%]'>
            <div className='flex gap-3 items-center p-3 bg-white shadow-md w-full justify-start top-container rounded-sm'>
                  <div>
                      <img src="https://static.vecteezy.com/system/resources/previews/020/765/399/non_2x/default-profile-account-unknown-icon-black-silhouette-free-vector.jpg" alt="profile-pic" height="100%" width="100%" className='w-[65px] h-[65px] bg-black object-contain rounded-[100%] border-2 border-black' />
                </div>
                  <div>
                    <p className='text-sm'>Hello,</p>
                      <p className='text-base font-medium'>{userData?.firstname + " " + userData?.lastname}</p>
                  </div>  
           </div>
            <div className='below-container flex flex-col gap-3 items-center p-3 bg-white shadow-md w-full justify-center rounded-sm'>
                <div className='flex flex-col w-full justify-start gap-1 items-end border-b-2 py-1 px-2'>
                    <div className='flex items-center w-full justify-start gap-3 py-3'>
                        <FaUserCircle size={28} />
                        <p className='section-title font-bold w-full text-gray-700'>ACCOUNT SETTINGS</p>
                    </div>
                    <div className='w-full flex flex-col gap-1'>
                        <p className='w-full flex text-left pl-10 py-2 cursor-pointer hover:bg-gray-200 rounded-md font-medium' onClick={()=>setComponent("profile")}>Profile Information</p>
                        <p className='w-full flex text-left pl-10 py-2 cursor-pointer hover:bg-gray-200 rounded-md font-medium' onClick={()=>setComponent("addresses")}>Manage Addresses</p>
                    </div>
                </div>
                <div className='flex flex-col w-full justify-start gap-1 items-end border-b-2 py-1 px-2'>
                  <div className='flex items-center w-full justify-start gap-3 py-1'>
                        <RiFolderUserFill size={28} />
                        <p className='section-title font-bold w-full text-gray-700'>MY STUFF</p>
                    </div>
                  <div className='w-full flex flex-col gap-2'>
                      <p className='w-full flex text-left pl-10 py-2 cursor-pointer hover:bg-gray-200 rounded-md font-medium' onClick={()=>navigate("/orders")}>Orders</p>
                      <p className='w-full flex text-left pl-10 py-2 cursor-pointer hover:bg-gray-200 rounded-md font-medium' onClick={()=>navigate("/wishlist")}>Wishlist</p>
                      {/* <p className='w-full flex text-left pl-10 py-2 cursor-pointer hover:bg-gray-200 rounded-md font-medium' onClick={()=>navigate("/coupons")}>Coupons</p> */}
                  </div>
                  </div>
                  <div className='flex items-center w-full justify-start gap-3 py-2 cursor-pointer hover:bg-gray-200 rounded-md px-2' onClick={() => {
                    cookies.remove("auth_token")
                    dispatch({
                        type: "UPDATE_USER_DATA",
                        payload: {
                            data: {}
                        }
                    })
                    navigate("/login")
                  }}>
                      <FaPowerOff size={27} />
                      <p className='font-bold w-full text-gray-700 '>SIGN OUT</p>
                  </div>
            </div>   
        </div>
          <div className='content-right-div md:min-w-[400px] sm:min-w-[200px] lg:w-[75%] md:w-[70%] shadow-md'>
              <div className="actual-comp-container">
                  {component === "profile" && <ProfileInfo userData={userData} updateUserData={setUserData}/>}
                  {component === "addresses" && <ManageAddress />}
            </div>
          </div>  
    </section>
  )
}

export default Account