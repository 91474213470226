import React, { useEffect, useRef } from "react";
import axios from "axios";
import { useState } from "react";
import { BsFillBagFill, BsSearch } from "react-icons/bs";
import { HiUserCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { CartState } from "../../context/Context.js";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import Cookies from "universal-cookie";
// import rudderanalytics from "../../rudderstack.js";
import rudderAnalytics from "../../rudderstack.js";
import Logo from '../../assets/images/ScketchLogo.png'
import { convertToUrlFormat, getCookie } from "../../ReactFunctions.js";
import {deleteCookie, cleanupLocalStorage, useSessionManager} from '../../SessionManager.js'
import MobileMenu from "./MobileMenu.jsx";
import HeaderIcons from "../HeaderIcons/HeaderIcons.jsx";


const Navbar = () => {
  
const cookies = new Cookies();
  const { dispatch } = CartState();
  const userData = JSON.parse(localStorage?.getItem("userData") || "{}");
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(null);
  const [subMenu, setSubMenu] = useState(null);
  const items_in_cart = cookies?.get("items_in_cart");
  const [cartItemsCount, setCartItemsCount] = useState(getCookie("items_in_cart") ?? 0);
  const [categories, setCategories] = useState([]);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isvisible, setVisible] = useState(false);
  const componentRef = useRef(null);
  const [showCategories, setShowCategories] = useState(false);
  const auth_token = cookies?.get("auth_token");
  const { setToken } = useSessionManager(null);

  const req_headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${auth_token}`,
  };

  const [searching, setSearching] = useState(false)


  const fetchCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/rest/all/V1/categories`)
      .then((res) => {
        console.log("categories", res);
        setCategories(res.data.children_data[0].children_data);
      })
      .catch((err) => {
        console.log("Error fetching categories: ", err);
      });
  };

  const handleCategoryClick = (id, name) => {
    navigate(`/categories/${id}/${convertToUrlFormat(name)}`);
  };

  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [hoveredSubCategory, setHoveredSubCategory] = useState(null);
  const [hoveredNestedCategory, setHoveredNestedCategory] = useState(null);

  const handleMouseEnter = (category) => {
    setHoveredCategory(category.id);
    setHoveredSubCategory(null);
    setHoveredNestedCategory(null);
  };

  const handleMouseLeave = () => {
    setHoveredCategory(null);
    setHoveredSubCategory(null);
    setHoveredNestedCategory(null);
  };

  const handleSubMouseEnter = (subCategory) => {
    setHoveredSubCategory(subCategory.id);
    setHoveredNestedCategory(null);
  };

  const handleSubMouseLeave = () => {
    setHoveredSubCategory(null);
    setHoveredNestedCategory(null);
  };

  const handleNestedMouseEnter = (nestedCategory) => {
    setHoveredNestedCategory(nestedCategory.id);
  };

  const handleNestedMouseLeave = () => {
    setHoveredNestedCategory(null);
  };

  const renderCategories = (categories) => {
    return (
      <ul className="flex w-full md:flex-row flex-col items-start md:items-center justify-start md:justify-center p-4 gap-4 text-sm font-man md:flex-wrap">
        {categories.map((category) => (
          <li
            key={category.id}
            className="relative group"
            onMouseEnter={() => handleMouseEnter(category)}
            onMouseLeave={handleMouseLeave}
          >
            <p
              onClick={() => handleCategoryClick(category.id, category.name)}
              className="min-w-fit cursor-pointer items-center flex gap-1 md:border-none text-white md:text-black hover:text-orange-400 font-semibold px-4 py-2"
            >
              <span className="min-w-fit text-nowrap">{category.name}</span>
              <span className="min-w-fit">
                {category.children_data &&
                  category.children_data.length > 0 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6 w-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  )}
              </span>
            </p>
            {category.children_data && category.children_data.length > 0 && (
              <ul
                className={`absolute left-0 mt-0 bg-white border border-gray-200 rounded shadow-lg transition-all duration-300 ${
                  hoveredCategory === category.id ? "block" : "hidden"
                }`}
              >
                {renderSubCategories(category.children_data)}
              </ul>
            )}
          </li>
        ))}
      </ul>
    );
  };

  const renderSubCategories = (subCategories) => {
    return (
      <ul>
        {subCategories.map((subCategory) => (
          <li
            key={subCategory.id}
            className="relative group z-50"
            onMouseEnter={() => handleSubMouseEnter(subCategory)}
            onMouseLeave={handleSubMouseLeave}
          >
            <p
              onClick={() =>
                handleCategoryClick(subCategory.id, subCategory.name)
              }
              className="cursor-pointer z-50  hover:text-orange-400  border-b bg-white font-semibold px-4 py-2 whitespace-nowrap"
            >
              <div className="flex flex-row  items-center justify-between gap-x-1">
                <div>{subCategory.name}</div>
                <div>
                  {subCategory.children_data &&
                    subCategory.children_data.length > 0 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m19.5 8.25-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    )}
                </div>
              </div>
            </p>
            {subCategory.children_data &&
              subCategory.children_data.length > 0 && (
                <ul
                  className={`absolute left-full top-0 bg-white border border-gray-200 rounded shadow-lg transition-all duration-300 ${
                    hoveredSubCategory === subCategory.id ? "block" : "hidden"
                  }`}
                >
                  {renderNestedSubCategories(subCategory.children_data)}
                </ul>
              )}
          </li>
        ))}
      </ul>
    );
  };

  const renderNestedSubCategories = (nestedSubCategories) => {
    return (
      <ul>
        {nestedSubCategories.map((nestedSubCategory) => (
          <li
            key={nestedSubCategory.id}
            className="relative group z-50"
            onMouseEnter={() => handleNestedMouseEnter(nestedSubCategory)}
            onMouseLeave={handleNestedMouseLeave}
          >
            <p
              onClick={() =>
                handleCategoryClick(
                  nestedSubCategory.id,
                  nestedSubCategory.name
                )
              }
              className="cursor-pointer  hover:text-orange-400 z-50 border-b bg-white font-semibold px-4 py-2 whitespace-nowrap"
            >
              <div className="flex flex-row items-center gap-x-1">
                <div>{nestedSubCategory.name}</div>
                <div>
                  {nestedSubCategory.children_data &&
                    nestedSubCategory.children_data.length > 0 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m19.5 8.25-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    )}
                </div>
              </div>
            </p>
            {nestedSubCategory.children_data &&
              nestedSubCategory.children_data.length > 0 && (
                <ul
                  className={`absolute left-full z-50 top-0 bg-white border border-gray-200 rounded shadow-lg transition-all duration-300 ${
                    hoveredNestedCategory === nestedSubCategory.id
                      ? "block"
                      : "hidden"
                  }`}
                >
                  {renderNestedSubCategories(nestedSubCategory.children_data)}
                </ul>
              )}
          </li>
        ))}
      </ul>
    );
  };

  const handleSearch = () => {
    setSearching(true);
    rudderAnalytics.track("Product Search", {
      searchKeyWord: searchWord,
    });
    if (searchWord?.length > 1) {

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/rest/V1/fuzzysearch?searchQuery=${searchWord}`
        )
        .then((response) => {
          setSearchResults(response?.data?.items);
          console.log(response?.data?.items);

          navigate(`/search-result/${searchWord}`)
          setSearching(false);
          setSearchWord("");
        })
        .catch((error) => {
          console.log("Error while searching..", error);
          setSearching(false);
        });
    }
  };

  const handleSignOut = () => {
    setShowUserMenu(!showUserMenu);
    deleteCookie("auth_token");
    setToken(null)
    deleteCookie("items_in_cart");
    cleanupLocalStorage()
    setCartItemsCount(0);
    dispatch({
      type: "UPDATE_USER_DATA",
      payload: {
        data: {},
      },
    });
    navigate("/login");
  }

  // const getItemsInCart = () => {
  //     console.log('cookies in navbar ran')
  //     return parseInt(cookies.get('items_in_cart')) || 0;
  // }

  // const fetchUserData = () => {
  //   const url = `${process.env.REACT_APP_API_URL}/rest/V1/customers/me`;
  //   axios
  //     .get(url, { headers: req_headers })
  //     .then((response) => {
  //       dispatch({
  //         type: "UPDATE_USER_DATA",
  //         payload: {
  //           data: response?.data,
  //         },
  //       });
  //       console.log("Fetched user data: ", response?.data);
  //     })
  //     .catch((err) => {
  //       console.log(
  //         "error from fetching user Data: ",
  //         err?.response?.data?.message
  //       );
  //     });
  // };


  
  useEffect(() => {
    setCartItemsCount(items_in_cart);
  }, [items_in_cart]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        // Clicked outside the component, perform the desired action (e.g., close the component)
        setSearchResults([]);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

    useEffect(() => {
      setActiveMenu(null);
      setSubMenu(null);
    }, [showCategories]);

  // useEffect(() => {
  //   if (!userData?.firstname) {
  //     fetchUserData();
  //   }
  // }, []);

  useEffect(() => {
    fetchCategories();
  }, []);
  const onclose = () => {
    setVisible(!isvisible);
  };
  return (
    <div className="w-full flex relative font-pop">
      <div className="flex-col hidden md:flex w-full ">
        <div className="flex justify-between px-10 py-5 items-center">
          <img
            src={Logo}
            alt="website-logo"
            height="100%"
            width="100%"
            className="h-[30px] w-[120px] object-contain cursor-pointer"
            onClick={() => navigate("/")}
          />
          <div className="flex flex-col px-2 py-1 w-1/2 items-center gap-2 rounded-sm relative font-man">
            <div className="flex px-2 py-1 overflow-hidden w-full items-center gap-2 border justify-between border-slate-600 rounded-md">
              <div className="flex items-center justify-start w-4/5">
                <BsSearch color="#141414" size={20} />
                <input
                  type="text"
                  value={searchWord}
                  onChange={(e) => setSearchWord(e?.target?.value)}
                  onKeyDown={(e) => e?.key === "Enter" && handleSearch()}
                  className=" border-none  w-4/5 px-2 outline-none py-2 text-sm md:text-base	"
                  placeholder="Search for products or brands..."
                />
              </div>
              <button
                onClick={() => handleSearch()}
                className="bg-gray-700 hover:bg-gray-900 text-white text-sm font-medium px-3 py-1 cursor-pointer outline-none rounded-sm"
              >
                Search
              </button>
            </div>
            {/* <div className="bg-white w-full top-10 z-10 mt-4 absolute rounded-md shadow-xl scrollbar-hide overflow-hidden overflow-y-scroll max-h-[360px] p-1" ref={componentRef}>
                            {searchResults?.map((result) => {
                                return (
                                    <div key={result?.sku} className="cursor-pointer py-2 px-3 flex gap-3 items-center hover:bg-slate-100">
                                        <img src={`http://65.0.150.104/media/catalog/product` + result?.custom_attributes?.[2]?.value} alt={result?.name} className="w-[40px] h-[40px] rounded-full" height="100%" width="100%" />
                                        <p>{result?.name}</p>
                                    </div>
                                )
                            })}
                        </div> */}
          </div>
          <HeaderIcons
            items_count={cartItemsCount}
            navigate={navigate}
            userData={userData}
            auth_token={auth_token}
            handleSignOut={handleSignOut}
          />
        </div>
        <nav>{renderCategories(categories)}</nav>
      </div>
      <nav className="bg-gray-800 md:hidden w-full sticky z-10">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div className=" inset-y-0 gap-2 left-0 flex items-center md:hidden">
              {/* <!-- Mobile menu button--> */}
              <button
                type="button"
                onClick={() => setShowCategories(!showCategories)}
                className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400"
              >
                <span className="sr-only">Open main menu</span>
                {showCategories ? (
                  <svg
                    className="w-10 h-10 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18 17.94 6M18 18 6.06 6"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-10 h-10 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeWidth="2"
                      d="M5 7h14M5 12h14M5 17h10"
                    />
                  </svg>
                )}
              </button>
              <div
                className="flex flex-shrink-0 items-center"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  className="h-8 w-auto"
                  src="https://flowbite.com/docs/images/logo.svg"
                  alt="Your Company"
                  height="100%"
                  width="100%"
                />
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 gap-x-3 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <button
                type="button"
                className="relative rounded-full bg-gray-800 p-1 text-gray-400"
                onClick={() => setVisible(!isvisible)}
              >
                <span className="absolute -inset-1.5"></span>
                <span className="sr-only">Search</span>
                <BsSearch
                  className="text-gray-300 hover:text-black cursor-pointer"
                  size={22}
                />
              </button>
              {isvisible ? (
                <SearchModel
                  onclose={onclose}
                  handleSearch={handleSearch}
                  searchWord={searchWord}
                  setSearchWord={setSearchWord}
                  searchResults={searchResults}
                />
              ) : (
                ""
              )}
              <button
                type="button"
                onClick={() => navigate("/cart")}
                className="relative rounded-full bg-gray-800 p-1 text-gray-400"
              >
                <span className="absolute -inset-1.5"></span>
                <span className="sr-only">View Cart</span>
                <BsFillBagFill
                  className="text-gray-300 hover:text-black cursor-pointer"
                  size={22}
                  onClick={() => navigate("/cart")}
                />
                {cartItemsCount > 0 && (
                  <span className="absolute -mt-6 ml-1 rounded-[0.37rem] bg-red-600 px-[0.45em] py-[0.2em] text-[0.6rem] leading-none text-white">
                    {cartItemsCount}
                  </span>
                )}
              </button>
              <MdOutlineFavoriteBorder
                className="text-gray-300  cursor-pointer"
                size={22}
                onClick={() => {
                  navigate("/wishlist");
                }}
              />
              {/* <!-- Profile dropdow    n --> */}
              <div className="relative">
                <div>
                  <button
                    type="button"
                    onClick={() => setShowUserMenu(!showUserMenu)}
                    className="relative flex rounded-full bg-gray-800 text-sm"
                  >
                    <span className="absolute -inset-1.5"></span>
                    <span className="sr-only">Open user menu</span>
                    <HiUserCircle
                      className="text-gray-300 hover:text-black cursor-pointer"
                      size={27}
                    />
                  </button>
                </div>

                {/* <!--
                        Dropdown menu, show/hide based on menu state.

                        Entering: "transition ease-out duration-100"
                        From: "transform opacity-0 scale-95"
                        To: "transform opacity-100 scale-100"
                        Leaving: "transition ease-in duration-75"
                        From: "transform opacity-100 scale-100"
                        To: "transform opacity-0 scale-95"
                    --> */}
                {showUserMenu &&
                  (auth_token ? (
                    <div
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                      tabIndex="-1"
                    >
                      {/* <!-- Active: "bg-gray-100", Not Active: "" --> */}
                      <p
                        onClick={() => navigate("/account")}
                        className="block px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-0"
                      >
                        My Account
                      </p>
                      <p
                        onClick={() => navigate("/orders")}
                        className="block px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-2"
                      >
                        Orders
                      </p>
                      <p
                        onClick={() => {
                          cookies.remove("auth_token");
                          setShowUserMenu(!showUserMenu);
                          dispatch({
                            type: "UPDATE_USER_DATA",
                            payload: {
                              data: {},
                            },
                          });
                          navigate("/login");
                        }}
                        className="block px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-2"
                      >
                        Sign out
                      </p>
                    </div>
                  ) : (
                    <div
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                      tabIndex="-1"
                    >
                      <p
                        onClick={() => {
                          setShowUserMenu(!showUserMenu);
                          navigate("/login");
                        }}
                        className="block px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-2"
                      >
                        Login
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Mobile menu, show/hide based on menu state. --> */}
        <MobileMenu
          categories={categories}
          showMobileMenu={showCategories}
          setShowMobileMenu={setShowCategories}
        />
      </nav>
    </div>
  );
};

const SearchModel = ({
  onclose,
  handleSearch,
  searchWord,
  setSearchWord,
  searchResults,
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-md flex justify-center items-start py-20">
      <div className="w-[95%] min-h-[300px] rounded-md flex flex-col gap-4 px-5 bg-white p-2">
        <button
          className="text-white text-lg place-self-end w-[35px] h-[35px] rounded-md bg-gray-700 font-medium"
          onClick={() => onclose()}
        >
          X
        </button>
        <div className="flex px-2 py-1 overflow-hidden bg-white w-full items-center gap-2 border justify-between border-slate-600 rounded-md mt-5">
          <div className="flex items-center justify-start w-4/5">
            <BsSearch color="#141414" size={20} />
            <input
              type="text"
              className=" border-none  w-4/5 px-2 outline-none py-2 text-sm md:text-base"
              placeholder="Search for products or brands..."
              value={searchWord}
              onChange={(e) => setSearchWord(e?.target?.value)}
              onKeyDown={(e) => e?.key === "Enter" && handleSearch()}
            />
          </div>
          <button
            onClick={() => {
              handleSearch();
              onclose();
            }}
            className="bg-gray-700 hover:bg-gray-900 text-white text-sm font-medium px-3 py-1 cursor-pointer outline-none rounded-sm"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};
export default Navbar;


// {
//   showCategories && (
//     // <div className="md:hidden transition-all duration-300 h-screen overflow-y-scroll" id="mobile-menu">
//     //   <div className="space-y-1 px-2 pb-3 pt-2">
//     //     <nav className="">{renderCategories(categories)}</nav>
//     //     {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
//     //     {/* {
//     //                   categories?.length !== 0 &&
//     //                   categories.map((data, index) => {
//     //                       return (
//     //                           <p key={data?.id} className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium" onClick={() => navigate(`/${url_words[index]}`)}>{data?.name}</p>
//     //                       )
//     //                   })
//     //               } */}
//     //     {/* <a href="#" className="bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium" aria-current="page">Dashboard</a>
//     //       <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Team</a>
//     //       <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Projects</a>
//     //       <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Calendar</a> */}
//     //   </div>
//     // </div>
//     <div className="flex lg:gap-5 items-center justify-between">
//       <div
//         className={`${
//           showCategories
//             ? "left-0 w-full sm:w-[40%] md:w-[50%] px-10 py-5 z-10"
//             : "-left-full w-0"
//         } overflow-hidden shadow-md font-medium text-[15px] duration-500 absolute top-full font-montserrat bg-LightBlue h-screen`}
//       >
//         {/* {!activeMenu &&   */}
//         <div>
//           <ul className="space-y-5">
//             {categories?.map((category) => (
//               <li
//                 key={category.id}
//                 className="flex items-center justify-between"
//               >
//                 <p
//                   className="p-1"
//                   onClick={() => {
//                     showCategories(false);
//                     navigate(category.path);
//                   }}
//                 >
//                   {category.name}
//                 </p>
//                 {category?.children_data?.length > 0 && (
//                   <button
//                     className="p-1 flex justify-between items-center"
//                     onClick={() => setActiveMenu(category)}
//                   >
//                     <svg
//                       className="w-6 h-6 ml-auto"
//                       aria-hidden="true"
//                       xmlns="http://www.w3.org/2000/svg"
//                       width="24"
//                       height="24"
//                       fill="none"
//                       viewBox="0 0 24 24"
//                     >
//                       <path
//                         stroke="currentColor"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         strokeWidth="2"
//                         d="M19 12H5m14 0-4 4m4-4-4-4"
//                       />
//                     </svg>
//                   </button>
//                 )}
//               </li>
//             ))}
//           </ul>
//         </div>
//         {/* }   */}
//         {/* Clicked Menu Item */}
//         {/* {activeMenu && */}
//         <div
//           className={` ${
//             activeMenu ? "left-0" : "-left-full"
//           } w-[99%] font-medium overflow-y-auto text-[15px] px-10 py-5 duration-500 absolute top-0 font-montserrat bg-LightBlue h-screen`}
//         >
//           <div className="flex items-center gap-5">
//             <button
//               onClick={() => {
//                 setActiveMenu(null);
//                 setSubMenu(null);
//               }}
//               className="flex items-center text-primary font-semibold"
//             >
//               <svg
//                 className="w-6 h-6"
//                 aria-hidden="true"
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="24"
//                 height="24"
//                 fill="none"
//                 viewBox="0 0 24 24"
//               >
//                 <path
//                   stroke="currentColor"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M5 12h14M5 12l4-4m-4 4 4 4"
//                 />
//               </svg>
//             </button>
//             <p
//               className="font-semibold text-secondary"
//               onClick={() => navigate(activeMenu?.path)}
//             >
//               {activeMenu?.name}
//             </p>
//           </div>
//           <ul className="py-5 space-y-5 h-full overflow-y-auto">
//             {activeMenu?.children_data?.map((subcategory) => (
//               <li key={subcategory.id}>
//                 <div className="flex justify-between items-center gap-1">
//                   <p
//                     onClick={() => {
//                       setShowCategories(false);
//                       navigate(subcategory?.path);
//                     }}
//                     className={`${
//                       subMenu?.name === subcategory.name
//                         ? "text-primary font-semibold"
//                         : "rotate-0 text-black hover:text-secondary font-medium"
//                     } py-1`}
//                   >
//                     {subcategory?.name}
//                   </p>
//                   {subcategory?.children_data?.length > 0 && (
//                     <button
//                       onClick={() => setSubMenu(subcategory?.children_data)}
//                       className="flex items-center p-1"
//                     >
//                       <svg
//                         className={`${
//                           subMenu?.name === subcategory.name
//                             ? "rotate-180 text-primary"
//                             : "rotate-270 text-black"
//                         } duration-300 w-5 h-5 dark:text-white`}
//                         aria-hidden="true"
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="24"
//                         height="24"
//                         fill="none"
//                         viewBox="0 0 24 24"
//                       >
//                         <path
//                           stroke="currentColor"
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                           strokeWidth="2"
//                           d="m19 9-7 7-7-7"
//                         />
//                       </svg>
//                     </button>
//                   )}
//                 </div>
//                 {subMenu?.name === subcategory.name && (
//                   <ul className={`ml-5`}>
//                     {subMenu?.children_data?.map((lastChild) => (
//                       <li
//                         key={lastChild.name}
//                         className="py-2.5 hover:text-secondary "
//                       >
//                         <p
//                           onClick={() => {
//                             setShowCategories(false);
//                             navigate(lastChild?.path);
//                           }}
//                         >
//                           {lastChild.name}
//                         </p>
//                       </li>
//                     ))}
//                   </ul>
//                 )}
//               </li>
//             ))}
//           </ul>
//         </div>
//         {/* }  */}
//       </div>
//     </div>
//   );
// }