// // import React, { useState, useEffect } from "react";

// // const loadScript = (src) => {
// //   return new Promise((resolve, reject) => {
// //     const script = document.createElement("script");
// //     script.src = src;
// //     script.onload = () => resolve();
// //     script.onerror = () => reject();
// //     document.body.appendChild(script);
// //   });
// // };

// // const PayUPaymentComponent = () => {
// //   const [paymentData, setPaymentData] = useState({
// //     amount: 0,
// //     items: ["shirt", "pant"],
// //     customer: {
// //       name: "Srikanth Venagurthy",
// //       email: "srikanth.venagurthy@altumindglobal.com",
// //       phone: "8686712087",
// //     },
// //     paymentMethod: "upi",
// //   });

// //   useEffect(() => {
// //     // Load the PayU SDK script
// //     loadScript(
// //       "https://checkout-static.payu.in/checkout/merchant/v2/merchant.js")
// //       .then(() => {
// //         console.log("PayU SDK loaded");
// //       })
// //       .catch((error) => {
// //         console.error("Error loading PayU SDK:", error);
// //       });
// //   }, []);

// //   const handlePayment = () => {
// //     const options = {
// //       key: "JPM7Fg",
// //       amount: paymentData.amount * 100, // Convert to paisa
// //       currency: "INR",
// //       name: "Scketch ECommerce",
// //       description: "Payment for items",
// //       image: "https://example.com/your_logo.png",
// //       order_id: "12676",
// //       handler: (response) => {
// //         // Handle the payment response
// //         console.log(response);
// //       },
// //       prefill: {
// //         name: paymentData.customer.name,
// //         email: paymentData.customer.email,
// //         contact: paymentData.customer.phone,
// //       },
// //       notes: {
// //         address: "Razorpay Corporate Office",
// //       },
// //       theme: {
// //         color: "#3399cc",
// //       },
// //       method: paymentData.paymentMethod,
// //     };

// //     // Initialize the PayU checkout
// //     const payuCheckout = new window.Payu.Checkout(options);
// //     payuCheckout.open();
// //   };

// //   const handleInputChange = (event) => {
// //     const { name, value } = event.target;
// //     setPaymentData((prevData) => ({
// //       ...prevData,
// //       [name]: value,
// //     }));
// //   };

// //   const handlePaymentMethodChange = (event) => {
// //     setPaymentData((prevData) => ({
// //       ...prevData,
// //       paymentMethod: event.target.value,
// //     }));
// //   };

// //   return (
// //     <div>
// //       <h2>Pay with PayU</h2>
// //       <form>
// //         <div>
// //           <label htmlFor="amount">Amount:</label>
// //           <input
// //             type="number"
// //             id="amount"
// //             name="amount"
// //             value={paymentData.amount}
// //             onChange={handleInputChange}
// //           />
// //         </div>
// //         <div>
// //           <label htmlFor="items">Items:</label>
// //           <textarea
// //             id="items"
// //             name="items"
// //             value={paymentData.items.join(", ")}
// //             onChange={(event) =>
// //               setPaymentData((prevData) => ({
// //                 ...prevData,
// //                 items: event.target.value.split(", "),
// //               }))
// //             }
// //           />
// //         </div>
// //         <div>
// //           <label htmlFor="name">Name:</label>
// //           <input
// //             type="text"
// //             id="name"
// //             name="name"
// //             value={paymentData.customer.name}
// //             onChange={(event) =>
// //               setPaymentData((prevData) => ({
// //                 ...prevData,
// //                 customer: { ...prevData.customer, name: event.target.value },
// //               }))
// //             }
// //           />
// //         </div>
// //         <div>
// //           <label htmlFor="email">Email:</label>
// //           <input
// //             type="email"
// //             id="email"
// //             name="email"
// //             value={paymentData.customer.email}
// //             onChange={(event) =>
// //               setPaymentData((prevData) => ({
// //                 ...prevData,
// //                 customer: { ...prevData.customer, email: event.target.value },
// //               }))
// //             }
// //           />
// //         </div>
// //         <div>
// //           <label htmlFor="phone">Phone:</label>
// //           <input
// //             type="tel"
// //             id="phone"
// //             name="phone"
// //             value={paymentData.customer.phone}
// //             onChange={(event) =>
// //               setPaymentData((prevData) => ({
// //                 ...prevData,
// //                 customer: { ...prevData.customer, phone: event.target.value },
// //               }))
// //             }
// //           />
// //         </div>
// //         <div>
// //           <label htmlFor="paymentMethod">Payment Method:</label>
// //           <select
// //             id="paymentMethod"
// //             name="paymentMethod"
// //             value={paymentData.paymentMethod}
// //             onChange={handlePaymentMethodChange}
// //           >
// //             <option value="upi">UPI</option>
// //             <option value="card">Card</option>
// //             <option value="netbanking">Net Banking</option>
// //           </select>
// //         </div>
// //         <button type="button" onClick={handlePayment}>
// //           Pay with PayU
// //         </button>
// //       </form>
// //     </div>
// //   );
// // };

// // export default PayUPaymentComponent;


// import React, { useEffect } from "react";

// const PaymentComponent = () => {
//   const paymentData = {
//     amount: 100,
//     customer: {
//       name: "John Doe",
//       email: "john@example.com",
//       phone: "1234567890",
//     },
//     paymentMethod: "card",
//     };
    
//     const handlePayment = () => {
//       const options = {
//         key: "JPM7Fg",
//         amount: paymentData.amount * 100, // Convert to paisa
//         currency: "INR",
//         name: "Scketch ECommerce",
//         description: "Payment for items",
//         image: "https://example.com/your_logo.png",
//         order_id: "12676",
//         handler: (response) => {
//           // Handle the payment response
//           console.log(response);
//         },
//         prefill: {
//           name: paymentData.customer.name,
//           email: paymentData.customer.email,
//           contact: paymentData.customer.phone,
//         },
//         notes: {
//           address: "Razorpay Corporate Office",
//         },
//         theme: {
//           color: "#3399cc",
//         },
//         method: paymentData.paymentMethod,
//       };

//       // Initialize the PayU checkout
//       const payuCheckout = new window.Razorpay.Checkout(options);
//       payuCheckout.open();
//     };

//   useEffect(() => {
    

//     // Load the Razorpay library
//     const script = document.createElement("script");
//     script.src = "https://checkout.razorpay.com/v1/checkout.js";
//     script.onload = handlePayment;
//     document.body.appendChild(script);

//     // Clean up the script tag on component unmount
//     return () => {
//       document.body.removeChild(script);
//     };
//   }, [paymentData]);

//   return (
//     <div>
//       <button onClick={handlePayment}>Pay Now</button>
//     </div>
//   );
// };

// export default PaymentComponent;

import React from 'react'

const PaymentTest = () => {
  return (
    <div>PaymentTest</div>
  )
}

export default PaymentTest