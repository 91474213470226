import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { CartState } from "../../context/Context.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineHeart } from 'react-icons/ai'
import { BiBarChart } from 'react-icons/bi'
import { BsFillCartPlusFill } from 'react-icons/bs'
import Cookies from 'universal-cookie'
import Loader from '../Loader/Loader.jsx';
import { generateRandomString } from '../../ReactFunctions.js';
import { setCookie } from '../../SessionManager.js';
import { LoadingDialog } from '../../screens/Cart/Cart.jsx';

const ProductCard = ({ data }) => {
  const cookies = new Cookies()
  const { state: { cart, compare }, dispatch } = CartState();
  const userData = JSON.parse(localStorage?.getItem("userData") || "{}");
  const [imageUrl, setImageUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [isWishListed, setIsWishListed] = useState(false)
  const [addingToWishlist, setAddingToWishlist] = useState(false)
  const [addingToCart, setAddingToCart] = useState(false)
  const [addingtoCompare, setAddingtoCompare] = useState(false)
    const navigate = useNavigate()
    const items_in_cart =  Number(cookies.get('items_in_cart'))
    // const header_token = { "username": "admin", "password": "Admin@12345" }
    const token = cookies?.get("auth_token")
  const cart_id = window?.localStorage?.getItem("cart_id")
  const compare_token = localStorage?.getItem("compare_token")
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    const oneWeekFromNow = new Date();
    oneWeekFromNow.setTime(oneWeekFromNow.getTime() + 604800 * 1000);
    const cookieOptions = {
        path: "/",
        expires: oneWeekFromNow,
        sameSite: "none",
    //   secure: true,
    };

    const updateItemsInCart = (count) => {
        console.log("count from update Itemsfromcart")
        cookies.set('items_in_cart', count, cookieOptions);
    };

    // Function to get "items_in_cart" value from local storage
    const getItemsInCart = () => {
        return parseInt(cookies.get('items_in_cart')) || 0;
    }

  const handleAddtoCart = () => {
    setAddingToCart(true)
    if (token) {
        const payload = {
          "cartItem": {
            "sku": data?.sku,
            "qty": 1,
            "quote_id": cart_id
          }
        }
        axios.post(`${process.env.REACT_APP_API_URL}/rest/V1/carts/mine/items`, payload, { headers: headers })
        .then((response) => {
          console.log("response from add to cart: ", response?.data)
          toast.dark(`✅ ${data?.name} is now added to your cart`, { closeOnClick: true })
          setAddingToCart(false)
          setCookie("items_in_cart", response?.data?.item_qty);
        })
        .catch((error) => {
          console.log("error from add to cart: ", error)
          setAddingToCart(false);
        })
      } else {
            alert("You're not logged in. Please login to continue.")
            navigate("/login")
        }

  }

  const addToCompare = () => {
    const payload = {
      "productId": data?.id ?? data?.entity_id,
      "compareToken": "",
    };
    if (!compare_token) {
      if (userData?.id) {
        const compareToken = token?.substring(0, 32);
        localStorage.setItem("compare_token", compareToken);
        payload.compareToken = compareToken;
      } else {
        payload.compareToken = generateRandomString(32);
      }
    } else {
       payload.compareToken = compare_token
    }
    console.log("payload before adding to compare: ", payload)
    setAddingtoCompare(true)
    axios.post(`${process.env.REACT_APP_API_URL}/rest/V1/compare/add`, payload)
    .then((res) => {
      console.log("response from add to compare: ", res?.data)  
      setAddingtoCompare(false);
      toast.dark(`✅ ${data?.name} is now added to your comparison list`, { closeOnClick: true })
      
    })
    .catch((err) => {
      console.log("error while adding item to comparison list: ", err)
      if (
        err?.response.data.message === "Product is already in the compare list."
      ) {
        toast.dark(`❌ ${data?.name} is already in your compare list`, {
          closeOnClick: true,
        });
        setAddingtoCompare(false);
      } else {
        toast.dark(
          `❌ Error while adding ${data?.name} to your comparison list`,
          { closeOnClick: true }
        );
        setAddingtoCompare(false);
      }
    })

  }

    const addToWishlist = () => {
      if (userData?.id) {
        if (data)
            setAddingToWishlist(true)
            axios.post(`${process.env.REACT_APP_API_URL}/rest/V1/wishlist/customer/product/${userData?.id}/${data?.id}`, null, { headers })
                .then((response) => {
                    console.log("Response : ", response?.data)
                    if (response?.data === true) {
                      toast.dark(`✅ ${data?.name} is added to WishList`, { closeOnClick: true })
                      setIsWishListed(true)
                      setAddingToWishlist(false);
                    } else {
                      toast.dark(`❌ ${data?.name} is not added to Wishlist. Please try again`, { closeOnClick: true })
                      setAddingToWishlist(false);
                    }
                })
                .catch((err) => {
                    console.log("Error while adding item to wishlist: ", err)
                })
        } else {
            const routeLogin = window.confirm("Please login to add the product to your wishlist. Do you want to login?");
            console.log("routeLogin value:", routeLogin);
            if (routeLogin) {
                navigate('/login'); // Navigate to the login page
            }
        }
  }

    const removeFromWishlist = () => {
      axios.delete(`${process.env.REACT_APP_API_URL}/rest/V1/wishlist/customer/item/${userData?.id}/${data?.id}`,null,{ headers: headers })
        .then((response) => {
          console.log("Response : ", response?.data);
          toast.dark(`🗑️ ${data?.name} is removed from your Wishlist.`, { closeOnClick: true })
          if (data?.extension_attributes?.wishlist) {
            data.extension_attributes.wishlist = false;
          }
          setIsWishListed(false)
        })
        .catch((err) => {
          console.log("Error while adding item to wishlist: ", err);
          toast.dark(`❌ Error while removing ${data?.name} from your Wishlist. Please try again.`, {
            closeOnClick: true,
          });
        });
    };
  
  useEffect(() => {
    if (data?.extension_attributes?.wishlist) {
      setIsWishListed(true);
    }
  }, [data]);

    return (
      <div
        key={data?.id}
        className="flex flex-col justify-start items-start gap-2 border-2 border-gray-400 rounded-md hover:shadow-xl p-2 md:w-[260px] md:min-w-[260px] sm:w-[260px] xs:w-[80%]  min-w-[200px]"
      >
        <div className="w-full flex justify-center items-center relative">
          <img
            src={
              data?.extension_attributes?.product_image_url ??
              data?.product_image_url
            }
            alt={data?.name + "_prod-img"}
            width="100%"
            height="100%"
            className="object-contain md:max-h-[150px] md:h-[130px] sm:h-[100px] sm:w-[100px] md:w-40 h-[120px] w-[120px]"
          />
          {!window?.location?.pathname.includes("wishlist") &&
            (isWishListed || addingToWishlist ? (
              <button
                className="absolute top-1 right-1"
                onClick={removeFromWishlist}
                disabled={addingToWishlist}
              >
                <svg
                  className={`w-6 h-6 text-red-600 ${
                    addingToWishlist && "animate-pulse"
                  }`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="m12.75 20.66 6.184-7.098c2.677-2.884 2.559-6.506.754-8.705-.898-1.095-2.206-1.816-3.72-1.855-1.293-.034-2.652.43-3.963 1.442-1.315-1.012-2.678-1.476-3.973-1.442-1.515.04-2.825.76-3.724 1.855-1.806 2.201-1.915 5.823.772 8.706l6.183 7.097c.19.216.46.34.743.34a.985.985 0 0 0 .743-.34Z" />
                </svg>
              </button>
            ) : (
              <button
                className="absolute top-1 right-1"
                onClick={() => {
                  addToWishlist();
                }}
              >
                <svg
                  className="w-6 h-6 text-gray-800"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12.01 6.001C6.5 1 1 8 5.782 13.001L12.011 20l6.23-7C23 8 17.5 1 12.01 6.002Z"
                  />
                </svg>
              </button>
            ))}
        </div>

        <div className="flex flex-col items-start justify-center md:px-3 px-1 py-1 gap-3 w-full">
          <p
            className="md:text-base text-sm font-medium w-full h-[75px] cursor-pointer font-man line-clamp-3"
            onClick={() =>
              navigate(
                `/product/${data?.sku}/${data?.name?.replace(/ /g, "-")}`,
                { state: data }
              )
            }
          >
            {data?.name}
          </p>
          <div className="w-full flex justify-between">
            {data?.price && (
              <p className="font-medium md:text-base text-sm">
                ₹{parseFloat(data?.price).toFixed(2)}
              </p>
            )}
            {/* <p className="flex flex-row gap-1 md:text-base text-sm">
                        ⭐4.3
                    </p> */}
          </div>

          <div className="w-full flex justify-between">
            {
              <button
                disabled={addingToCart}
                className="text-xs md:flex hover:bg-black bg-slate-800 justify-center items-center p-2 text-white cursor-pointer rounded-md"
                onClick={() => {
                  handleAddtoCart();
                }}
              >
                <p>
                  {addingToCart ? (
                    <div className="loading-container flex justify-center items-center w-[70px] h-[20px]">
                      <svg
                        aria-hidden="true"
                        className={`w-3 h-3 mr-2 text-gray-200 animate-spin  fill-blue-600`}
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                  ) : (
                    <span>ADD TO CART</span>
                  )}
                </p>
              </button>
            }
            {/* <button
              className="md:hidden"
              onClick={() => {
                handleAddtoCart();
              }}
            >
              {" "}
              <BsFillCartPlusFill size={25} />
            </button> */}

            <div className="flex gap-3 relative group">
              <button
                disabled={addingtoCompare}
                onClick={() => {
                  addToCompare();
                }}
              >
                {addingtoCompare ? (
                  <svg
                    aria-hidden="true"
                    className={`w-5 h-5 text-gray-200 animate-spin  fill-blue-600`}
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                ) : (
                  <>
                    <svg
                      className="w-7 h-7 text-gray-800 -rotate-90"
                      // aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M5 7h14M5 12h14M5 17h10"
                      />
                    </svg>
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs text-white bg-gray-800 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 delay-1000">
                      Add to Compare
                    </span>
                  </>
                )}
              </button>
            </div>
          </div>

          {/* {cart?.some((prod) => prod?.id === data?.id) ? (
                    <div className='text-sm justify-between flex w-full items-center gap-4'>
                        <div className='px-2 py-1 border rounded-md w-2/3 bg-white text-black text-sm font-semibold flex xl:w-2/3 justify-between border-gray-400'>
                            <p className='font-semibold text-xl cursor-pointer' onClick={()=>handleDecrement()}>-</p>
                            <p className='font-semibold text-base'>{cart?.find((c) => c.sku === data?.sku)?.qty}</p>
                            <p className='font-semibold text-xl cursor-pointer' onClick={()=>handleIncrement()}>+</p>
                        </div>
                        <div className='w-1/3 flex justify-center items-center'>
                            <MdDelete size={32} className='cursor-pointer' onClick={() => {
                                dispatch({
                                    type: "REMOVE_FROM_CART",
                                    payload: {
                                        sku: data?.sku
                                    }
                                })
                            }}/>
                        </div>
                    </div>
                ) : (
                    <button className="text-sm bg-black flex justify-center items-center py-2 text-white cursor-pointer w-full" onClick={() => {
                        dispatch({
                            type: "ADD_TO_CART",
                            payload: data
                        })
                        handleAddtoCart();
                    }}>
                        ADD TO CART
                    </button>
                )} */}
        </div>
        <div className="font-outfit text-sm ">
          <ToastContainer position="bottom-left" autoClose={4000} type="dark" />
        </div>
        <LoadingDialog
          isOpen={addingToCart}
          message={`Adding ${data?.name} to your cart...`}
          adding={true}
        />
      </div>
    );
}

export default ProductCard