import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import shoe from "./shoe.png";
import { CartState } from "../../context/Context";
import axios from "axios";
import { debounce } from "lodash";
import { getCart, fetchProducts } from "../../ReactFunctions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Cookies from "universal-cookie";
import usePageDurationTracker from "../../components/Hooks/usePageDurationTracker";
import Loader from "../../components/Loader/Loader";
import { getCookie, setCookie, useSessionManager } from "../../SessionManager";


export const LoadingDialog = ({ isOpen, message, adding }) => {
  if (!isOpen) return null; // Don't render anything if not open

  return (
    <div
      className="fixed inset-0 bg-black/50 flex justify-center items-center z-50"
      style={{
        backdropFilter: "blur(4px)",
        WebkitBackdropFilter: "blur(4px)", // for Safari support
      }}
    >
      <div className="bg-white rounded-lg shadow-xl max-w-[500px] w-[90%] min-h-[100px] p-6 flex justify-center items-center">
        <p className="flex gap-3 items-center justify-center text-center">
          {adding ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-6 h-6 text-black animate-ping"
            >
              <path
                fill-rule="evenodd"
                d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 0 0 4.25 22.5h15.5a1.875 1.875 0 0 0 1.865-2.071l-1.263-12a1.875 1.875 0 0 0-1.865-1.679H16.5V6a4.5 4.5 0 1 0-9 0ZM12 3a3 3 0 0 0-3 3v.75h6V6a3 3 0 0 0-3-3Zm-3 8.25a3 3 0 1 0 6 0v-.75a.75.75 0 0 1 1.5 0v.75a4.5 4.5 0 1 1-9 0v-.75a.75.75 0 0 1 1.5 0v.75Z"
                clip-rule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 text-black animate-spin"
            >
              <path
                fillRule="evenodd"
                d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                clipRule="evenodd"
              />
            </svg>
          )}
          <span className="text-black">{message}</span>
        </p>
      </div>
    </div>
  );
};

const CartProdCard = ({ data, updateCart, updateCartTotal }) => {
  // const cookies = new Cookies();
  const {
    state: { cart },
    dispatch,
  } = CartState();
  const [prodData, setProdData] = useState(data ?? {});
  const [deletingItem, setDeletingItem] = useState(false);
  const token = getCookie("auth_token");
  const cart_id = window?.localStorage?.getItem("cart_id");
  const [quantity, setQuantity] = useState(data?.qty);
  const [previousQty, setPreviousQty] = useState(data.qty);
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [quantityChanged, setQuantityChanged] = useState(false)
  // const productsData = JSON.parse(localStorage?.getItem("products"));
  const [quantLoading, setQuantLoading] = useState(false)
  const qla = "The requested qty exceeds the maximum qty allowed in shopping cart"
  // console.log("cart id", cart_id);
  // console.log("products data", productsData);

  //Debounce function
  const [executeDebouncer, setExecuteDebouncer] = useState(false);
  const debounceUpdateProductsInOrder = useCallback(
    debounce(() => setExecuteDebouncer(true), 750),
    []
  );

  const cartHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const quantityUpdate = () => {
    const payload = {
      cartItem: {
        sku: data?.sku,
        qty: quantity,
        quote_id: cart_id,
      },
    };
    setQuantLoading(true)
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/rest/default/V1/carts/mine/items/${data?.item_id}`,
        payload,
        { headers: cartHeaders }
      )
      .then((response) => {
        console.log("cartData: ", response?.data?.items);
        setCookie("items_in_cart", response?.data?.item_qty);
        const updatedItems = [...response.data.items];
        console.log("Updating cart with items:", updatedItems);
        updateCart(updatedItems);
        setQuantLoading(false);
        setQuantityChanged(false)
        console.log("Quant context: ", cart);
      })
      .catch((error) => {
        setQuantity(previousQty)
        setQuantLoading(false);
        setQuantityChanged(false);
        if (error?.response?.data?.message === qla)
          alert(`You've added maximum allowed quantity of ${prodData?.name}`);
        console.log("Error while updating quantity", error);
      });
  };

  //function to delete the product from cart
  const deleteItem = () => {
    setDeletingItem(true);
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/rest/V1/cart/delete-item/${data?.item_id}`,
        { headers: cartHeaders }
      )
      .then((response) => {
        if (response?.data) {
          updateCart([...response?.data?.items]);
          setCookie("items_in_cart", response?.data?.items_qty);
          setDeletingItem(false);
          toast.dark(`🗑️ ${data?.name} is now removed from your cart`, {
            closeOnClick: true,
          });
          console.log("response from delete API: ", response?.data);
        } else {
        }
      })
      .catch((error) => {
        console.log("Error while delete API", error);
        setDeletingItem(false);
      });
  };

  useEffect(() => {
    if (quantityChanged) {
      quantityUpdate()
      updateCartTotal()
    };
  }, [quantity]);

  useEffect(() => {
    console.log("CartProductCard rendered with data:", data);
  }, [data]);

  // useEffect(() => {
  //   fetchImage();
  //   console.log("image url received: ", imageUrl);
  // }, [data?.sku]);

  return (
    <div
      className="w-full flex justify-between items-center font-man"
      key={prodData?.item_id}
    >
      <div className="flex gap-3 w-2/5 justify-start">
        <div className="w-fit max-h-[125px] hidden md:flex border border-gray-300 p-2 rounded-md bg-gray-300">
          <img
            className="rounded-md object-contain md:w-[140px] md:h-[110px] w-[50px] h-[50px] mix-blend-multiply"
            src={
              prodData?.extension_attributes?.product_image_url ??
              prodData?.product_image_url
            }
            alt={prodData?.name + "img"}
          />
        </div>
        <div className="text-left items-start flex flex-col gap-2 justify-between w-full">
          <p className="font-semibold md:text-base text-sm line-clamp-3">
            {prodData?.name}{" "}
          </p>
          {/* <p className="md:text-sm text-xs flex gap-1">
            <span className="text-gray-600">Size:</span>
            <span className="font-medium">{data?.size}L</span>
          </p>
          <p className="md:text-sm text-xs flex gap-1">
            <span className="text-gray-600">Color:</span>
            <span className="font-medium">{data?.color}Monochrome</span>
          </p> */}
          <button
            className="hover:underline cursor-pointer text-gray-400 font-medium mt-2 text-sm  "
            onClick={() => {
              deleteItem();
            }}
          >
            Remove Item
          </button>
        </div>
      </div>
      <div className="flex gap-3 w-2/5 justify-center items-center">
        <div className="md:px-4 p-2 border h-10 align-center bg-white text-black text-sm font-semibold flex w-3/5 justify-between border-gray-400 rounded-md items-center">
          <button
            className={`font-semibold text-xl cursor-pointer disabled:cursor-not-allowed`}
            disabled={quantLoading}
            onClick={() => {
              if (quantity >= 2) {
                setQuantityChanged(true);
                setPreviousQty(quantity);
                setQuantity(quantity - 1);
              }
            }}
          >
            -
          </button>
          {quantLoading ? (
            <Loader size={4} />
          ) : (
            <p className="font-semibold text-base">
              {prodData?.qty >= 1 && prodData?.qty}
            </p>
          )}
          <button
            className={`font-semibold text-xl cursor-pointer disabled:cursor-not-allowed`}
            disabled={quantLoading}
            onClick={() => {
              setQuantityChanged(true);
              setPreviousQty(quantity);
              setQuantity(quantity + 1);
            }}
          >
            +
          </button>
        </div>
      </div>
      <div className="justify-end flex gap-3 w-1/5">
        <p className="font-medium md:text-lg">
          ₹
          {parseFloat(Number(prodData?.price) * Number(prodData?.qty)).toFixed(
            2
          )}
        </p>
      </div>
      <LoadingDialog isOpen={deletingItem} message={`Removing ${prodData?.name} from your cart...`}/>
    </div>
  );
};

const Cart = () => {
  // const cookies = new Cookies();
  const token = getCookie("auth_token");
  const {navigateTo} = useSessionManager()
  const cart_id = window?.localStorage?.getItem("cart_id");
  const [clearingCart, setClearingCart] = useState(false)
  const {
    state: { cart },
    dispatch,
  } = CartState();
  const navigate = useNavigate();
  const [cartData, setCartData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(0);

  const updateCartTotal = () => {
    const total = cartData?.reduce(
    (total, item) => total + item?.price * item?.qty,
    0
    )
    setTotal(total); 
  }

  const cartHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const clearCart = () => {
    setClearingCart(true)
    axios.post(`${process.env.REACT_APP_API_URL}/rest/V1/customer/cart/clear`, {},{
        headers: cartHeaders,
      })
      .then((response) => {
        console.log("response from clear cart: ", response?.data);
        if (response?.data?.success === true) {
          setCartData([])
          setCookie("items_in_cart", 0);
        }
        setClearingCart(false);
      })
      .catch((err) => {
        console.log("error while clearing cart: ", err);
        setClearingCart(false);
      });
  }

  const handleCheckout = () => {
    console.log(
      "Current flow before checkout:",
      sessionStorage.getItem("FLOW_STATE_KEY")
    );
    navigateTo("/checkout", cartData);
  };

  const updateCart = useCallback((newItems) => {
    console.log("Updating cart with new items:", newItems);
    setCartData(newItems);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getCart(cartHeaders, setCartData, setLoading)
    updateCartTotal()
  }, []);

  usePageDurationTracker("Cart");



  // useEffect(() => {
  //   if (!localStorage?.getItem("products")) {
  //     fetchProducts();
  //   }
  // }, []);

  useEffect(() => {
    updateCartTotal()
  }, [cartData])
  
  return (
    <div className="w-full p-2 md:px-10 px-4 font-man min-h-screen">
      {loading ? (
        <div className="w-full min-h-[400px] loading-container p-4 flex justify-center items-center flex-col gap-6">
          <svg
            aria-hidden="true"
            className="w-8 h-8 mr-2 text-gray-200 animate-spin  fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <p>Fetching your cart...</p>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center py-3">
            <div className="md:text-3xl text-lg font-bold">Your Cart</div>
            <div
              className="hover:underline text-gray-700 text-base cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            >
              Continue shopping
            </div>
          </div>
          {cartData?.length && !loading !== 0 ? (
            <div className="w-full flex flex-col gap-y-6">
              {/* <div className="flex w-full py-4">
                        <div className="text-gray-500 justify-center flex  w-1/3">
                            Product
                        </div> 
                        <div className="text-gray-500  justify-center flex w-1/3">
                            Quantity
                        </div>
                        <div className="text-gray-500 justify-end flex  w-1/3">
                            Total
                        </div>
                    </div> */}
              <div className="flex border-t-2 border-b-2 py-6 flex-col gap-8 items-center">
                <div className="flex flex-col gap-8 w-full items-center">
                  {cartData?.map((product, index) => {
                    return (
                      <CartProdCard
                        data={product}
                        updateCart={updateCart}
                        key={`${product.item_id}-${product.qty}`}
                        updateCartTotal={updateCartTotal}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="flex justify-between items-start">
                <button onClick={clearCart}>
                  <p className="underline">Clear Cart</p>
                </button>
                <div className="text-right">
                  <p className="font-bold md:text-2xl text-xl mb-2">
                    <span className="mr-3 text-gray-400 font-semibold">
                      Subtotal
                    </span>
                    ₹{parseFloat(total).toFixed(2)}
                  </p>
                  <p className="font-sm text-gray-400 md:text-base text-sm mb-2">
                    *Taxes & Shipping calculated at checkout
                  </p>
                </div>
              </div>
              <div className="flex justify-between mb-3">
                <button
                  onClick={() => navigate(-1)}
                  className="px-4 py-3 border rounded-md md:w-1/5 bg-white text-black  text-sm font-semibold border-gray-400"
                >
                  GO BACK{" "}
                </button>
                <button
                  className="px-4 py-3 border rounded-md md:w-1/5 bg-black text-white  text-sm font-semibold border-gray-400"
                  onClick={handleCheckout}
                >
                  CHECKOUT
                </button>
                {/* <button
                  onClick={() => navigateTo("/checkout", { state: cartData })}
                >
                  CHECKOUT
                </button> */}
              </div>
            </div>
          ) : (
            <div className="w-full p-7 h-[450px] flex items-center justify-center">
              <p>Your cart is empty 🛍️</p>
            </div>
          )}
        </>
      )}

      <div className="font-outfit text-sm ">
        <ToastContainer position="bottom-left" autoClose={4000} type="dark" />
      </div>
      <LoadingDialog isOpen={clearingCart} message={`Clearing your cart...`} />
    </div>
  );
};

export default Cart;

// old code
// import React, { useState, useEffect, useCallback } from "react";
// import { useNavigate } from "react-router-dom";
// import shoe from './shoe.png'
// import { CartState } from "../../context/Context";
// import axios from "axios";
// import { debounce } from 'lodash'
// import { getCart } from "../../ReactFunctions";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { fetchProducts } from "../../ReactFunctions";
// import Cookies from 'universal-cookie'

// const CartProdCard = ({ data, updateCart }) => {
//     const cookies = new Cookies()
//     const { state: { cart }, dispatch } = CartState();
//     const token = cookies?.get("auth_token")
//     const cart_id = window?.localStorage?.getItem("cart_id")
//     const [quantity, setQuantity] = useState(data?.qty)
//     const [imageUrl, setImageUrl] = useState('')
//     const [loading, setLoading] = useState(false)
//     const productsData = JSON.parse(localStorage?.getItem("products"))

//     //Debounce function
//     const [executeDebouncer, setExecuteDebouncer] = useState(false);
//     const debounceUpdateProductsInOrder = useCallback(
//         debounce(() => setExecuteDebouncer(true), 750),
//         [],
//     );

//     const cartHeaders = {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`
//     }

//     const fetchImage = () => {
//         setLoading(true)
//         let req_prod = productsData?.find(prod => prod?.sku === data?.sku)
//         setImageUrl(req_prod?.media_gallery_entries?.[0]?.file)
//         setLoading(false)
//     }

//     const oneWeekFromNow = new Date();
//     oneWeekFromNow.setTime(oneWeekFromNow.getTime() + 604800 * 1000);
//     const cookieOptions = {
//         path: "/",
//         expires: oneWeekFromNow,
//         sameSite: "none",
//         secure: true,
//     };

//     const updateItemsInCart = (count) => {
//         cookies.set('items_in_cart', count, cookieOptions);
//     };

//     // Function to get "items_in_cart" value from local storage
//     const getItemsInCart = () => {
//         return parseInt(cookies.get('items_in_cart')) || 0;
//     }

//     //function to update the quantity of the product in cart
//     const quantityUpdate = () => {
//         const payload  = {
//             "cartItem": {
//                 "sku": data?.sku,
//                 "qty": quantity,
//                 "quote_id": cart_id
//             }
//         }

//         axios.put(`http://65.0.150.104/rest/default/V1/carts/mine/items/${data?.item_id}`, payload, { headers: cartHeaders })
//             .then((response) => {
//                 console.log("cartData: ", response?.data?.items)
//                 getCart(cartHeaders, updateCart);
//                 dispatch({
//                     type: "CHANGE_CART_QTY",
//                     payload: {
//                         sku: data?.sku,
//                         qty: quantity
//                     }
//                 })
//                 console.log("Quant context: ", cart)
//             })
//             .catch((error) => {
//                 console.log("Error while fetching active cart", error)
//             })
//     }

//     //function to delete the product from cart
//     const deleteItem = () => {
//         axios.delete(`http://65.0.150.104/rest/default/V1/carts/mine/items/${data?.item_id}`, { headers: cartHeaders })
//             .then((response) => {
//                 if (response?.data) {
//                     getCart(cartHeaders, updateCart);
//                     toast.dark(`🗑️ ${data?.name} is now removed from your cart`, { closeOnClick: true })
//                     dispatch({
//                         type: "REMOVE_FROM_CART",
//                         payload: {
//                             id: data?.sku
//                         }
//                     })
//                     updateItemsInCart(getItemsInCart()-1)
//                     console.log("response from delete API: ", response?.data)
//                 }
//                 else {

//                 }
//             })
//             .catch((error) => {
//                 console.log("Error while delete API", error)
//             })
//     }

//     // const handleIncrement = () => {
//     //     dispatch({
//     //     type: "INCREMENT",
//     //     payload: {
//     //         sku: data?.sku,
//     //     },
//     //     });
//     // };

//     // const handleDecrement = () => {
//     //     if (cart?.find((c) => c.sku === data?.sku).qty > 1) {
//     //     dispatch({
//     //         type: "DECREMENT",
//     //         payload: {
//     //          sku: data?.sku,
//     //         },
//     //     });
//     //     }
//     // };

//     useEffect(() => {
//         if (executeDebouncer) {
//             setExecuteDebouncer(false);
//             quantityUpdate();
//         }
//     }, [executeDebouncer]);

//     useEffect(() => {
//         fetchImage()
//         console.log("image url received: ", imageUrl)
//     }, [])

//     return (
//         <div className="w-full flex justify-between items-center" key={data?.item_id}>
//                 <div className="flex gap-3 w-2/5 justify-start">
//                 <div className="w-[60px] hidden md:flex">
//                 {loading ? (
//                     <div className='rounded-md bg-[#ECF2F4] w-[100px] h-[100px] loading-container p-4 flex justify-center items-center'>
//                         <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-200 animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//                             <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
//                             <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
//                         </svg>
//                     </div>
//                 ) : (
//                     <img className="rounded-md object-contain md:w-[100px] md:h-[100px] w-[50px] h-[50px]" src={`http://65.0.150.104/media/catalog/product${imageUrl}`} alt="prod-img" />
//                 )
//                 }
//                 </div>
//                     <div className="text-left items-start flex flex-col gap-2">
//                         <p className="font-semibold md:text-base text-sm">{data?.name} </p>
//                         <p className="md:text-sm text-xs flex gap-1"><span className="text-gray-600">Size:</span><span className="font-medium">{data?.size}L</span></p>
//                        <p className="md:text-sm text-xs flex gap-1"><span className="text-gray-600">Color:</span><span className="font-medium">{data?.color}Monochrome</span></p>
//                         <button className="hover:underline cursor-pointer hover:text-red-500 font-medium mt-2 md:text-base text-sm  " onClick={() => {
//                             deleteItem();
//                         }}>Remove Item</button>
//                     </div>
//                 </div>
//             <div className="flex gap-3 w-2/5 justify-center items-center">
//                 <div className='md:px-4 p-2 border h-10 align-center bg-white text-black text-sm font-semibold flex w-3/5 justify-between border-gray-400 rounded-md items-center'>
//                     <p className='font-semibold text-xl cursor-pointer' onClick={() => {
//                         if (quantity >= 2) {
//                             setQuantity(quantity - 1)
//                             debounceUpdateProductsInOrder()
//                         };
//                     }}>-</p>
//                     <p className='font-semibold text-base'>{quantity >= 1 && quantity}</p>
//                     <p className='font-semibold text-xl cursor-pointer' onClick={() => {
//                         setQuantity(quantity + 1)
//                         debounceUpdateProductsInOrder();
//                     }}>+</p>
//                 </div>
//             </div>
//             <div className="justify-end flex gap-3 w-1/5">
//                 <p className="font-medium">${Number(data?.price) * Number(quantity)}</p>
//             </div>
//         </div>
//     )
// }

// const Cart = () => {
//     const cookies = new Cookies()
//     const token = cookies?.get("auth_token")
//     const cart_id = window?.localStorage?.getItem("cart_id")
//     const { state: { cart }, dispatch } = CartState();
//     const navigate = useNavigate();
//     const [count, setCount] = useState(1)
//     const colors = [1, 2, 3, 4, 5, 6, 7, 8]
//     const [cartData, setCartData] = useState([])
//     const [loading, setLoading] = useState(true)

//     const total = cartData?.reduce((total, item) => total + (item?.price * item?.qty), 0)
//     const cartHeaders = {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`
//     }

//     useEffect(() => {
//         window.scrollTo(0, 0)
//         setLoading(true)
//         getCart(cartHeaders, setCartData);
//         setLoading(false)
//     }, [])

//     useEffect(() => {
//         if (!localStorage?.getItem("products")) {
//             fetchProducts()
//         }
//     },[])
//     return (

//         <div className="w-full p-2 md:px-10 px-4 font-outfit min-h-screen">
//             {loading ? (
//                 <div className='rounded-md bg-[#ECF2F4] w-[100px] h-[100px] loading-container p-4 flex justify-center items-center'>
//                   <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-200 animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//                       <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
//                       <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
//                   </svg>
//                 </div>
//             ):(
//                     <>
//                 <div className="flex justify-between items-center py-3">
//                 <div className="md:text-3xl text-lg font-bold">
//                     Your Cart
//                 </div>
//                 <div className="hover:underline text-gray-700 text-base cursor-pointer" onClick={()=>{navigate("/")}}>
//                     Continue shopping
//                 </div>
//             </div>
//             {cartData?.length && !loading !== 0 ? (
//                 <div className="w-full flex flex-col gap-y-6">
//                     {/* <div className="flex w-full py-4">
//                         <div className="text-gray-500 justify-center flex  w-1/3">
//                             Product
//                         </div>
//                         <div className="text-gray-500  justify-center flex w-1/3">
//                             Quantity
//                         </div>
//                         <div className="text-gray-500 justify-end flex  w-1/3">
//                             Total
//                         </div>
//                     </div> */}
//                 <div className="flex border-t-2 border-b-2 py-6 flex-col gap-8 items-center">
//                     <div className="flex flex-col gap-8 w-full items-center">
//                         {
//                             cartData?.map((product, index) => {
//                                 return (
//                                     <CartProdCard data={product} updateCart={setCartData} key={index} />
//                                 )
//                             })
//                         }
//                     </div>
//                 </div>
//                 <div className="text-right">
//                     <p className="font-bold md:text-2xl text-xl mb-2"><span className="mr-3 text-gray-400 font-semibold">Subtotal</span>${total}.00</p>
//                     <p className="font-sm text-gray-400 md:text-base text-sm mb-2">*Taxes & Shipping calculated at checkout</p>
//                 </div>
//                 <div className="flex justify-between mb-3">
//                     <button onClick={() => navigate(-1)} className='px-4 py-3 border rounded-md md:w-1/5 bg-white text-black  text-sm font-semibold border-gray-400'>GO BACK </button>
//                     <button className='px-4 py-3 border rounded-md md:w-1/5 bg-black text-white  text-sm font-semibold border-gray-400' onClick={()=>navigate("/checkout", {state: cartData})}>CHECKOUT</button>
//                 </div>
//                 </div>
//             ) : (
//                 <div className="w-full p-7 md:h-screen h-[450px] flex items-center justify-center">
//                     <p>Your cart is empty 🛍️</p>
//                 </div>
// )}
//             </>
//             )}

//         {/* <div className="w-full py-3 md:px-6 p-3 flex flex-col md:items-center items-start md:mt-10 font-outfit">
//         <div className='md:w-[85%] w-full flex flex-col gap-5'>
//           <p className='md:text-xl text-base'>Recommended for you</p>
//           <div className='w-full flex md:gap-4 gap-y-4 gap-x-2 flex-wrap'>
//             {colors?.map((color, index) => {
//                 return <div key={index+"a"} className='md:w-[200px] md:h-[240px] w-[140px] h-[160px] rounded-md bg-gray-400'/>
//             })}
//         </div>
//         </div>
//       </div>

//       <div className="w-full py-3 md:px-6 p-3 flex flex-col md:items-center items-start md:mt-10 font-outfit">
//         <div className='md:w-[85%] w-full flex flex-col gap-5'>
//           <p className='md:text-xl text-base'>You may also like</p>
//           <div className='w-full flex md:gap-4 gap-y-4 gap-x-2 flex-wrap'>
//             {colors?.map((color, index) => {
//               return <div key={index} className='md:w-[200px] md:h-[240px] w-[140px] h-[160px] rounded-md bg-gray-400'/>
//             })}
//         </div>
//         </div>
//       </div> */}
//             <div className='font-outfit text-sm '>
//                 <ToastContainer position='bottom-left' autoClose={4000} type="dark"/>
//             </div>
//         </div>

//     )
// }

// export default Cart;
