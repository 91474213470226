import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios'
import { BsCheckCircleFill } from 'react-icons/bs'
import Cookies from 'universal-cookie'

export const regions = [
  {
    id: "569",
    code: "AN",
    name: "Andaman and Nicobar Islands",
  },
  {
    id: "570",
    code: "AP",
    name: "Andhra Pradesh",
  },
  {
    id: "571",
    code: "AR",
    name: "Arunachal Pradesh",
  },
  {
    id: "572",
    code: "AS",
    name: "Assam",
  },
  {
    id: "573",
    code: "BR",
    name: "Bihar",
  },
  {
    id: "574",
    code: "CH",
    name: "Chandigarh",
  },
  {
    id: "575",
    code: "CT",
    name: "Chhattisgarh",
  },
  {
    id: "576",
    code: "DN",
    name: "Dadra and Nagar Haveli",
  },
  {
    id: "577",
    code: "DD",
    name: "Daman and Diu",
  },
  {
    id: "578",
    code: "DL",
    name: "Delhi",
  },
  {
    id: "579",
    code: "GA",
    name: "Goa",
  },
  {
    id: "580",
    code: "GJ",
    name: "Gujarat",
  },
  {
    id: "581",
    code: "HR",
    name: "Haryana",
  },
  {
    id: "582",
    code: "HP",
    name: "Himachal Pradesh",
  },
  {
    id: "583",
    code: "JK",
    name: "Jammu and Kashmir",
  },
  {
    id: "584",
    code: "JH",
    name: "Jharkhand",
  },
  {
    id: "585",
    code: "KA",
    name: "Karnataka",
  },
  {
    id: "586",
    code: "KL",
    name: "Kerala",
  },
  {
    id: "587",
    code: "LD",
    name: "Lakshadweep",
  },
  {
    id: "588",
    code: "MP",
    name: "Madhya Pradesh",
  },
  {
    id: "589",
    code: "MH",
    name: "Maharashtra",
  },
  {
    id: "590",
    code: "MN",
    name: "Manipur",
  },
  {
    id: "591",
    code: "ML",
    name: "Meghalaya",
  },
  {
    id: "592",
    code: "MZ",
    name: "Mizoram",
  },
  {
    id: "593",
    code: "NL",
    name: "Nagaland",
  },
  {
    id: "594",
    code: "OR",
    name: "Odisha",
  },
  {
    id: "595",
    code: "PY",
    name: "Puducherry",
  },
  {
    id: "596",
    code: "PB",
    name: "Punjab",
  },
  {
    id: "597",
    code: "RJ",
    name: "Rajasthan",
  },
  {
    id: "598",
    code: "SK",
    name: "Sikkim",
  },
  {
    id: "599",
    code: "TN",
    name: "Tamil Nadu",
  },
  {
    id: "600",
    code: "TG",
    name: "Telangana",
  },
  {
    id: "601",
    code: "TR",
    name: "Tripura",
  },
  {
    id: "602",
    code: "UP",
    name: "Uttar Pradesh",
  },
  {
    id: "603",
    code: "UT",
    name: "Uttarakhand",
  },
  {
    id: "604",
    code: "WB",
    name: "West Bengal",
  },
];

const Address = ({
  currentScreen,
  updateShippingCost,
  updateTaxes,
  updateDiscount,
  updateTotal,
  fetchingTotals,
}) => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const location = useLocation();
  const [cartData, setCartData] = useState(
    location?.state ? location?.state : []
  );
  const auth_token = cookies?.get("auth_token");
  const [open, setOpen] = useState(true);
  const [inputId, setId] = useState(1);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({});
  const taxes = 1000;
  const shipping = 500;
  const subtotal =
    cartData?.reduce((total, item) => total + item?.price * item?.qty, 0) * 80;
  const orderTotal = taxes + shipping + subtotal;
  const address_headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${auth_token}`,
  };
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [postcode, setPostcode] = useState("");
  const [phone, setPhone] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
    const [selectedShipping, setSelectedShipping] = useState("freeshipping");
    const [fetchingAddresses, setFetchingAddresses] = useState(false)
    const [billingAddress, setBillingAddress] = useState({});
    const [addingNewAddress, setAddingNewAddress] = useState(false)

  //******Function to Fetch User Addresses ******//
  const fetchAddresses = () => {
    setFetchingAddresses(true);
    const url = `${process.env.REACT_APP_API_URL}/rest/V1/customers/me`;
    axios
      .get(url, { headers: address_headers })
      .then((response) => {
        console.log(
          "response from address api to fetch address",
          response?.data
        );
        setAddresses(response?.data?.addresses);
        setUserData(response?.data);
        setUserEmail(response?.data?.email);
        console.log(
          "Log before setting selected address: ",
          response?.data?.addresses?.find((c) => c?.default_shipping === true)
        );
        setSelectedAddress(
          response?.data?.addresses?.find((c) => c?.default_shipping === true)
        );
        console.log(
          "billing address from address section: ",
          response?.data?.addresses?.find((c) => c?.default_shipping === true)
        );
        setBillingAddress(
          response?.data?.addresses?.find((c) => c?.default_billing === true)
        );
        setFetchingAddresses(false);
      })
      .catch((err) => {
        console.log("Error while fetching user address: ", err);
        setFetchingAddresses(false);
      });
  };

  //******Function to Add New Address to User Addresses ******//

  const addNewAddress = () => {
    const url = `${process.env.REACT_APP_API_URL}/rest/V1/customers/me`;
    const payload = {
      customer: {
        email: userEmail,
        firstname: userData?.firstname,
        lastname: userData?.lastname,
        website_id: 1,
        addresses: [
          ...addresses,
          {
            customer_id: userData?.id,
            region: {
              region_code: state?.code,
              region: state?.name,
              region_id: state?.id,
            },
            country_id: "IN",
            street: [address],
            telephone: phone,
            postcode: postcode,
            city: city,
            firstname: firstName,
            lastname: lastName,
            default_shipping: addresses?.length > 0 ? isDefault : true,
            default_billing:
              addresses?.length > 0 ? (isDefault ? true : false) : true,
          },
        ],
      },
    };
      const req_payload = JSON.stringify(payload);
      setAddingNewAddress(true)
    axios
      .put(url, req_payload, { headers: address_headers })
      .then((response) => {
        setAddresses(response?.data?.addresses);
        setSelectedAddress(
          response?.data?.addresses?.find((c) => c?.default_shipping === true)
        );
        resetState();
          console.log("response from adding address", response?.data);
          localStorage.setItem("userData", JSON.stringify(response?.data));
          showAddressForm(false)
          setAddingNewAddress(false);
      })
      .catch((err) => {
          console.log("error while adding address: ", err);
          setAddingNewAddress(false);
      });
  };

  const submitShippingInfo = () => {
    const req_url = `${process.env.REACT_APP_API_URL}/rest/V1/carts/mine/shipping-information`;
    console.log(
      `values before payload shipping: region: ${selectedAddress?.region}, country_id: ${selectedAddress?.country_id} and street: ${selectedAddress?.street}`
    );
    const payload = {
      addressInformation: {
        shippingAddress: {
          region: selectedAddress?.region?.region,
          region_id: Number(selectedAddress?.region?.region_id),
          country_id: selectedAddress?.country_id,
          street: selectedAddress?.street,
          postcode: selectedAddress?.postcode,
          city: selectedAddress?.city,
          telephone: selectedAddress?.telephone,
          firstname: selectedAddress?.firstname,
          lastname: selectedAddress?.lastname,
          email: userEmail,
        },
        billingAddress: {
          region: selectedAddress?.region?.region,
          region_id: Number(selectedAddress?.region?.region_id),
          country_id: selectedAddress?.country_id,
          street: selectedAddress?.street,
          postcode: selectedAddress?.postcode,
          city: selectedAddress?.city,
          telephone: selectedAddress?.telephone,
          firstname: selectedAddress?.firstname,
          lastname: selectedAddress?.lastname,
          email: userEmail,
        },
        shipping_method_code: selectedShipping,
        shipping_carrier_code: selectedShipping,
      },
    };
    const req_payload = JSON.stringify(payload);
    axios
      .post(req_url, payload, { headers: address_headers })
      .then((response) => {
        console.log("shipping info submitted: ", response?.data);
        localStorage?.setItem("shippingInfoSubmitted", true);
        updateShippingCost(response?.data?.totals?.base_shipping_incl_tax);
        updateTaxes(response?.data?.totals?.tax_amount);
        updateDiscount(response?.data?.totals?.discount_amount);
        updateTotal(response?.data?.totals?.grand_total);
        fetchingTotals(false);
      })
      .catch((err) => {
        console.log("Error with shipping: ", err?.response?.data);
        localStorage?.setItem("shippingInfoSubmitted", false);
        fetchingTotals(false);
      });
  };

  const handleContinue = () => {
    submitShippingInfo();
    setShowAddressForm(false);
    currentScreen("payment");
  };

  const resetState = () => {
    setFirstName("");
    setLastName("");
    setAddress("");
    setCity("");
    setCountry("");
    setState("");
    setPostcode("");
    setPhone("");
      setIsDefault(false);
      setShowAddressForm(false);
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    fetchAddresses();
  }, []);

  useEffect(() => {
    console.log(`Checkbox is checked : ${isDefault}`);
  }, [isDefault]);

  // useEffect(() => {
  //     sessionStorage?.setItem('sa', JSON.stringify(selectedAddress))
  // }, [selectedAddress])

  return (
    <div className="w-full font-man flex flex-col items-start justify-start gap-7">
      <div className="w-full hidden lg:flex gap-3 items-center justify-center font-medium ">
        <div className="flex gap-3 items-center">
          <p className="rounded-full bg-black text-white p-2 w-6 h-6 flex items-center justify-center text-sm">
            1
          </p>
          <p>Information</p>
        </div>
        <hr className="h-0.5 w-[60px] border-t-0 bg-black opacity-100 dark:opacity-50" />
        <div className="flex gap-3 items-center">
          <p className="rounded-full bg-black text-white p-2 w-6 h-6 flex items-center justify-center  text-sm">
            2
          </p>
          <p>Payment</p>
        </div>
        <hr className="h-0.5 w-[60px] border-t-0 bg-black opacity-100 dark:opacity-50" />
        <div className="flex gap-3 items-center">
          <p className="rounded-full bg-black text-white p-2 w-6 h-6 flex items-center justify-center  text-sm">
            3
          </p>
          <p>Order Placed</p>
        </div>
      </div>
      {fetchingAddresses ? (
        <div className="w-full flex justify-center items-center min-h-[200px] gap-3">
          <svg
            aria-hidden="true"
            className={`w-5 h-5 text-gray-200 animate-spin  fill-blue-600`}
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span>Fetching your addresses</span>
        </div>
      ) : (
        addresses?.length !== 0 && (
          <div className="flex justify-evenly items-start w-full gap-3">
            <p className="w-1/4 font-medium flex justify-center">
              Delivery Address
            </p>
            {
              <p className="w-2/4 capitalize text-center">
                {selectedAddress?.firstname} {selectedAddress?.lastname},{" "}
                {selectedAddress?.street}
                {selectedAddress?.address}, {selectedAddress?.city},{" "}
                {selectedAddress?.region?.region}, {selectedAddress?.country_id}
                ,{"  "}
                {selectedAddress?.postcode}
              </p>
            }
            {open ? (
              <button
                className="w-1/4 cursor-pointer hover:underline"
                onClick={() => setOpen(!open)}
              >
                Change
              </button>
            ) : (
              <button
                className="w-1/4   cursor-pointer hover:underline"
                onClick={() => {
                  setOpen(!open);
                  setShowAddressForm(false);
                }}
              >
                Close
              </button>
            )}
          </div>
        )
      )}
      {!open && (
        <div className="border rounded p-4 w-full flex flex-col gap-8 items-start justify-start">
          <p className="text-lg">ADDRESSES</p>
          <div className="w-full flex flex-col gap-5 items-start justify-start">
            {addresses?.map((address) => {
              return (
                <div
                  className=" flex gap-3 w-full justify-start items-center py-2 cursor-pointer bg-[#F0F2F6] rounded-md"
                  key={address?.id}
                  onClick={() => setSelectedAddress(address)}
                >
                  <div className="flex p-3 flex-col w-4/5">
                    <p className="text-lg font-medium capitalize">
                      {address?.firstname} {address?.lastname}
                    </p>
                    <div>
                      <p className=" capitalize">
                        {address?.street}, {address?.city},<br />
                        {address?.region?.region}, {address?.country_id},{" "}
                        {address.postcode}
                      </p>
                    </div>
                  </div>
                  <div className="w-1/5 flex h-full justify-end items-start px-6">
                    {selectedAddress?.id === address?.id && (
                      <BsCheckCircleFill size={26} />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-full flex justify-between">
            <button
              className="font-sm text-white bg-black cursor-pointer text-sm px-4 py-2 flex justify-center items-center rounded-md"
              onClick={() => {
                setShowAddressForm(!showAddressForm);
              }}
            >
              ADD ADDRESS
            </button>
            <button
              className="font-sm text-white bg-black cursor-pointer rounded-md text-sm px-4 py-2"
              onClick={() => {
                setShowAddressForm(false);
                setOpen(!open);
              }}
            >
              SAVE
            </button>
          </div>
        </div>
      )}
      {(showAddressForm || addresses?.length === 0) &&
        fetchingAddresses === false && (
          <div className="w-full">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addNewAddress();
              }}
            >
              <div className="flex items-center py-4 ">
                <span className="text-base text-[#2D2D2D] font-medium">
                  ADDRESS FORM
                </span>
              </div>
              <div className="flex justify-between gap-4">
                <div className="w-1/2">
                  <label
                    htmlFor="firstName"
                    className="text-[#767676] text-base"
                  >
                    First Name
                  </label>
                  <input
                    className="w-full px-3 py-2 border-[1px] border-[#C7C7C7]
                                     focus:outline-none"
                    type="text"
                    placeholder="John"
                    name="firstName"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e?.target.value);
                    }}
                    required
                  />
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor="lastName"
                    className="text-[#767676] text-base"
                  >
                    Last Name
                  </label>
                  <input
                    className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] 
                                     focus:outline-none"
                    type="text"
                    placeholder="Doe"
                    name="lastName"
                    id="lastName"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e?.target.value);
                    }}
                    required
                  />
                </div>
              </div>
              <div className="mt-3">
                <label htmlFor="address" className="text-[#767676] text-base">
                  Address
                </label>
                <input
                  className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none"
                  type="text"
                  placeholder="House/Flat No., Colony/Lane, Area"
                  required
                  name="address"
                  id="address"
                  value={address}
                  onChange={(e) => {
                    setAddress(e?.target.value);
                  }}
                />
              </div>
              <div className="flex justify-start items-center gap-4 mt-4">
                <div className="col-span-2 w-1/3">
                  <label htmlFor="city" className="text-[#767676] text-base">
                    City
                  </label>
                  <input
                    className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none"
                    type="text"
                    placeholder="Your City"
                    required
                    name="city"
                    id="city"
                    value={city}
                    onChange={(e) => {
                      setCity(e?.target.value);
                    }}
                  />
                </div>
                <div className="flex gap-4 w-1/3">
                  <div className="w-full flex flex-col">
                    <label className="text-[#767676] text-base">State</label>
                    <select
                      name="region_dd"
                      id="region_dd"
                      className="w-full px-1 py-2 border-[1px] border-[#C7C7C7] focus:outline-none"
                      value={state?.name || ""} // Using name for display
                      onChange={(e) => {
                        const selectedName = e.target.value;
                        const selectedRegion = regions.find(
                          (region) => region.name === selectedName
                        );
                        console.log("selected Region: ", selectedRegion);
                        setState(selectedRegion);
                      }}
                    >
                      <option value="">Select a State</option>
                      {regions?.map((region) => (
                        <option key={region.id} value={region.name}>
                          {" "}
                          {/* Using name as value */}
                          {region.name}
                        </option>
                      ))}
                    </select>
                    {/* <input
                      className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none"
                      type="text"
                      placeholder="New York"
                      required
                      name="province"
                      value={state}
                      onChange={(e) => {
                        setState(e?.target.value);
                      }}
                    /> */}
                  </div>
                </div>
                <div className="w-1/3">
                  <label className="text-[#767676] text-base">Country</label>
                  <input
                    disabled
                    className="w-full px-1 py-2 border-[1px]
                                 border-[#C7C7C7] focus:outline-none"
                    name="country"
                    value={"India"}
                    // onChange={(e) => {
                    //   setCountry(e?.target.value);
                    // }}
                    required
                  />
                </div>
              </div>
              <div className="flex justify-between items-center gap-4 mt-4">
                <div className="w-1/2">
                  <label className="text-[#767676] text-base">Phone</label>
                  <input
                    className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none"
                    type="text"
                    placeholder="+910000000000"
                    required
                    name="zipcode"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e?.target.value);
                    }}
                  />
                </div>
                <div className="w-1/2">
                  <label htmlFor="zipcode" className="text-[#767676] text-base">
                    Pincode
                  </label>
                  <input
                    className="w-full px-3 py-2 border-[1px] border-[#C7C7C7] focus:outline-none"
                    type="text"
                    placeholder="10001"
                    required
                    id="zipcode"
                    name="zipcode"
                    value={postcode}
                    onChange={(e) => {
                      setPostcode(e?.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="mt-3">
                <input
                  className="bg-gray-500"
                  id="DefaultAddress"
                  type="checkbox"
                  name="DefaultAddress"
                  value={isDefault}
                  onChange={(e) => setIsDefault(e?.target?.checked)}
                  required={addresses?.length === 0}
                />
                <label
                  className="text-base font-medium"
                  htmlFor="DefaultAddress"
                >
                  {" "}
                  Set as a default address
                </label>
              </div>
              {/* <div className="mt-3">
                <input
                  className="bg-gray-500"
                  type="checkbox"
                  name="DefaultAddress"
                  value="Address"
                  required
                />
                <label
                  className="text-base font-medium"
                  htmlFor="DefaultAddress"
                >
                  {" "}
                  Privacy Policy Statement (consent)
                </label>
              </div> */}
              <div className="flex justify-between items-center gap-4 mt-7">
                {addresses?.length !== 0 && (
                              <button
                                  disabled={addingNewAddress}
                    className="w-[90px]  py-3 border rounded-md bg-white text-black  text-sm font-semibold border-gray-400"
                    onClick={() => {
                      setShowAddressForm(!showAddressForm);
                    }}
                  >
                    CANCEL
                  </button>
                )}
                <button
                              type="submit"
                              disabled={addingNewAddress}
                  className=" w-[90px]  py-3 border rounded-md bg-black text-white  text-sm font-semibold border-gray-400 flex justify-center items-center"
                >
                  {addingNewAddress ?
                    <svg
                      aria-hidden="true"
                      className={`w-5 h-5 text-gray-200 animate-spin  fill-blue-600`}
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg> : <span>SUBMIT</span>
                  }
                </button>
              </div>
            </form>
          </div>
        )}
      <div className="shipping-container w-full flex flex-col gap-4 mt-8">
        <p className="uppercase font-semibold">AVAILABLE SHIPPING METHODS</p>
        <div
          className="flex items-center justify-between p-4 gap-6 rounded-md bg-[#F0F2F6] cursor-pointer"
          onClick={() => {
            setSelectedShipping("freeshipping");
          }}
        >
          <div>
            <p className="font-medium">Standard Delivery</p>
            <p className="text-gray-500 text-sm">
              Delivery: 7-8 Business Days (Free)
            </p>
          </div>
          <div className="flex gap-3">
            {/* <p className='font-medium'>FREE</p> */}
            <input
              type="radio"
              name="delivery-checkbox"
              id="fedex"
              checked={selectedShipping === "freeshipping"}
              onChange={() => console.log("shipping method changed")}
              className="accent-black"
            />
          </div>
        </div>
        <div
          className="flex items-center justify-between p-4 gap-6 rounded-md bg-[#F0F2F6] cursor-pointer"
          onClick={() => {
            setSelectedShipping("flatrate");
          }}
        >
          <div>
            <p className="font-medium">Express Delivery</p>
            <p className="text-gray-500 text-sm">
              One-day delivery. Get it delivered by tomorrow (Charge will be
              calculated)
            </p>
          </div>
          <div className="flex gap-3">
            {/* <p className='font-medium'>CHARGEABLE</p> */}
            <input
              type="radio"
              name="delivery-checkbox"
              id="fedex"
              className="accent-black"
              checked={selectedShipping === "flatrate"}
              onChange={() => console.log("shipping method changed")}
            />
          </div>
        </div>
      </div>
      <div className="p-3 w-full flex justify-between items-center">
        <button
          className="font-sm text-black border-black border rounded-md cursor-pointer text-sm px-4 py-2 flex justify-center items-center"
          onClick={() => {
            navigate("/cart");
          }}
        >
          BACK TO CART
        </button>
        {selectedAddress?.region && (
          <button
            className="font-sm text-white rounded-md bg-black cursor-pointer text-sm px-4 py-2 flex justify-center items-center"
            onClick={() => {
              handleContinue();
            }}
          >
            CONTINUE
          </button>
        )}
      </div>
    </div>
  );
};

export default Address